import React from 'react';
import { Progress, Card, CardBody, Button } from "@nextui-org/react";
import useStore from '../../store';

/**
 * A reusable progress bar component that shows operation progress
 * with status text and optional cancel button
 */
const ProgressBar = ({ 
  operation, 
  showCancel = false, 
  onCancel = null,
  className = '',
  compact = false,
  showWhenComplete = false,
  completeTimeout = 2000  // ms to show the completed status before hiding
}) => {
  const progressData = useStore((state) => state.progressData[operation]);
  const resetProgress = useStore((state) => state.resetProgress);
  const [showCompletedState, setShowCompletedState] = React.useState(false);
  
  // Auto-hide when completed after timeout
  React.useEffect(() => {
    if (progressData.status === 'complete' && !showWhenComplete) {
      setShowCompletedState(true);
      const timer = setTimeout(() => {
        setShowCompletedState(false);
        resetProgress(operation);
      }, completeTimeout);
      return () => clearTimeout(timer);
    }
  }, [progressData.status, completeTimeout, operation, resetProgress, showWhenComplete]);

  // Don't render anything if not active and not showing completed state
  if (!progressData.isActive && !showCompletedState && 
      (progressData.status !== 'complete' || !showWhenComplete)) {
    return null;
  }

  // Determine color based on status
  const getColor = () => {
    switch (progressData.status) {
      case 'error':
        return 'danger';
      case 'complete':
        return 'success';
      default:
        return 'primary';
    }
  };

  // Calculate percentage for smooth progress
  const percentage = progressData.progress !== undefined ? progressData.progress : 0;
  
  // Display operation status text
  const getStatusText = () => {
    if (progressData.status === 'error') {
      return progressData.error || 'Error occurred';
    }
    if (progressData.status === 'complete') {
      return 'Operation completed successfully';
    }
    return progressData.operation || 'Processing...';
  };

  // For compact version
  if (compact) {
    return (
      <div className={`w-full ${className}`}>
        <div className="flex justify-between items-center gap-2 mb-1">
          <span className="text-sm truncate">{getStatusText()}</span>
          {progressData.status !== 'complete' && (
            <span className="text-sm font-medium">{Math.round(percentage)}%</span>
          )}
        </div>
        <Progress 
          aria-label={`Progress for ${operation}`} 
          value={percentage} 
          color={getColor()} 
          className="w-full"
          isIndeterminate={progressData.isActive && percentage === 0}
          showValueLabel={false}
        />
      </div>
    );
  }

  // Full card version
  return (
    <Card className={`w-full ${className}`}>
      <CardBody className="gap-3">
        <div className="flex justify-between items-center">
          <h4 className="text-md font-medium">{operation.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())}</h4>
          {showCancel && progressData.isActive && onCancel && (
            <Button
              size="sm"
              variant="flat"
              color="danger"
              onPress={onCancel}
            >
              Cancel
            </Button>
          )}
        </div>
        
        <div className="w-full">
          <Progress 
            aria-label={`Progress for ${operation}`} 
            value={percentage} 
            color={getColor()} 
            className="w-full"
            isIndeterminate={progressData.isActive && percentage === 0}
            showValueLabel={true}
          />
        </div>
        
        <p className="text-sm text-default-500">{getStatusText()}</p>
      </CardBody>
    </Card>
  );
};

export default ProgressBar; 