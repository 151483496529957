import axios from 'axios';

class WebhookService {
  static ZAPIER_WEBHOOK_URL = 'https://hooks.zapier.com/hooks/catch/13542156/2l5dipm/';
  static PROXY_WEBHOOK_URL = '/api/webhook/notify-zapier';
  
  // Regex for matching ID codes in filenames (CEDAR5-76, etc.)
  static ID_CODE_REGEX = /([a-zA-Z0-9]+-\d{1,3})/i;
  
  /**
   * Check if a filename contains an ID code that should trigger a notification
   * @param {string} filename - The filename to check
   * @returns {string|null} - The matched ID code or null if no match
   */
  static extractIdCode(filename) {
    if (!filename) {
      console.log(`🔔 WEBHOOK: Cannot extract ID code - filename is empty or undefined`);
      return null;
    }
    
    console.log(`🔔 WEBHOOK: Checking for ID code in filename: "${filename}"`);
    const match = filename.match(this.ID_CODE_REGEX);
    
    if (match) {
      console.log(`🔔 WEBHOOK: Found ID code: "${match[0]}" at position ${match.index}`);
      return match[0];
    }
    
    console.log(`🔔 WEBHOOK: No ID code found in filename "${filename}"`);
    return null;
  }
  
  /**
   * Send a notification to Zapier for projects with an ID code
   * @param {Object} options - Options for the notification
   * @param {string} options.filename - The original filename (with the ID code)
   * @param {string} options.fileHash - The hash of the video file
   * @param {string} options.projectName - The name of the project
   * @returns {Promise} - Promise resolving to the webhook response
   */
  static async notifyZapier({filename, fileHash, projectName}) {
    console.log(`🔔 WEBHOOK: notifyZapier called with:`, {filename, fileHash, projectName});
    
    // Extract the ID code from the filename
    const idCode = this.extractIdCode(filename);
    
    // Only notify if there's a matching ID code
    if (!idCode) {
      console.log(`🔔 WEBHOOK: Skipping notification - no ID code in filename`);
      return null;
    }
    
    // Construct the project URL
    const projectUrl = `${window.location.origin}/project/${fileHash}/${projectName}`;
    
    console.log(`🔔 WEBHOOK: Sending notification to Zapier via backend proxy:`, {
      idCode,
      filename,
      projectUrl
    });
    
    try {
      // Use our backend proxy to send the notification to Zapier
      console.log(`🔔 WEBHOOK: Sending request to backend proxy: ${this.PROXY_WEBHOOK_URL}`);
      const response = await axios.post(this.PROXY_WEBHOOK_URL, {
        filename,
        projectUrl,
        idCode
      });
      
      console.log(`🔔 WEBHOOK: Notification sent successfully via proxy:`, response.data);
      return response.data;
    } catch (error) {
      console.error(`🔔 WEBHOOK: Failed to send notification via proxy:`, error);
      
      // Try direct methods as fallback
      try {
        // Method 1: Try a simple GET request with params in URL (no preflight)
        console.log(`🔔 WEBHOOK: Fallback - trying direct GET request with query params`);
        const queryParams = new URLSearchParams({
          filename,
          projectUrl,
          idCode
        }).toString();
        
        const response = await fetch(`${this.ZAPIER_WEBHOOK_URL}?${queryParams}`, {
          method: 'GET',
          mode: 'no-cors', // This prevents CORS errors but means we can't read the response
        });
        
        console.log(`🔔 WEBHOOK: Fallback notification sent using fetch API (no-cors mode)`);
        return { success: true, method: 'direct-fetch' };
      } catch (fetchError) {
        console.error(`🔔 WEBHOOK: Fallback fetch also failed:`, fetchError);
        
        // Final fallback - image pixel tracking
        try {
          console.log(`🔔 WEBHOOK: Final fallback - trying with image pixel tracking`);
          const img = new Image();
          const src = `${this.ZAPIER_WEBHOOK_URL}?${new URLSearchParams({
            filename,
            projectUrl,
            idCode
          }).toString()}`;
          img.src = src;
          console.log(`🔔 WEBHOOK: Image pixel request sent to: ${src}`);
          return { success: true, method: 'image-pixel' };
        } catch (pixelError) {
          console.error(`🔔 WEBHOOK: All notification methods failed:`, pixelError);
          return null;
        }
      }
    }
  }
}

export default WebhookService; 