import React, { useState, useEffect } from 'react';
import { UploadFlow, RetrieveFlow, ThemeToggle, SpookyCutterLogo, Tips, Login, Signup, Logout } from './components';
import CacheSettings from './components/CacheSettings';
import { Button, ButtonGroup, Tooltip } from '@nextui-org/react';
import useStore from './store';
import { Toaster } from 'react-hot-toast';
import { useLocation, useNavigate, Outlet } from 'react-router-dom';
import { Navbar, NavbarBrand, NavbarContent, NavbarItem } from "@nextui-org/react";

const App = () => {
  const [flow, setFlow] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  const { videoFile, token, projectState, currentLanguage, sourceLanguage, setCurrentLanguage, setVideoFile, resetState } = useStore();

  // Reset flow when token changes
  useEffect(() => {
    if (token) {
      setFlow(null);
    }
  }, [token]);

  // Set initial language whenever project state changes
  useEffect(() => {
    if (projectState) {
      const savedDisplayLanguage = projectState.displayLanguage || projectState.currentLanguage;

      // If we have a saved display language preference, use it
      if (savedDisplayLanguage && savedDisplayLanguage !== currentLanguage) {
        console.log('[APP] Setting initial display language:', {
          saved: savedDisplayLanguage,
          current: currentLanguage,
          source: sourceLanguage
        });
        setCurrentLanguage(savedDisplayLanguage);
      }
      // Otherwise default to source language
      else if (sourceLanguage && !currentLanguage) {
        console.log('[APP] Defaulting to source language:', sourceLanguage);
        setCurrentLanguage(sourceLanguage);
      }
    }
  }, [projectState, sourceLanguage]);

  const handleUploadClick = () => {
    setFlow('upload');
  };

  const handleRetrieveClick = () => {
    setFlow('retrieve');
  };

  const handleAuthFlowChange = (newFlow) => {
    setFlow(newFlow);
  };

  const handleHomeClick = (e) => {
    e.preventDefault();
    resetState(); // Reset all state when going home
    setFlow(null); // Reset flow
    navigate('/');
  };

  // Login/Signup screen
  if (!token) {
    return (
      <div className="h-screen flex justify-center items-center">
        <div className="flex flex-col items-center">
          <h2 className="text-2xl mb-4">Welcome</h2>
          <p className="mb-4">Please login or signup to continue</p>
          <div className="flex flex-col gap-4 items-center">
            {flow === 'login' ? (
              <>
                <Login onSuccess={() => setFlow(null)} />
                <Button
                  onClick={() => handleAuthFlowChange('signup')}
                  size="lg"
                  className="bg-gradient-to-tr from-purple-500 to-blue-500 text-white shadow-lg hover:shadow-xl transition-shadow"
                >
                  Signup
                </Button>
              </>
            ) : flow === 'signup' ? (
              <>
                <Signup onSuccess={() => setFlow(null)} />
                <Button
                  onClick={() => handleAuthFlowChange('login')}
                  size="lg"
                  className="bg-gradient-to-tr from-purple-500 to-blue-500 text-white shadow-lg hover:shadow-xl transition-shadow"
                >
                  Login
                </Button>
              </>
            ) : (
              <>
                <Button
                  onClick={() => handleAuthFlowChange('login')}
                  size="lg"
                  className="bg-gradient-to-tr from-purple-500 to-blue-500 text-white shadow-lg hover:shadow-xl transition-shadow"
                >
                  Login
                </Button>
                <Button
                  onClick={() => handleAuthFlowChange('signup')}
                  size="lg"
                  className="bg-gradient-to-tr from-pink-500 to-yellow-500 text-white shadow-lg hover:shadow-xl transition-shadow"
                >
                  Signup
                </Button>
              </>
            )}
          </div>
        </div>
      </div>
    );
  }

  // Check if we're on a project route
  const isProjectRoute = location.pathname.startsWith('/project/');

  // Main app
  return (
    <>
      <Toaster position="top-right" />
      <div className="h-screen grid grid-rows-[auto,1fr] overflow-hidden">
        <div className="flex flex-col z-50 col-span-12">
          <Navbar isBordered position='static' maxWidth={'full'}>
            <NavbarBrand>
              <button onClick={handleHomeClick} className="cursor-pointer">
                <SpookyCutterLogo />
              </button>
            </NavbarBrand>

            <NavbarContent justify="center" className="flex-grow">
              {(isProjectRoute || (flow && flow !== 'retrieve')) && <Tips />}
            </NavbarContent>

            <NavbarContent justify='end' className="gap-2">
              <NavbarItem className="flex-none">
                {/* Temporarily comment out FullscreenButton to avoid errors */}
                {/*
                <Button
                  as={FullscreenButton}
                  isIconOnly
                  size="sm"
                  className="min-w-8 w-8 h-8"
                />
                */}
              </NavbarItem>
              <NavbarItem className="flex-none">
                <Button
                  as={ThemeToggle}
                  isIconOnly
                  size="sm"
                  className="min-w-8 w-8 h-8"
                />
              </NavbarItem>
              <NavbarItem className="flex-none">
                <CacheSettings />
              </NavbarItem>
              <NavbarItem className="flex-none">
                <Logout
                  size="sm"
                  className="text-[0.80rem] text-default-500 !bg-light-bg dark:!bg-dark-bg hover:!bg-slate-100 dark:hover:!bg-slate-700"
                />
              </NavbarItem>
            </NavbarContent>
          </Navbar>
        </div>
        {isProjectRoute ? (
          <Outlet />
        ) : (
          <>
            {flow === 'upload' && <UploadFlow />}
            {flow === 'retrieve' && <RetrieveFlow />}
            {!flow && (
              <main className="relative overflow-hidden flex justify-center items-center col-span-12">
                <div className='flex flex-col justify-center items-center'>
                  <h2 className='text-4xl mb-2 font-bold'>Welcome</h2>
                  <p className='mb-8 text-3xl font-bold bg-gradient-to-r from-purple-600 via-pink-500 to-yellow-500 text-transparent bg-clip-text bg-[length:200%_auto] animate-gradient'>
                    Where would you like to start?
                  </p>
                  <div className='flex gap-8'>
                    <Button
                      onClick={handleUploadClick}
                      size="lg"
                      className="text-xl py-8 px-12 bg-gradient-to-tr from-pink-500 to-yellow-500 text-white shadow-lg hover:shadow-xl transition-shadow"
                    >
                      Upload a New Video
                    </Button>
                    <Button
                      onClick={handleRetrieveClick}
                      size="lg"
                      className="text-xl py-8 px-12 bg-gradient-to-tr from-purple-500 to-blue-500 text-white shadow-lg hover:shadow-xl transition-shadow"
                    >
                      Browse Existing Cuts
                    </Button>
                  </div>
                </div>
              </main>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default App;