import React, { useState, useCallback, useEffect, useRef, useMemo } from 'react';
import useStore from '../../store';
import { Resizable } from 'react-resizable';
import { Card, CardBody, Button, Tabs, Tab } from "@nextui-org/react";
import { preventOverlap } from '../../utils/TranscriptUtils';
import { useParams } from 'react-router-dom';
import { toast } from 'react-hot-toast';

import { 
  ProjectBrowser, 
  ContextualToolbar, 
  FileUploadSection, 
  VideoPlayer, 
  TranscriptSettings, 
  TranscriptOrderManager, 
  OutputSection, 
  ActionBar, 
  FindBarContainer, 
  TranslationSettings,
  Tips,
  WordSelectionArea,
} from '../../components';
import { useProjects, useVideoSeeking, useTranscriptBlocks, useTranscriptProcessor, useFileUpload, useDragAndDrop, useKeyPress, useBlockNavigation, useWordAdjustment, useBlockHandlers } from '../../hooks';

import '../../App.css';
import { LoadingSpinner } from '../LoadingSpinner/index';
import JSONUpdateService from '../../services/JSONUpdateService';
import ProjectStateService from '../../services/ProjectStateService';
import FileUploadService from '../../services/FileUploadService';
import WebhookService from '../../services/WebhookService';

// Add this style either inline or in your CSS file
const videoFileNameStyle = {
  fontSize: '0.875rem',
  fontStyle: 'italic',
  color: '#666',
  whiteSpace: 'normal',
  overflowWrap: 'anywhere',
  wordBreak: 'keep-all',
  maxWidth: '100%',
  padding: '0 8px',
  display: 'block',
  wordSpacing: '0.1px',
  textWrap: 'balance'
};

// Add this style for the duration display
const durationStyle = {
  fontSize: '0.875rem',
  padding: '0 8px',
  marginTop: '4px'
};

// Add these styles at the top with other style definitions
const columnLayoutStyles = {
  container: "grid grid-cols-12 h-full w-full gap-4 p-4 overflow-hidden",
  leftColumn: "col-span-5 flex flex-col h-full overflow-hidden",
  centerColumn: "col-span-4 flex flex-col h-full overflow-hidden",
  rightColumn: "col-span-3 flex flex-col h-full overflow-hidden",
  scrollContainer: "flex-1 min-h-0 rounded-xl bg-content1 p-4 relative",
  resizableContainer: "flex-1 min-h-0 rounded-xl bg-content1 p-4 relative overflow-hidden",
};

// Add video player specific styles
const videoPlayerStyles = {
  group: "flex-none flex flex-col", // Ensures the group stays together
  container: "relative w-full aspect-video", // Maintains video aspect ratio
  metadata: "flex-none mt-2" // Keeps metadata attached to video
};

const HeightDebugger = ({ elementRef }) => {
  const [dimensions, setDimensions] = useState({});

  useEffect(() => {
    const updateDimensions = () => {
      if (elementRef.current) {
        const computed = window.getComputedStyle(elementRef.current);
        const rect = elementRef.current.getBoundingClientRect();
        setDimensions({
          clientHeight: elementRef.current.clientHeight,
          scrollHeight: elementRef.current.scrollHeight,
          computedHeight: computed.height,
          boundingHeight: rect.height,
          overflow: computed.overflow,
          display: computed.display,
          position: computed.position
        });
      }
    };

    updateDimensions();
    window.addEventListener('resize', updateDimensions);
    return () => window.removeEventListener('resize', updateDimensions);
  }, [elementRef]);

  return (
    <div className="fixed bottom-4 right-4 z-50 bg-black/80 text-white p-4 rounded-lg text-xs font-mono">
      <pre>{JSON.stringify(dimensions, null, 2)}</pre>
    </div>
  );
};

export function Editor() {
  const { videoSHA, projectFileName } = useParams();
  const { 
    handleLoadProjectRemote, 
    videoLoadingOperation 
  } = useProjects();

  // Video seeking hooks
  const {
    currentTime,
    isPlaying,
    videoPlayerRef,
    handleSeek,
    handleVideoPlayPause,
    handleTimeUpdate,
    handleFineSeek,
    handleStoppingOnRejectedBlock,
  } = useVideoSeeking();

  // Store state
  const {
    currentBlockIndex,
    selectedBlockIndex,
    breakAtSentence, setBreakAtSentence,
    maxCharPerSection, setMaxCharPerSection,
    breakOnSpeakerChange, setBreakOnSpeakerChange,
    videoFile, setVideoFile,
    videoFileName, setVideoFileName,
    isSeeking,
    flashingButton, setFlashingButton,
    videoProcessed, setVideoProcessed,
    rejectLongSilences, setRejectLongSilences,
    longSilenceThreshold, setLongSilenceThreshold,
    breakOnLongSilences, setBreakOnLongSilences,
    projectName, setProjectName,
    projectFileName: existingProjectFileName, setProjectFileName,
    setIsTextInputFocused,
    rejectBlocksContaining, setRejectBlocksContaining,
    rejectBlocksCondition, setRejectBlocksCondition,
    rejectBlocksWord, setRejectBlocksWord,
    transcriptData, setTranscriptData,
  } = useStore();

  const { transcriptBlocks, setTranscriptBlocks } = useStore();
  const apiUrl = '/api';

  // Initialize transcript processor first
  const handleTranscript = useTranscriptProcessor();

  // Then initialize file upload with transcript processor
  const {
    isUploading,
    uploadError,
    handleFileUpload,
    generateTranscript,
    hasTranscriptData
  } = useFileUpload(
    apiUrl,
    handleTranscript,
    setVideoFile,
    setVideoProcessed,
    setVideoFileName
  );

  const { handleLoadLocalProject, listAllProjectsGroupedBySHA, listProjectsForSHA } = useProjects();

  // Add effect to handle auto-saving for new uploads
  useEffect(() => {
    const autoSaveNewUpload = async () => {
      // Only proceed if we have a SHA and filename, and upload just completed
      if (!videoSHA || !videoFileName || isUploading) {
        return;
      }

      console.log('Checking for auto-save conditions:', {
        videoSHA,
        videoFileName,
        isUploading
      });

      try {
        // Check if there are any existing projects for this video
        const existingProjects = await listProjectsForSHA(videoSHA);
        console.log('Existing projects:', existingProjects);

        // Only save if there are no existing projects
        if (!existingProjects || existingProjects.length === 0) {
          console.log('No existing projects found, auto-saving...');
          
          // Create project name from video filename (without extension)
          const baseName = videoFileName.replace(/\.[^/.]+$/, '');
          const projectName = `${baseName}`;
          
          // Prepare project state
          const projectState = {
            videoSHA,
            videoFileName,
            transcriptBlocks,
            projectName,
            projectFileName: `${projectName}.json`,
            decisions: {
              blocks: transcriptBlocks || [],
              settings: {
                breakAtPunctuation: false,
                maxCharPerSection: 500,
                breakOnSpeakerChange: false,
                rejectLongSilences: false,
                longSilenceThreshold: 1.0,
              }
            },
            metadata: {
              dateCreated: new Date().toISOString(),
              lastModified: new Date().toISOString(),
              version: '1.0.0',
              source: 'upload'
            }
          };

          console.log('Auto-saving project with metadata:', { 
            projectName, 
            dateCreated: projectState.metadata.dateCreated,
            lastModified: projectState.metadata.lastModified
          });
          
          // Save the project
          await ProjectStateService.saveToBackend(videoSHA, projectName, projectState);
          console.log('Auto-save completed successfully');
          
          // Update local state
          setProjectName(projectName);
          setProjectFileName(projectName);
          
          // Dispatch event to refresh project list
          window.dispatchEvent(new CustomEvent('projectSaved'));
          
          // Send notification to Zapier if the filename has an ID code
          try {
            console.log('🔔 WEBHOOK: Attempting to send notification for newly saved project from Editor:', { 
              videoSHA, 
              videoFileName 
            });
            await WebhookService.notifyZapier({
              filename: videoFileName,
              fileHash: videoSHA,
              projectName
            });
          } catch (webhookError) {
            console.error('🔔 WEBHOOK: Failed to send notification from Editor:', webhookError);
            // We don't want to fail the project creation if the webhook fails
          }
        } else {
          console.log('Existing projects found, skipping auto-save');
        }
      } catch (error) {
        console.warn('Error during auto-save:', error);
      }
    };

    autoSaveNewUpload();
  }, [videoSHA, videoFileName, isUploading, transcriptBlocks]);

  // Add an effect to update filename when loading projects
  useEffect(() => {
    console.log('Project filename update effect triggered:', {
      isFile: videoFile instanceof File,
      videoFile,
      currentFileName: videoFileName
    });
    
    if (videoFile instanceof File) {
      console.log('Setting filename from File object:', videoFile.name);
      setVideoFileName(videoFile.name);
    } else if (videoFile && typeof videoFile === 'string' && !videoFile.startsWith('blob:')) {
      // Only extract filename from URL/path if it's not a blob URL
      const fileName = videoFile.split('/').pop();
      console.log('Setting filename from URL/path:', fileName);
      setVideoFileName(fileName);
    }
  }, [videoFile, setVideoFileName]);

  // Block and word handling hooks
  const { handleRejectBlock } = useTranscriptBlocks(handleSeek);
  const { handleBlockNavigation, handleBlockEnd } = useBlockNavigation(handleSeek, handleVideoPlayPause);
  const { handleAdjustFirstWord, handleAdjustLastWord } = useWordAdjustment(handleSeek, setFlashingButton);
  const { handleDragEnd } = useDragAndDrop(transcriptBlocks, setTranscriptBlocks);
  const { getCurrentWordIndex } = useBlockHandlers(handleSeek, currentTime);

  // Key press handling
  useKeyPress(handleBlockNavigation, handleVideoPlayPause, isPlaying, handleSeek);

  // Add state for tracking if blocks have been generated
  const [hasGeneratedBlocks, setHasGeneratedBlocks] = useState(false);

  // Callbacks
  const handleGenerateTranscript = useCallback(() => {
    console.log('Generating transcript:', { hasTranscriptData });
    if (hasTranscriptData) {
      generateTranscript(handleTranscript, setVideoProcessed);
      console.log('Generated transcript blocks');
      setHasGeneratedBlocks(true);
    }
  }, [generateTranscript, handleTranscript, setVideoProcessed, hasTranscriptData]);

  const handleRegenerateTranscript = useCallback(() => {
    handleGenerateTranscript();
  }, [handleGenerateTranscript]);

  const isContextualToolbarVisible = useStore(state => state.isContextualToolbarVisible);
  const isFindBarVisible = useStore(state => state.isFindBarVisible);

  useEffect(() => {
    if (transcriptBlocks.length > 0) {
      setHasGeneratedBlocks(true);
    }
  }, [transcriptBlocks]);

  const [activeTab, setActiveTab] = useState('layout');

  const tabsContainerRef = useRef(null);

  useEffect(() => {
    const updateTabsHeight = () => {
      if (tabsContainerRef.current) {
        const windowHeight = window.innerHeight;
        const tabsTop = tabsContainerRef.current.getBoundingClientRect().top;
        const availableHeight = windowHeight - tabsTop;
        tabsContainerRef.current.style.height = `${availableHeight}px`;
      }
    };

    updateTabsHeight();
    window.addEventListener('resize', updateTabsHeight);
    return () => window.removeEventListener('resize', updateTabsHeight);
  }, []);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    // No need to do anything special for language tab
  };

  // Add this useEffect in your Editor component to handle scaling
  useEffect(() => {
    const filename = document.querySelector('.video-filename');
    if (filename) {
      const parent = filename.parentElement;
      const scale = parent.offsetWidth / filename.offsetWidth;
      if (scale < 1) {
        filename.style.setProperty('--scale', scale);
      }
    }
  }, [videoFileName]); // Re-run when filename changes

  const newDuration = useMemo(() => 
    JSONUpdateService.formatDuration(JSONUpdateService.calculateDuration(transcriptBlocks)),
    [transcriptBlocks]
  );

  const [displayFileName, setDisplayFileName] = useState(null);

  // Add an effect to handle filename display
  useEffect(() => {
    const getDisplayFileName = async () => {
      // Skip if nothing has changed
      if (displayFileName === videoFileName && !isUploading) {
        return;
      }

      // During upload, use the direct filename from the File object
      if (isUploading && videoFile instanceof File) {
        setDisplayFileName(videoFile.name);
        return;
      }
      
      // If we have videoFileName and it's not a blob URL, use it
      if (videoFileName && !videoFileName.includes('blob:')) {
        setDisplayFileName(videoFileName);
        return;
      }
      
      // Only check manifest when loading an existing project and we don't have the filename
      if (videoSHA && !isUploading && !displayFileName) {
        try {
          const manifestResponse = await ProjectStateService.getManifest(videoSHA);
          if (manifestResponse?.manifest?.originalFilename) {
            setDisplayFileName(manifestResponse.manifest.originalFilename);
          }
        } catch (error) {
          console.warn('Failed to get manifest for filename:', error);
        }
      }
    };
    getDisplayFileName();
  }, [videoFileName, videoSHA, isUploading, videoFile, displayFileName]);

  const formattedFileName = useMemo(() => 
    displayFileName?.replace(/-/g, '‑'),
    [displayFileName]
  );

  const transcriptContainerRef = useRef(null);

  // Add effect to handle auto-scrolling when selected block changes
  useEffect(() => {
    if (selectedBlockIndex === null || !transcriptContainerRef.current) return;

    const blockElement = document.querySelector(`[data-block-index="${selectedBlockIndex}"]`);
    if (!blockElement) return;

    const container = transcriptContainerRef.current;
    const containerRect = container.getBoundingClientRect();
    const elementRect = blockElement.getBoundingClientRect();
    
    const relativeTop = elementRect.top - containerRect.top;
    const centerOffset = (containerRect.height - elementRect.height) / 2;
    const targetScroll = container.scrollTop + relativeTop - centerOffset;
    
    container.scrollTo({
      top: targetScroll,
      behavior: 'smooth'
    });
  }, [selectedBlockIndex]);

  const [columnWidths, setColumnWidths] = useState({
    left: window.innerWidth * 0.417, // 41.7%
    center: window.innerWidth * 0.333, // 33.3%
    right: window.innerWidth * 0.25, // 25%
  });
  
  const [isRightPanelCollapsed, setIsRightPanelCollapsed] = useState(false);

  const handleResizeLeft = (e, { size }) => {
    const totalWidth = window.innerWidth - 32; // Account for padding
    const newLeftWidth = Math.max(200, Math.min(size.width, totalWidth * 0.6));
    const remainingWidth = totalWidth - newLeftWidth;
    const centerRatio = columnWidths.center / (columnWidths.center + columnWidths.right);
    
    setColumnWidths({
      left: newLeftWidth,
      center: remainingWidth * centerRatio,
      right: remainingWidth * (1 - centerRatio),
    });
  };

  const handleResizeCenter = (e, { size }) => {
    const totalWidth = window.innerWidth - 32;
    const remainingWidth = totalWidth - columnWidths.left;
    const newCenterWidth = Math.max(200, Math.min(size.width, remainingWidth - 200));
    
    setColumnWidths({
      ...columnWidths,
      center: newCenterWidth,
      right: remainingWidth - newCenterWidth,
    });
  };

  const toggleRightPanel = () => {
    setIsRightPanelCollapsed(!isRightPanelCollapsed);
    if (isRightPanelCollapsed) {
      // Restore previous width
      setColumnWidths(prev => ({
        left: prev.left,
        center: prev.center,
        right: window.innerWidth * 0.25,
      }));
    } else {
      // Collapse right panel and distribute its width
      setColumnWidths(prev => ({
        left: prev.left,
        center: prev.center + prev.right,
        right: 0,
      }));
    }
  };

  // Update widths on window resize
  useEffect(() => {
    const handleResize = () => {
      const totalWidth = window.innerWidth - 32;
      const leftRatio = columnWidths.left / (columnWidths.left + columnWidths.center + columnWidths.right);
      const centerRatio = columnWidths.center / (columnWidths.left + columnWidths.center + columnWidths.right);
      
      setColumnWidths({
        left: totalWidth * leftRatio,
        center: totalWidth * centerRatio,
        right: totalWidth * (1 - leftRatio - centerRatio),
      });
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [columnWidths]);

  // Add effect to load project from URL params
  useEffect(() => {
    const loadProjectFromUrl = async () => {
      if (videoSHA && projectFileName) {
        console.log('Loading project from URL params:', { videoSHA, projectFileName });
        try {
          if (projectFileName === '_new') {
            // Load video and transcript for new projects
            if (!useStore.getState().transcriptData) {
              const data = await FileUploadService.fetchTranscript('/api', videoSHA);
              setTranscriptData(data);
            }
            if (!useStore.getState().videoFile) {
              const videoUrl = `/api/project/${videoSHA}/video`;
              setVideoFile(videoUrl);
            }
            return;
          }
          await handleLoadProjectRemote({
            videoSHA,
            filename: projectFileName
          });
        } catch (error) {
          console.error('Error loading project from URL:', error);
          toast.error('Failed to load project. Please try again.');
        }
      }
    };

    loadProjectFromUrl();
  }, [videoSHA, projectFileName, handleLoadProjectRemote, setTranscriptData, setVideoFile]);

  return (
    <div className="h-full w-full flex flex-col">
      {/* Header Section */}
      <div className="flex-none">
        {/* Find bar */}
        {isFindBarVisible && (
          <div className="w-full bg-background/80 border-b border-default-200">
            <FindBarContainer isVisible={true} onSeek={handleSeek} />
          </div>
        )}

        {/* Contextual toolbar */}
        <div className="w-full toolbar-area backdrop-blur-lg backdrop-saturate-150 bg-background/40 flex justify-end border-b border-default-200">
          {!isUploading && transcriptBlocks && transcriptBlocks.length === 0 ? (
            <></>
          ) : (
            <ContextualToolbar
              selectedBlock={selectedBlockIndex !== null ? transcriptBlocks[selectedBlockIndex] : null}
              onReject={() => selectedBlockIndex !== null && handleRejectBlock(selectedBlockIndex)}
              onAdjustFirstWord={handleAdjustFirstWord}
              onAdjustLastWord={handleAdjustLastWord}
              flashingButton={flashingButton}
              videoProcessed={videoProcessed}
              currentTime={currentTime}
              onFineSeek={handleFineSeek}
              currentBlockIndex={currentBlockIndex}
              currentWordIndex={getCurrentWordIndex()}
            />
          )}
        </div>
      </div>

      {/* Main Content Area */}
      <div className="flex-1 min-h-0 overflow-hidden p-4">
        <div className="column-container">
          {/* Left Column - Video */}
          <Resizable
            className="column column-left"
            width={columnWidths.left}
            height={Infinity}
            onResize={handleResizeLeft}
            draggableOpts={{ enableUserSelectHack: false }}
            minConstraints={[200, Infinity]}
            maxConstraints={[window.innerWidth * 0.6, Infinity]}
            handle={<div className="react-resizable-handle react-resizable-handle-e" />}
          >
            <div style={{ width: columnWidths.left }} className={`${columnLayoutStyles.resizableContainer} video-container`}>
              <Card className="video-card h-full flex flex-col">
                <CardBody className="flex-1 flex flex-col p-3 gap-2 overflow-hidden">
                  {/* Video player group - keeps video and metadata together */}
                  <div className={videoPlayerStyles.group}>
                    <div className={videoPlayerStyles.container}>
                      <VideoPlayer
                        videoPlayerRef={videoPlayerRef}
                        src={videoFile}
                        onSeek={handleSeek}
                        onPlayPause={handleVideoPlayPause}
                        onTimeUpdate={handleTimeUpdate}
                        onBlockEnd={handleBlockEnd}
                        currentBlock={transcriptBlocks[currentBlockIndex]}
                        isPlaying={isPlaying}
                        isSeeking={isSeeking}
                        handleStoppingOnRejectedBlock={handleStoppingOnRejectedBlock}
                        initialLoadingOperation={videoLoadingOperation}
                      />
                    </div>
                    {formattedFileName && (
                      <div className={videoPlayerStyles.metadata}>
                        <div style={videoFileNameStyle}>
                          {formattedFileName}
                        </div>
                        <div style={durationStyle}>
                          New Duration: {newDuration}
                        </div>
                      </div>
                    )}
                  </div>

                  {/* Spacer to push upload section to bottom */}
                  <div className="flex-auto" />

                  {/* Upload section */}
                  <div className="flex-none">
                    <FileUploadSection
                      onFileUpload={handleFileUpload}
                      isUploading={isUploading}
                      uploadError={uploadError}
                      apiUrl={apiUrl}
                      compact={true}
                      hide={hasGeneratedBlocks || videoProcessed || isUploading || Boolean(videoFile)}
                    />
                  </div>
                </CardBody>
              </Card>
            </div>
          </Resizable>

          {/* Center Column - Transcript */}
          <Resizable
            className="column column-center"
            width={columnWidths.center}
            height={Infinity}
            onResize={handleResizeCenter}
            draggableOpts={{ enableUserSelectHack: false }}
            minConstraints={[200, Infinity]}
            maxConstraints={[window.innerWidth - 400, Infinity]}
            handle={<div className="react-resizable-handle react-resizable-handle-e" />}
          >
            <div style={{ width: columnWidths.center }} className={`${columnLayoutStyles.scrollContainer} h-full flex flex-col`} ref={transcriptContainerRef}>
              {isUploading ? (
                <div className="h-full flex items-center justify-center">
                  <LoadingSpinner />
                </div>
              ) : !isUploading && hasTranscriptData && transcriptBlocks.length === 0 ? (
                <div className="h-full w-full">
                  <div className="relative w-full pl-4 pt-8">
                    <div className="flex items-center gap-4">
                      <div className="p-8">
                        <h2 className="text-2xl font-mono mb-3">
                          Choose how you'd like your transcript sliced up and click Generate.
                        </h2>
                        <h3 className="text-base font-mono text-default-500">
                          You may want longer or shorter sections to rearrange, or you can remove silences. Play around with it first!
                        </h3>
                      </div>
                      <div className="text-default-400">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M5 12H19M19 12L12 5M19 12L12 19" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  <div className="container-action-bar mb-4">
                    <ActionBar onFineSeek={handleFineSeek} />
                  </div>
                  <div className="flex-1 min-h-0 overflow-hidden">
                    <TranscriptOrderManager
                      onDragEnd={handleDragEnd}
                      onSeek={handleSeek}
                      videoPlayerRef={videoPlayerRef}
                      currentTime={currentTime}
                      preventOverlap={preventOverlap}
                      onBlockNavigation={handleBlockNavigation}
                    />
                  </div>
                </>
              )}
            </div>
          </Resizable>

          {/* Right Column - Settings */}
          <div 
            className={`column column-right ${isRightPanelCollapsed ? 'collapsed' : ''}`}
            style={{ width: columnWidths.right }}
          >
            <Button
              isIconOnly
              className="toggle-right-panel"
              onClick={toggleRightPanel}
              size="sm"
            >
              {isRightPanelCollapsed ? '←' : '→'}
            </Button>
            <div className={columnLayoutStyles.resizableContainer}>
              <div ref={tabsContainerRef} className="h-full">
                <Tabs 
                  aria-label="Editor tabs"
                  className="bg-transparent rounded-xl w-full"
                  classNames={{
                    base: "w-full",
                    tabList: "w-full relative rounded-lg p-2 bg-default-50 dark:bg-default-100/50 border border-default-100 shadow-[0_0_0_1px_rgba(0,0,0,0.03)]",
                    cursor: "bg-white dark:bg-default shadow-medium",
                    tab: "flex-1 px-4 h-10 data-[selected=true]:text-default-foreground",
                    tabContent: "group-data-[selected=true]:font-medium text-center",
                    panel: "pt-2 w-full",
                    panels: "w-full"
                  }}
                  variant="solid"
                  fullWidth
                >
                  <Tab
                    key="layout"
                    title={
                      <div className="flex items-center space-x-2">
                        <span>Layout</span>
                      </div>
                    }
                  >
                    <div className="p-2">
                      <TranscriptSettings
                        onLoadLocalProject={handleLoadLocalProject}
                        onGenerateTranscript={handleGenerateTranscript}
                        setHasGeneratedBlocks={setHasGeneratedBlocks}
                        showRegenerateButton={hasGeneratedBlocks}
                        onRegenerateTranscript={handleRegenerateTranscript}
                        hasVideo={Boolean(videoFile)}
                        breakAtSentence={breakAtSentence}
                        setBreakAtSentence={setBreakAtSentence}
                        maxCharPerSection={maxCharPerSection}
                        setMaxCharPerSection={setMaxCharPerSection}
                        breakOnSpeakerChange={breakOnSpeakerChange}
                        setBreakOnSpeakerChange={setBreakOnSpeakerChange}
                        rejectLongSilences={rejectLongSilences}
                        setRejectLongSilences={setRejectLongSilences}
                        longSilenceThreshold={longSilenceThreshold}
                        setLongSilenceThreshold={setLongSilenceThreshold}
                        breakOnLongSilences={breakOnLongSilences}
                        setBreakOnLongSilences={setBreakOnLongSilences}
                        rejectBlocksContaining={rejectBlocksContaining}
                        setRejectBlocksContaining={setRejectBlocksContaining}
                        rejectBlocksCondition={rejectBlocksCondition}
                        setRejectBlocksCondition={setRejectBlocksCondition}
                        rejectBlocksWord={rejectBlocksWord}
                        setRejectBlocksWord={setRejectBlocksWord}
                      />
                    </div>
                  </Tab>
                  
                  <Tab
                    key="cuts"
                    title={
                      <div className="flex items-center space-x-2">
                        <span>Cuts</span>
                      </div>
                    }
                  >
                    <div className="p-2">
                      <div className="flex flex-col gap-4">
                        <ProjectBrowser
                          onLoadProject={handleLoadProjectRemote}
                          filterBySHA={useStore.getState().videoSHA}
                          hideInputs={true}
                        />
                      </div>
                    </div>
                  </Tab>

                  <Tab
                    key="output"
                    title={
                      <div className="flex items-center space-x-2">
                        <span>Output</span>
                      </div>
                    }
                  >
                    <div className="p-2">
                      <OutputSection />
                    </div>
                  </Tab>

                  <Tab
                    key="language"
                    title={
                      <div className="flex items-center space-x-2">
                        <span>Language</span>
                      </div>
                    }
                  >
                    <div className="p-2">
                      <TranslationSettings 
                        handleTranscript={handleTranscript}
                      />
                    </div>
                  </Tab>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}