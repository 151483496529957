import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import useVideoStore from './videoSlice';
import useUploadStore from './uploadSlice';
import useSettingsStore from './settingsSlice';
import useTranscriptBlocksStore from './transcriptBlocksSlice';
import useProjectStore from './projectSlice';
import createAuthStore from './authSlice';
import createProgressSlice from './progressSlice';
import { ProjectStateService, FileUploadService } from '../services';

const useStore = create(
  devtools(
    (set, get) => {
      // First, get all the project store functions
      const projectStore = useProjectStore(set, get);
      
      return {
        // Spread all other stores
        ...createAuthStore(set),
        ...useVideoStore(set),
        ...useUploadStore(set),
        ...useSettingsStore(set),
        ...useTranscriptBlocksStore(set, get),
        ...createProgressSlice(set),
        
        // Explicitly spread project store functions
        ...projectStore,
        
        // Make sure saveProject is properly bound
        saveProject: async (isNewProject = false, newProjectName = undefined) => {
          try {
            // If it's a new project, we want to clear the existing project filename first
            if (isNewProject) {
              set({ projectFileName: null }); // Clear existing project filename
            }

            // Get current project state to preserve existing metadata
            const currentState = get().projectState || {};
            const currentMetadata = currentState.metadata || {};

            const state = {
              projectFileName: isNewProject 
                ? `${Date.now()}.json` 
                : (get().projectFileName || `${Date.now()}.json`),
              projectName: newProjectName || get().projectName || 'Untitled Project',
              videoSHA: get().videoSHA,
              videoFileName: get().videoFileName,
              originalTranscript: get().originalTranscript,
              sourceLanguage: get().sourceLanguage,
              currentLanguage: get().currentLanguage,
              translations: get().availableLanguages.reduce((acc, lang) => {
                acc[lang.code] = {
                  dateCreated: lang.dateCreated
                };
                return acc;
              }, {}),
              decisions: {
                blocks: get().transcriptBlocks.map(block => ({
                  ...block,
                  words: block.words.map(processWord)
                })),
                settings: {
                  breakAtPunctuation: get().breakAtPunctuation,
                  maxCharPerSection: get().maxCharPerSection,
                  breakOnSpeakerChange: get().breakOnSpeakerChange,
                  rejectLongSilences: get().rejectLongSilences,
                  longSilenceThreshold: get().longSilenceThreshold,
                }
              },
              metadata: {
                ...currentMetadata,
                lastModified: new Date().toISOString(),
                dateCreated: currentMetadata.dateCreated || new Date().toISOString(),
                version: '1.0.0'
              }
            };

            // For new projects, always generate a new filename
            if (isNewProject) {
              state.projectFileName = `${Date.now()}.json`;
            }

            console.log('Saving project with metadata:', {
              projectName: state.projectName,
              metadata: state.metadata
            });

            // Save to backend
            await ProjectStateService.save(state);
            await ProjectStateService.saveToBackend(
              state.videoSHA,
              state.projectFileName,
              state
            );

            // Update store state
            set({
              projectState: state,
              projectName: state.projectName,
              projectFileName: state.projectFileName,
              isDirty: false,
              lastSaved: new Date()
            });

            return state;
          } catch (error) {
            console.error('Save failed:', error);
            throw error;
          }
        },
        setProjectName: projectStore.setProjectName,
        setProjectFileName: projectStore.setProjectFileName,
        
        // Rest of the store
        rejectLongSilences: false,
        setRejectLongSilences: (rejectLongSilences) => set({ rejectLongSilences }),
        longSilenceThreshold: 0.1,
        setLongSilenceThreshold: (longSilenceThreshold) => set({ longSilenceThreshold }),
        breakOnLongSilences: false,
        setBreakOnLongSilences: (breakOnLongSilences) => set({ breakOnLongSilences }),
        isFindBarVisible: false,
        toggleFindBar: () => set(state => ({ isFindBarVisible: !state.isFindBarVisible })),
        addBlock: (newBlock, insertIndex) => set(state => {
          const newBlocks = [...state.transcriptBlocks];
          newBlocks.splice(insertIndex, 0, newBlock);
          return { transcriptBlocks: newBlocks };
        }),
        searchQuery: '',
        setSearchQuery: (query) => set({ searchQuery: query }),
        removeBlock: (index) => set(state => {
          const newBlocks = [...state.transcriptBlocks];
          newBlocks.splice(index, 1);

          // Update any references to blocks after the deleted one
          const newState = {
            transcriptBlocks: newBlocks,
            currentBlockIndex: Math.min(state.currentBlockIndex, newBlocks.length - 1),
            selectedBlockIndex: state.selectedBlockIndex > index
              ? state.selectedBlockIndex - 1
              : state.selectedBlockIndex === index
                ? -1
                : state.selectedBlockIndex,
            isDirty: true
          };

          // Clear any selections if we removed the selected block
          if (state.selectedBlockIndex === index) {
            newState.selectedWords = [];
            newState.selectedWordsInBlock = {};
          }

          return newState;
        }),
        isSearchInputFocused: false,
        setSearchInputFocused: (focused) => set({ isSearchInputFocused: focused }),
        isTextInputFocused: false,
        setIsTextInputFocused: (value) => set({ isTextInputFocused: value }),
        sourceLanguage: null,
        currentLanguage: null,
        availableLanguages: [],
        setCurrentLanguage: (lang) => set({ currentLanguage: lang }),
        setAvailableLanguages: (langs) => set({ availableLanguages: langs }),
        setSourceLanguage: (lang) => set({ sourceLanguage: lang }),
        isSaveModalOpen: false,
        setIsSaveModalOpen: (value) => set({ isSaveModalOpen: value }),
        openSaveModal: () => set({ isSaveModalOpen: true }),
        closeSaveModal: () => set({ isSaveModalOpen: false }),
        loadProject: async (projectData, transcriptData) => {
          try {
            console.log('Loading project with data:', { projectData, transcriptData });
            
            // Set source language first
            const sourceLanguage = projectData.sourceLanguage || null;
            
            // Restore display language preference from project data
            const currentLanguage = projectData.currentLanguage || sourceLanguage;
            
            let availableLangs = [];

            // Collect languages from multiple sources
            if (transcriptData?.translations) {
              // Get languages from transcript translations
              const transcriptLangs = Object.keys(transcriptData.translations).map(code => ({
                code,
                dateCreated: transcriptData.translations[code].dateCreated || new Date().toISOString()
              }));
              availableLangs = [...availableLangs, ...transcriptLangs];
            }

            if (projectData.translations) {
              // Get languages from project translations
              const projectLangs = Object.entries(projectData.translations).map(([code, data]) => ({
                code,
                dateCreated: data.dateCreated
              }));
              availableLangs = [...availableLangs, ...projectLangs];
            }

            // Deduplicate languages
            const uniqueLangs = Array.from(
              new Map(availableLangs.map(lang => [lang.code, lang])).values()
            );

            console.log('Setting languages:', {
              source: sourceLanguage,
              current: currentLanguage,
              available: uniqueLangs
            });

            set({ 
              sourceLanguage,
              currentLanguage,
              availableLanguages: uniqueLangs,
              lastUpdateTimestamp: Date.now()
            });

          } catch (error) {
            console.error('Error loading project:', error);
          }
        },
        loadRemoteProject: async (filename, projectName, fileHash) => {
          try {
            console.log('Starting loadRemoteProject for:', { filename, projectName, fileHash });
            
            // Get transcript data
            const transcriptResponse = await FileUploadService.fetchTranscript(fileHash);
            console.log('Transcript response:', transcriptResponse);

            // Set available languages immediately from transcript response
            if (transcriptResponse.availableLanguages) {
              const availableLangs = transcriptResponse.availableLanguages.map(lang => ({
                code: lang,
                dateCreated: new Date().toISOString()
              }));
              set({ availableLanguages: availableLangs });
              console.log('Set available languages from transcript response:', availableLangs);
            }

            // Load project data
            const projectData = await ProjectStateService.loadProject(fileHash, filename);
            console.log('Loaded project state:', projectData);

            // Update available languages if project has more specific data
            if (projectData.translations) {
              const projectLangs = Object.entries(projectData.translations)
                .map(([code, data]) => ({
                  code,
                  dateCreated: data.dateCreated
                }));
              set({ availableLanguages: projectLangs });
              console.log('Updated available languages from project data:', projectLangs);
            }

            await loadProject(projectData, transcriptResponse);
            
            // ... rest of the function
          } catch (error) {
            console.error('Failed to load remote project:', error);
            throw error;
          }
        },
        updateAvailableTranslations: (newTranslations) => {
          const state = get();
          const existingCodes = new Set(state.availableLanguages.map(lang => lang.code));
          
          // Add any new languages
          const newLangs = Object.keys(newTranslations)
            .filter(code => !existingCodes.has(code))
            .map(code => ({
              code,
              dateCreated: new Date().toISOString()
            }));

          if (newLangs.length > 0) {
            const updatedLangs = [...state.availableLanguages, ...newLangs];
            console.log('Adding new languages:', {
              new: newLangs,
              total: updatedLangs
            });
            
            set({ 
              availableLanguages: updatedLangs,
              lastUpdateTimestamp: Date.now()
            });
          }
        },
        resetState: () => set({
          videoFile: null,
          videoFileName: null,
          videoSHA: null,
          projectName: null,
          projectFileName: null,
          transcriptBlocks: [],
          transcriptData: null,
          currentBlockIndex: null,
          selectedBlockIndex: null,
          isUploading: false,
          uploadError: null,
          videoProcessed: false,
          hasTranscriptData: false,
        }),
      };
    }
  )
);

// Helper function to scan words for available translations
const extractAvailableLanguagesFromWords = (blocks) => {
  const languagesSet = new Set();
  
  // First check the top-level translations object if it exists
  if (blocks.translations) {
    Object.keys(blocks.translations).forEach(lang => languagesSet.add(lang));
  }
  
  // Then scan through all words
  blocks.forEach(block => {
    block.words?.forEach(word => {
      if (word.translations) {
        Object.keys(word.translations).forEach(lang => languagesSet.add(lang));
      }
    });
  });

  console.log('[SCAN] Found languages:', Array.from(languagesSet));

  // Convert to our expected format with current timestamp as dateCreated
  return Array.from(languagesSet).map(code => ({
    code,
    dateCreated: new Date().toISOString()
  }));
};

const processWord = (word) => {
  // Keep only the essential properties
  return {
    word: word.word,
    start: word.start,
    end: word.end,
    translations: word.translations || {},
    type: word.type
  };
};

const updateTranscriptBlocks = (blocks) => {
  console.log('updateTranscriptBlocks START', {
    blockCount: blocks.length,
    operation: 'initial'
  });

  const processedBlocks = blocks
    .filter(block => block.words.length > 0)
    .map(block => ({
      ...block,
      words: block.words.map(processWord),
      isRejected: block.isRejected ?? false
    }));

  // Sort blocks and update state
  processedBlocks.sort((a, b) => (a.isRejected ?? false) - (b.isRejected ?? false));
  
  set({ 
    transcriptBlocks: processedBlocks,
    lastUpdateTimestamp: Date.now()
  });
};

export default useStore;
