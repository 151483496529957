/**
 * Normalizes a hash to ensure it's 8 characters.
 * During the transition period, this helps handle both full and truncated hashes.
 * @param {string} hash - The hash to normalize
 * @returns {string} - The normalized 8-character hash
 */
export const normalizeHash = (hash) => {
  if (!hash || hash.length <= 8) return hash;
  return hash.substring(0, 8);
};

/**
 * Calculates an 8-character SHA-256 hash of a file.
 * @param {File} file - The file to hash
 * @returns {Promise<string>} - The 8-character hash
 */
export const calculateFileHash = async (file) => {
  const buffer = await file.arrayBuffer();
  const hashBuffer = await crypto.subtle.digest('SHA-256', buffer);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const fullHash = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
  return fullHash.substring(0, 8);
}; 