import React from 'react';
import { BiTrash } from "react-icons/bi";
import { Button } from '@nextui-org/react';
import { useProjects } from '../../hooks';
import { toast } from 'react-hot-toast';

export const ProjectDeleteButton = ({ videoSHA, filename, onDelete, className = '' }) => {
  const { moveToTrash } = useProjects();
  const [isLoading, setIsLoading] = React.useState(false);

  const handleDelete = async (e) => {
    e.stopPropagation(); // Prevent triggering project selection
    
    if (window.confirm('Move this project to trash?')) {
      setIsLoading(true);
      try {
        await moveToTrash(videoSHA, filename);
        if (onDelete) onDelete();
      } catch (error) {
        // Error handling is done in moveToTrash
        console.error('Failed to move project to trash:', error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <Button
      isIconOnly
      size="sm"
      variant="light"
      isLoading={isLoading}
      className={`min-w-8 w-8 h-8 hover:bg-default-200 ${className}`}
      onClick={handleDelete}
    >
      <BiTrash className="text-gray-500 hover:text-red-500" />
    </Button>
  );
}; 