import React, { useEffect, useMemo, memo, useCallback } from 'react';
import useProjects from '../../hooks/useProjects';
import AuthImage from '../AuthImage';
import { Button, Card, CardBody, Tooltip } from '@nextui-org/react';
import { ProjectDeleteButton } from '../ProjectDeleteButton';
import { useNavigate } from 'react-router-dom';

const ProjectThumbnail = memo(({ videoSHA, projectName, variant = 'compact' }) => (
  <div className={`${
    variant === 'expanded' 
      ? 'w-40 aspect-video'
      : 'w-16 h-9'
  } bg-default-200 rounded flex-shrink-0 overflow-hidden relative`}>
    <AuthImage 
      fileHash={videoSHA}
      alt={`Thumbnail for ${projectName}`}
      className="w-full h-full object-cover absolute inset-0"
      onError={(error) => {
        console.error('Failed to load thumbnail:', videoSHA);
      }}
    />
  </div>
));

const ProjectItem = memo(({ project, onProjectClick, variant = 'compact', hideInputs = false }) => {
  const navigate = useNavigate();
  
  const formatTimestamp = (filename) => {
    const match = filename.match(/(\d+)\.json$/);
    if (!match) return '';
    const timestamp = parseInt(match[1]);
    const date = new Date(timestamp);
    return date.toLocaleString();
  };

  const getModifiedTime = (project) => {
    if (project.metadata?.lastModified) {
      return new Date(project.metadata.lastModified).toLocaleString();
    }
    return formatTimestamp(project.filename);
  };

  const displayName = project.projectName || 'Untitled Cut';
  const modifiedTime = getModifiedTime(project);
  const cutId = project.filename.replace('.json', '');
  
  const handleClick = () => {
    const urlSafeFileName = project.filename.replace('.json', '');
    const projectUrl = `/project/${project.videoSHA}/${urlSafeFileName}`;
    navigate(projectUrl);
    onProjectClick(project);
  };

  const shareUrl = `${window.location.origin}/project/${project.videoSHA}/${project.filename}`;

  const handleShare = (e) => {
    e.stopPropagation();
    navigator.clipboard.writeText(shareUrl);
  };

  if (variant === 'expanded') {
    return (
      <li className="group hover:bg-default-100/50 rounded-xl transition-all duration-200 ease-in-out transform hover:scale-[1.01] hover:shadow-medium backdrop-blur-sm">
        <button
          onClick={handleClick}
          className="text-left w-full flex gap-6 items-center p-5 pr-24"
        >
          <div className="flex flex-col gap-1">
            <ProjectThumbnail 
              videoSHA={project.videoSHA} 
              projectName={displayName}
              variant="expanded"
            />
          </div>
          <div className="flex flex-col min-w-0 flex-1">
            <span className="text-lg font-semibold truncate group-hover:text-primary transition-colors">
              {displayName}
            </span>
            <span className="text-sm text-default-600 truncate mt-0.5">
              Last modified: {modifiedTime}
            </span>
            {!hideInputs ? (
              <span className="font-mono text-xs text-default-500 truncate">
                Cut ID: {cutId}
              </span>
            ) : project.originalFilename && (
              <span className="text-xs text-default-500 italic truncate mt-0.5">
                {project.originalFilename}
              </span>
            )}
          </div>
        </button>
        <div className="absolute right-4 top-0 bottom-0 flex items-center gap-3 opacity-0 group-hover:opacity-100 transition-opacity">
          <Tooltip content="Copy share URL">
            <Button
              isIconOnly
              size="sm"
              variant="light"
              onClick={handleShare}
              className="min-w-8 w-8 h-8 hover:bg-default-200/80"
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                <path d="M4 12v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-8"/>
                <polyline points="16 6 12 2 8 6"/>
                <line x1="12" y1="2" x2="12" y2="15"/>
              </svg>
            </Button>
          </Tooltip>
          <ProjectDeleteButton 
            videoSHA={project.videoSHA}
            filename={project.filename}
            className="relative"
          />
        </div>
      </li>
    );
  }

  return (
    <li className="group hover:bg-default-100 p-2 rounded-lg transition-all duration-200 ease-in-out transform hover:scale-[1.02] hover:shadow-md">
      <button
        onClick={handleClick}
        className="text-left w-full flex gap-3 items-center pr-20"
      >
        <ProjectThumbnail 
          videoSHA={project.videoSHA} 
          projectName={displayName}
          variant="compact"
        />
        <div className="flex flex-col min-w-0 flex-1 overflow-hidden">
          <span className="font-medium truncate group-hover:text-primary transition-colors">
            {displayName}
          </span>
          <span className="text-xs text-default-400 truncate">
            Last modified: {modifiedTime}
          </span>
          {!hideInputs && (
            <span className="text-xs text-default-500 font-mono truncate">
              Cut ID: {cutId}
            </span>
          )}
        </div>
      </button>
      <div className="absolute right-2 top-0 bottom-0 flex items-center gap-3 opacity-0 group-hover:opacity-100 transition-opacity">
        <Tooltip content="Copy share URL">
          <Button
            isIconOnly
            size="sm"
            variant="light"
            onClick={handleShare}
            className="min-w-8 w-8 h-8 hover:bg-default-200"
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
              <path d="M4 12v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-8"/>
              <polyline points="16 6 12 2 8 6"/>
              <line x1="12" y1="2" x2="12" y2="15"/>
            </svg>
          </Button>
        </Tooltip>
        <ProjectDeleteButton 
          videoSHA={project.videoSHA}
          filename={project.filename}
          className="relative"
        />
      </div>
    </li>
  );
});

const LoadingIndicator = ({ progress }) => (
  <div className="flex flex-col items-center justify-center h-full space-y-4 text-default-500">
    <div className="w-full max-w-md bg-default-100 rounded-full h-2.5">
      <div 
        className="bg-primary h-2.5 rounded-full transition-all duration-300 ease-out"
        style={{ width: `${progress}%` }}
      />
    </div>
    <p className="text-sm">Loading projects... {Math.round(progress)}%</p>
  </div>
);

const ProjectBrowser = memo(({ 
  onLoadProject, 
  filterBySHA, 
  variant = 'compact', 
  hideInputs = false,
  searchFilter = '' 
}) => {
  const { fetchFiles, files, isLoading, loadingProgress } = useProjects();

  useEffect(() => {
    fetchFiles(filterBySHA);
    
    // Add event listeners for project updates
    const handleProjectSaved = () => {
      console.log('Caught projectSaved event, refreshing files');
      fetchFiles(filterBySHA);
    };
    
    const handleProjectDeleted = () => {
      console.log('Caught projectDeleted event, refreshing files');
      fetchFiles(filterBySHA);
    };
    
    console.log('Adding project event listeners');
    window.addEventListener('projectSaved', handleProjectSaved);
    window.addEventListener('projectDeleted', handleProjectDeleted);
    
    // Cleanup
    return () => {
      console.log('Removing project event listeners');
      window.removeEventListener('projectSaved', handleProjectSaved);
      window.removeEventListener('projectDeleted', handleProjectDeleted);
    };
  }, [fetchFiles, filterBySHA]);

  const filteredFiles = useMemo(() => {
    let result = files;
    
    // Apply SHA filter if provided
    if (filterBySHA) {
      result = result.filter(f => f.videoSHA === filterBySHA);
    }
    
    // Apply search filter if provided
    if (searchFilter.trim()) {
      const normalizedSearch = searchFilter.toLowerCase().trim();
      result = result.filter(project => {
        const projectName = (project.projectName || '').toLowerCase();
        const fileName = (project.filename || '').toLowerCase();
        const originalFileName = (project.originalFilename || '').toLowerCase();
        
        return projectName.includes(normalizedSearch) || 
               fileName.includes(normalizedSearch) ||
               originalFileName.includes(normalizedSearch);
      });
    }
    
    return result;
  }, [files, filterBySHA, searchFilter]);

  const handleRefresh = useCallback(() => {
    fetchFiles(filterBySHA);
  }, [fetchFiles, filterBySHA]);

  const content = (
    <div className={`h-full overflow-hidden col-span-12 grid grid-cols-12 grid-rows-[auto,1fr] 
      ${variant === 'expanded' ? 'gap-4 pt-2' : ''}`}>
      <div className="flex justify-between items-center mb-4 col-span-12 px-4">
        <h2 className="text-2xl font-semibold text-default-900">
          Recent Cuts
        </h2>
        {!variant === 'expanded' && (
          <Button 
            size="sm"
            onClick={handleRefresh}
            className="bg-default-100 hover:bg-default-200"
            isLoading={isLoading}
          >
            Refresh List
          </Button>
        )}
      </div>
      {isLoading ? (
        <div className="col-span-12 flex items-center justify-center h-full">
          <LoadingIndicator progress={loadingProgress} />
        </div>
      ) : filteredFiles.length === 0 ? (
        <div className="col-span-12 flex items-center justify-center h-full text-default-500">
          <p>No cuts available yet</p>
        </div>
      ) : (
        <div className='h-full overflow-hidden col-span-12'>
          <ul className={`h-full overflow-y-auto px-2 space-y-3`}>
            {filteredFiles.map((project) => (
              <ProjectItem 
                key={project.videoSHA + project.filename} 
                project={project} 
                onProjectClick={onLoadProject}
                variant={variant}
                hideInputs={hideInputs}
              />
            ))}
          </ul>
        </div>
      )}
    </div>
  );

  if (hideInputs) {
    return (
      <Card className="bg-background/40 backdrop-blur-sm backdrop-saturate-150 border-small border-default-200">
        <CardBody>
          {content}
        </CardBody>
      </Card>
    );
  }

  return content;
}, (prevProps, nextProps) => {
  return prevProps.filterBySHA === nextProps.filterBySHA &&
         prevProps.onLoadProject === nextProps.onLoadProject &&
         prevProps.hideInputs === nextProps.hideInputs &&
         prevProps.searchFilter === nextProps.searchFilter;
});

export default ProjectBrowser;