import React, { useState, useRef, useEffect } from 'react';
import { Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Button, Input } from "@nextui-org/react";
import useStore from '../../store';

export const SaveModal = ({ isOpen, onClose, onSave }) => {
  const { setIsTextInputFocused, projectName: existingProjectName } = useStore();
  const [newProjectName, setNewProjectName] = useState(existingProjectName || '');
  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState(null);
  const updateButtonRef = useRef(null);

  useEffect(() => {
    if (isOpen) {
      // Small delay to ensure the modal is fully rendered
      const timeoutId = setTimeout(() => {
        updateButtonRef.current?.focus();
      }, 50);
      return () => clearTimeout(timeoutId);
    }
  }, [isOpen]);

  const handleSave = async (isNewProject = false) => {
    setError(null);
    setIsSaving(true);
    try {
      // For regular save, if no name is provided, use existing name or let backend handle default
      // For save as new, always use the new name if provided, otherwise timestamp will be used in backend
      const nameToUse = isNewProject ? newProjectName : (newProjectName || existingProjectName);
      await onSave(isNewProject, nameToUse);
      onClose();
    } catch (err) {
      setError(err.message);
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <Modal 
      isOpen={isOpen} 
      onClose={onClose}
      classNames={{
        base: "!outline-none"
      }}
    >
      <ModalContent>
        <ModalHeader>Save Project</ModalHeader>
        <ModalBody>
          <div className="flex flex-col gap-4">
            <div className="flex flex-col gap-2">
              <Input
                label="Project Name (optional)"
                placeholder="Enter a name for your project"
                value={newProjectName}
                onChange={(e) => setNewProjectName(e.target.value)}
                onFocus={() => setIsTextInputFocused(true)}
                onBlur={() => setIsTextInputFocused(false)}
              />
            </div>
            {error && (
              <div className="text-red-500 text-sm">
                Error: {error}
              </div>
            )}
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="danger" variant="light" onPress={onClose}>
            Cancel
          </Button>
          <Button
            ref={updateButtonRef}
            onPress={() => handleSave(false)}
            isDisabled={isSaving}
            className="bg-gradient-to-tr from-purple-500 to-blue-500 text-white shadow-lg hover:shadow-xl transition-shadow focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 focus:outline-none"
          >
            Update Project
          </Button>
          <Button
            onPress={() => handleSave(true)}
            isDisabled={isSaving}
            className="bg-gradient-to-tr from-pink-500 to-yellow-500 text-white shadow-lg hover:shadow-xl transition-shadow focus:ring-2 focus:ring-pink-500 focus:ring-offset-2 focus:outline-none"
          >
            Save As New Project
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}; 