import { openDB } from 'idb';
import useStore from '../store';

// Helper to get store functions without needing a component
const getStoreActions = () => {
  const { startProgress, setProgress, completeProgress, setProgressError } = useStore.getState();
  return { startProgress, setProgress, completeProgress, setProgressError };
};

class VideoCacheService {
  static dbName = 'spookycutter-cache';
  static storeName = 'videos';
  static version = 2;
  static dbPromise = null; // Single database connection for reuse
  
  // Cache expiration settings (in milliseconds)
  static DEFAULT_EXPIRATION = 60 * 24 * 60 * 60 * 1000; // 60 days by default (longer than thumbnails)
  static expirationTime = VideoCacheService.DEFAULT_EXPIRATION;
  
  // Set custom expiration time
  static setExpirationTime(milliseconds) {
    this.expirationTime = milliseconds;
    console.log(`VideoCacheService: Set expiration time to ${milliseconds}ms (${milliseconds / (24 * 60 * 60 * 1000)} days)`);
  }

  static async initDB() {
    // Reuse existing DB connection if available
    if (this.dbPromise) {
      return this.dbPromise;
    }

    console.log('VideoCacheService: Initializing IndexedDB');
    this.dbPromise = openDB(this.dbName, this.version, {
      upgrade(db, oldVersion, newVersion, transaction) {
        console.log(`VideoCacheService: Upgrading database from v${oldVersion} to v${newVersion}`);
        
        // Create all stores our app needs if they don't exist
        if (!db.objectStoreNames.contains(VideoCacheService.storeName)) {
          db.createObjectStore(VideoCacheService.storeName);
        }
        // Also ensure the thumbnails store exists for compatibility
        if (!db.objectStoreNames.contains('thumbnails')) {
          db.createObjectStore('thumbnails');
        }
      },
    });
    
    // Clean expired items when initializing (but without blocking)
    this.cleanExpiredItems().catch(err => {
      console.warn('Failed to clean expired videos:', err);
    });
    
    return this.dbPromise;
  }

  static async cacheVideo(sha, blob) {
    console.log(`VideoCacheService: Caching video ${sha}`);
    try {
      const db = await this.initDB();
      
      // Create cache item with metadata
      const cacheItem = {
        data: blob,
        timestamp: Date.now(),
        lastAccessed: Date.now(),
        size: blob.size
      };
      
      await db.put(this.storeName, cacheItem, sha);
      const sizeMB = (blob.size / (1024 * 1024)).toFixed(2);
      console.log(`VideoCacheService: Cached video ${sha} (${sizeMB} MB)`);
    } catch (error) {
      console.error(`VideoCacheService: Failed to cache video ${sha}`, error);
      throw new Error('Failed to cache video');
    }
  }

  static async getCachedVideo(sha) {
    try {
      // Get progress tracking functions to show loading progress
      const { startProgress, setProgress, completeProgress } = getStoreActions();
      
      // Start progress tracking for video loading
      startProgress('videoCache', `Checking cache for video ${sha.substring(0, 8)}...`);
      setProgress('videoCache', { 
        progress: 10,
        operation: "checking"
      });
      
      const db = await this.initDB();
      const cacheItem = await db.get(this.storeName, sha);
      
      // If nothing found, return null
      if (!cacheItem) {
        console.log(`VideoCacheService: Cache miss for ${sha}`);
        // Update progress with cache miss status and wait to ensure UI updates
        setProgress('videoCache', { 
          progress: 100,
          operation: "cache_miss"
        });
        
        // Add a short delay to ensure the user sees the "Cache miss" message before moving on
        await new Promise(resolve => setTimeout(resolve, 800));
        
        console.log(`VideoCacheService: Proceeding to fetch video from server for ${sha}`);
        // Complete the cache check progress operation
        completeProgress('videoCache');
        return null;
      }
      
      // If it's the old format (just a blob), convert it to new format
      if (cacheItem instanceof Blob) {
        const sizeMB = (cacheItem.size / (1024 * 1024)).toFixed(2);
        console.log(`VideoCacheService: Cache hit for ${sha} (${sizeMB} MB) - old format, upgrading`);
        
        setProgress('videoCache', { 
          progress: 80,
          operation: "cached"
        });
        
        // Update the cache with the new format (but don't await to avoid delay)
        this.cacheVideo(sha, cacheItem).catch(err => {
          console.warn(`Failed to upgrade cache format for video ${sha}:`, err);
        });
        
        // Show completion after a short delay (visual feedback)
        setTimeout(() => completeProgress('videoCache'), 500);
        
        return cacheItem; // Return the blob directly for backward compatibility
      }
      
      // If it's expired, remove it and return null
      if (this.isExpired(cacheItem)) {
        console.log(`VideoCacheService: Expired video found for ${sha}, removing`);
        setProgress('videoCache', { 
          progress: 100,
          operation: "cache_expired"
        });
        
        // Add a short delay to ensure the user sees the "Cache expired" message before moving on
        await new Promise(resolve => setTimeout(resolve, 800));
        
        // Delete but don't block on it
        db.delete(this.storeName, sha).catch(err => console.warn('Error deleting expired video:', err));
        completeProgress('videoCache');
        return null;
      }
      
      // Update last accessed time (don't await to avoid delay)
      cacheItem.lastAccessed = Date.now();
      db.put(this.storeName, cacheItem, sha).catch(err => {
        console.warn(`Failed to update last accessed time for video ${sha}:`, err);
      });
      
      const sizeMB = (cacheItem.data.size / (1024 * 1024)).toFixed(2);
      console.log(`VideoCacheService: Cache hit for ${sha} (${sizeMB} MB)`);
      
      setProgress('videoCache', { 
        progress: 90,
        operation: "cached"
      });
      
      // Show completion after a short delay (visual feedback)
      setTimeout(() => completeProgress('videoCache'), 500);
      
      return cacheItem.data; // Return just the blob for backward compatibility
    } catch (error) {
      console.error(`VideoCacheService: Failed to retrieve video ${sha}`, error);
      
      // Report error in progress
      const { setProgressError } = getStoreActions();
      setProgressError('videoCache', error.message || 'Failed to load video from cache');
      
      throw error;
    }
  }
  
  // Check if a cache item is expired
  static isExpired(cacheItem) {
    const now = Date.now();
    const age = now - cacheItem.timestamp;
    
    // For videos, we can also consider last access time to keep frequently used videos
    const lastAccess = now - cacheItem.lastAccessed;
    
    // Expire if both the item is old AND hasn't been accessed recently
    return age > this.expirationTime && lastAccess > (this.expirationTime / 3);
  }
  
  // Clean expired items from cache
  static async cleanExpiredItems() {
    console.log('VideoCacheService: Checking for expired videos...');
    
    // Get progress tracking functions
    const { startProgress, setProgress, completeProgress } = getStoreActions();
    
    // Start progress tracking
    startProgress('videoCache', 'Scanning video cache for expired items');
    
    const db = await this.initDB();
    const tx = db.transaction(this.storeName, 'readwrite');
    const store = tx.objectStore(this.storeName);
    const allKeys = await store.getAllKeys();
    
    // Check storage usage before cleaning
    const { quota, usage } = await this.getStorageEstimate();
    const usagePercent = usage && quota ? ((usage / quota) * 100).toFixed(2) : 'unknown';
    console.log(`VideoCacheService: Storage usage before cleaning: ${usagePercent}% (${this.formatBytes(usage)} / ${this.formatBytes(quota)})`);
    
    let expiredCount = 0;
    let bytesFreed = 0;
    
    // Update progress to show we're starting the scan
    setProgress('videoCache', { 
      progress: 5,
      operation: `Scanning ${allKeys.length} cached videos for expiration`
    });
    
    for (let i = 0; i < allKeys.length; i++) {
      const key = allKeys[i];
      const cacheItem = await store.get(key);
      
      // Update progress occasionally
      if (i % 5 === 0 || i === allKeys.length - 1) {
        setProgress('videoCache', { 
          progress: Math.round(((i + 1) / allKeys.length) * 90) + 5, // 5-95% during scan
          operation: `Scanning videos (${i + 1}/${allKeys.length})`
        });
      }
      
      // Skip if it's just a blob (old format)
      if (cacheItem instanceof Blob) continue;
      
      if (cacheItem && this.isExpired(cacheItem)) {
        bytesFreed += cacheItem.size || 0;
        await store.delete(key);
        expiredCount++;
      }
    }
    
    // Update progress to show results
    setProgress('videoCache', { 
      progress: 95,
      operation: expiredCount > 0 
        ? `Removed ${expiredCount} expired videos (${(bytesFreed / (1024 * 1024)).toFixed(2)} MB)`
        : 'No expired videos found'
    });
    
    if (expiredCount > 0) {
      const mbFreed = (bytesFreed / (1024 * 1024)).toFixed(2);
      console.log(`VideoCacheService: Cleaned ${expiredCount} expired videos, freed ${mbFreed} MB`);
    } else {
      console.log('VideoCacheService: No expired videos found');
    }
    
    await tx.done;
    
    // Mark operation as complete
    completeProgress('videoCache');
    
    return { expiredCount, bytesFreed };
  }

  static async uploadAndCacheFile(file, onProgress) {
    // Get progress tracking functions
    const { startProgress, setProgress, completeProgress, setProgressError } = getStoreActions();
    
    // Start progress tracking
    startProgress('videoCache', `Uploading and caching ${file.name}`);
    
    try {
      // Create FormData
      const formData = new FormData();
      formData.append('file', file);

      // Upload with progress tracking
      const response = await new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        
        xhr.upload.onprogress = (event) => {
          if (event.lengthComputable) {
            const progress = (event.loaded / event.total) * 100;
            
            // Update both our custom progress callback and the store
            onProgress?.(progress);
            
            setProgress('videoCache', { 
              progress: Math.round(progress * 0.8), // Upload is 80% of the process
              operation: `Uploading ${file.name} (${Math.round(progress)}%)`
            });
          }
        };

        xhr.onload = () => {
          if (xhr.status === 200) {
            try {
              resolve(JSON.parse(xhr.responseText));
            } catch (e) {
              reject(new Error('Invalid response format'));
            }
          } else {
            reject(new Error(`Upload failed: ${xhr.statusText}`));
          }
        };

        xhr.onerror = () => reject(new Error('Network error during upload'));
        
        xhr.open('POST', '/api/upload');
        xhr.send(formData);
      });

      // Update progress to show we're caching now
      setProgress('videoCache', { 
        progress: 80,
        operation: `Caching ${file.name} locally`
      });

      // Cache the file if upload was successful
      if (response.fileHash) {
        // Get file as blob
        const blob = await file.arrayBuffer().then(buffer => new Blob([buffer]));
        
        // Cache the blob
        await this.cacheVideo(response.fileHash, blob);
        
        // Complete the operation
        setProgress('videoCache', { 
          progress: 95,
          operation: `Successfully cached ${file.name}`
        });
        
        completeProgress('videoCache');
        
        return response;
      }

      setProgressError('videoCache', 'Upload response missing fileHash');
      throw new Error('Upload response missing fileHash');

    } catch (error) {
      console.error('VideoCacheService: Upload failed:', error);
      setProgressError('videoCache', error.message || 'Failed to upload and cache file');
      throw error;
    }
  }

  static async clearCache() {
    try {
      // Get progress tracking functions
      const { startProgress, completeProgress, setProgressError } = getStoreActions();
      
      // Start progress tracking
      startProgress('videoCache', 'Clearing video cache');
      
      const db = await this.initDB();
      await db.clear(this.storeName);
      
        console.log('VideoCacheService: Cache cleared');
      
      // Complete the operation
      completeProgress('videoCache');
    } catch (error) {
      console.error('VideoCacheService: Failed to clear cache', error);
      
      // Set error in progress tracking
      const { setProgressError } = getStoreActions();
      setProgressError('videoCache', error.message || 'Failed to clear cache');
      
      throw new Error('Failed to clear cache');
    }
  }
  
  // Format bytes to human-readable format
  static formatBytes(bytes, decimals = 2) {
    if (!bytes) return '0 Bytes';
    
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }
  
  // Get browser storage estimate
  static async getStorageEstimate() {
    if (navigator.storage && navigator.storage.estimate) {
      return await navigator.storage.estimate();
    }
    return { quota: null, usage: null };
  }

  static async getCacheSize() {
    try {
      const db = await this.initDB();
      const tx = db.transaction(this.storeName, 'readonly');
      const store = tx.objectStore(this.storeName);
      const allItems = await store.getAll();
      
      let totalSize = 0;
      let oldestTimestamp = Date.now();
      let newestTimestamp = 0;
      let videoCount = 0;
      
      for (const item of allItems) {
        // Handle old format (just blob)
        if (item instanceof Blob) {
          totalSize += item.size;
          videoCount++;
          continue;
        }
        
        // Handle new format with metadata
        if (item && item.data) {
          totalSize += item.size || 0;
          videoCount++;
          
          if (item.timestamp < oldestTimestamp) {
            oldestTimestamp = item.timestamp;
          }
          
          if (item.timestamp > newestTimestamp) {
            newestTimestamp = item.timestamp;
          }
        }
      }
      
      const stats = {
        videoCount,
        totalSize,
        totalSizeMB: (totalSize / (1024 * 1024)).toFixed(2),
        oldestVideo: oldestTimestamp === Date.now() ? null : new Date(oldestTimestamp),
        newestVideo: newestTimestamp === 0 ? null : new Date(newestTimestamp),
        formatted: this.formatBytes(totalSize)
      };
      
      console.log(`VideoCacheService: Cache contains ${videoCount} videos totaling ${stats.formatted}`);
      await tx.done;
      return stats;
    } catch (error) {
      console.error('VideoCacheService: Failed to calculate cache size', error);
      throw new Error('Failed to calculate cache size');
    }
  }
}

// Export the service
export default VideoCacheService; 