import axios from '../../utils/axios';

// No need for axios import since we'll use fetch directly to Google's API

const languageMap = {
  english: 'en',
  'english-forced': 'en',
  spanish: 'es',
  french: 'fr',
  japanese: 'ja',
  italian: 'it',
  russian: 'ru',
  polish: 'pl',
  korean: 'ko',
  portuguese: 'pt',
};

const SHOW_TRANSLATION_INFO = false;  // Toggle this to enable/disable the translation info message

export class TranslateSRTService {
  static async translateWithGPT(text, targetLanguage, model, context = null, retryCount = 0) {
    const maxRetries = 3;
    const baseDelay = 1000; // Start with 1 second delay

    try {
      const systemPrompt = `You are a professional translator specializing in translating any language to ${targetLanguage}.
      Maintain the natural flow and style of speech while translating.
      Context from adjacent subtitles is provided to help with continuity.
      Important formatting rules:
    - NEVER add quotes around the translation, even for speech
    - Do not add dashes or bullet points
    - Don't add line breaks or any additional formatting
      - Keep the text plain without any special formatting
      - Preserve any existing parentheses for sound effects
      Respond only with the translation, no explanations or additional text.`;
      
      let content = text;
      if (context) {
        content = `Previous subtitles for context:
${context.previous.map(entry => `- ${entry}`).join('\n')}

Following subtitles for context:
${context.next.map(entry => `- ${entry}`).join('\n')}

>>> Translate this text: "${text}"`;
      }

      const response = await fetch('https://api.openai.com/v1/chat/completions', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${import.meta.env.VITE_OPENAI_API_KEY}`
        },
        body: JSON.stringify({
          model: model === 'gpt4' ? 'gpt-4-turbo-preview' : 'gpt-3.5-turbo',
          messages: [
            { role: 'system', content: systemPrompt },
            { role: 'user', content }
          ],
          temperature: 0.3,
        })
      });

      const data = await response.json();
      
      if (!response.ok) {
        if (data.error?.code === 'rate_limit_exceeded' && retryCount < maxRetries) {
          // Extract wait time from error message or use exponential backoff
          const waitTimeMatch = data.error.message.match(/Please try again in (\d+)ms/);
          const waitTime = waitTimeMatch ? 
            parseInt(waitTimeMatch[1]) + 100 : // Add 100ms buffer
            baseDelay * Math.pow(2, retryCount);
          
          console.log(`Rate limit hit, retrying in ${waitTime}ms... (Attempt ${retryCount + 1}/${maxRetries})`);
          await new Promise(resolve => setTimeout(resolve, waitTime));
          return this.translateWithGPT(text, targetLanguage, model, context, retryCount + 1);
        }
        throw new Error(`OpenAI API Error: ${JSON.stringify(data.error || data)}`);
      }

      return data.choices[0].message.content.trim();
    } catch (error) {
      if (error.message.includes('rate_limit_exceeded') && retryCount < maxRetries) {
        const delay = baseDelay * Math.pow(2, retryCount);
        console.log(`Rate limit hit, retrying in ${delay}ms... (Attempt ${retryCount + 1}/${maxRetries})`);
        await new Promise(resolve => setTimeout(resolve, delay));
        return this.translateWithGPT(text, targetLanguage, model, context, retryCount + 1);
      }
      throw error;
    }
  }

  static async translateWithGoogle(text, targetLanguage, sourceLanguage) {
    const targetLangCode = languageMap[targetLanguage.toLowerCase()] || targetLanguage;
    let sourceLangCode = languageMap[sourceLanguage.toLowerCase()] || sourceLanguage;

    // If using forced English, set source to Korean to bypass the same-language error
    if (targetLanguage === 'english-forced') {
      sourceLangCode = 'ko';  // Force source to Korean
    }

    const response = await fetch(
      `https://translation.googleapis.com/language/translate/v2?key=${import.meta.env.VITE_GOOGLE_TRANSLATE_API_KEY}`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          q: [text],
          source: sourceLangCode,
          target: targetLangCode,
          format: 'text'
        })
      }
    );

    const data = await response.json();
    if (!response.ok) {
      throw new Error(`Google Translate API Error: ${JSON.stringify(data.error || data)}`);
    }

    return data.data.translations[0].translatedText;
  }

  static async translateSRT(srtContent, targetLanguage, sourceLanguage = 'korean', model = 'google', enforcePunctuation = true) {
    try {
      console.log('TranslateSRT called with model:', model);
      
      const response = await axios.post('/project/translate-srt', {
        srtContent,
        targetLanguage,
        sourceLanguage,
        model,
        enforcePunctuation
      });

      if (!response.data || !response.data.translatedContent) {
        throw new Error('Invalid response from translation service');
      }

      return response.data.translatedContent;
    } catch (error) {
      console.error('[TRANSLATE SRT] Error:', error);
      throw error;
    }
  }
}