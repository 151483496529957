import React from 'react';
import { Checkbox } from '@nextui-org/checkbox';
import { Input, Button, Slider, Card, CardBody } from "@nextui-org/react";
import useStore from '../../store';
import clsx from "clsx";
import { AIOrganizer } from '../AIOrganizer/index.jsx';

// Custom button styles using the highlight colors
const CustomButton = ({ color, ...props }) => {
  const buttonClasses = clsx(
    "transition-colors w-full",
    color === "highlight" && "bg-yellow-100/70 hover:bg-yellow-100/80",
    color === "current" && "bg-gradient-to-tr from-purple-500 to-blue-500 text-white shadow-lg hover:shadow-xl transition-shadow",
    color === "reset" && "bg-gradient-to-tr from-pink-500 to-yellow-500 text-white shadow-lg hover:shadow-xl transition-shadow",
  );

  return (
    <Button
      {...props}
      className={clsx(props.className, buttonClasses)}
      size="sm"
    />
  );
};

// Compact helper text component
const HelperText = ({ children, className }) => (
  <p className={clsx("text-xs text-default-500 italic", className)}>
    {children}
  </p>
);

// Slider with label component
const LabeledSlider = ({ label, value, ...props }) => (
  <div className="flex-1">
    <span className="text-xs text-default-600 mb-1 block">
      {label}: {value}
    </span>
    <Slider
      size="sm"
      className="max-w-full"
      aria-label={label}
      {...props}
    />
  </div>
);

export const TranscriptSettings = ({
  onGenerateTranscript,
  showRegenerateButton,
  onRegenerateTranscript,
  disabled,
  hasVideo,
  onLoadLocalProject,
  setHasGeneratedBlocks,
}) => {
  const {
    breakAtPunctuation = false,
    setBreakAtPunctuation,
    useMaxCharacters = false,
    setUseMaxCharacters,
    maxCharPerSection,
    setMaxCharPerSection,
    breakOnSpeakerChange,
    setBreakOnSpeakerChange,
    rejectLongSilences,
    setRejectLongSilences,
    longSilenceThreshold = 0.2,
    setLongSilenceThreshold,
    breakOnLongSilences,
    setBreakOnLongSilences,
    rejectBlocksContaining,
    setRejectBlocksContaining,
    rejectBlocksCondition,
    setRejectBlocksCondition,
    rejectBlocksWord,
    setRejectBlocksWord,
    setIsTextInputFocused,
  } = useStore();

  const updateTranscript = async () => {
    try {
      const localProject = await onLoadLocalProject();
      if (localProject) {
        setHasGeneratedBlocks(true);
      }
    } catch (error) {
      console.error('Error loading project:', error);
      throw error;
    }
  };

  return (
    <Card>
      <CardBody className="container-type-inline-size">
        <div className="flex flex-col gap-3">
          {/* Generate Button Section */}
          <div>
            {!showRegenerateButton ? (
              <CustomButton color="current" onClick={onGenerateTranscript}>
                Generate Transcript
              </CustomButton>
            ) : null}
          </div>

          {/* Settings Section */}
          <div>
            <p className="text-small font-bold mb-1">Cut Blocks at...</p>
            <div className="space-y-3 pl-1">
              {/* Basic Checkboxes */}
              <div className="flex flex-wrap gap-2">
                <Checkbox
                  size="sm"
                  isSelected={breakAtPunctuation}
                  onValueChange={setBreakAtPunctuation}
                >
                  <span className="text-xs">Punctuation</span>
                </Checkbox>
                {/* Temporarily hidden until feature is ready
                <Checkbox
                  size="sm"
                  isSelected={breakOnSpeakerChange}
                  onValueChange={setBreakOnSpeakerChange}
                >
                  <span className="text-xs">Speaker Change</span>
                </Checkbox>
                */}
              </div>

              {/* Max Characters Section */}
              <div className={clsx("flex flex-col gap-1", !useMaxCharacters && "opacity-50")}>
                <Checkbox
                  size="sm"
                  isSelected={useMaxCharacters}
                  onValueChange={setUseMaxCharacters}
                >
                  <span className="text-xs">Max Characters</span>
                </Checkbox>
                <LabeledSlider
                  label="Chars per section"
                  value={maxCharPerSection.toLocaleString()}
                  step={maxCharPerSection < 50 ? 1 : maxCharPerSection < 200 ? 5 : maxCharPerSection < 1000 ? 25 : 100}
                  minValue={1}
                  maxValue={5000}
                  defaultValue={500}
                  onChange={value => setMaxCharPerSection(value)}
                  disabled={!useMaxCharacters}
                  marks={[
                    { value: 1, label: '1' },
                    { value: 2500, label: '2.5k' },
                    { value: 5000, label: '5k' }
                  ]}
                />
              </div>

              {/* Silences Section */}
              <div className={clsx("flex flex-col gap-1", !breakOnLongSilences && "opacity-50")}>
                <div className="flex flex-wrap items-center gap-2">
                  <Checkbox
                    size="sm"
                    isSelected={breakOnLongSilences}
                    onValueChange={setBreakOnLongSilences}
                  >
                    <span className="text-xs">Silences</span>
                  </Checkbox>
                  <Checkbox
                    size="sm"
                    isSelected={rejectLongSilences}
                    onValueChange={setRejectLongSilences}
                    isDisabled={!breakOnLongSilences}
                  >
                    <span className="text-xs">Reject Silences</span>
                  </Checkbox>
                </div>
                <LabeledSlider
                  label="Silence Threshold"
                  value={`${longSilenceThreshold}s`}
                  step={0.1}
                  minValue={0.1}
                  maxValue={60}
                  onChange={value => setLongSilenceThreshold(value)}
                  disabled={!breakOnLongSilences}
                  marks={[
                    { value: 0.1, label: '0.1s' },
                    { value: 30, label: '30s' },
                    { value: 60, label: '60s' }
                  ]}
                />
              </div>

              {/* Reject Blocks Section */}
              <div className={clsx("flex flex-col gap-1", !rejectBlocksContaining && "opacity-50")}>
                <Checkbox
                  size="sm"
                  isSelected={rejectBlocksContaining}
                  onValueChange={setRejectBlocksContaining}
                >
                  <span className="text-xs">Reject blocks that</span>
                </Checkbox>
                <div className="flex flex-wrap items-center gap-2 pl-2">
                  <select
                    value={rejectBlocksCondition}
                    onChange={(e) => setRejectBlocksCondition(e.target.value)}
                    disabled={!rejectBlocksContaining}
                    className="px-2 py-1 text-xs rounded border border-default-200"
                  >
                    <option value="contain">contain</option>
                    <option value="dont-contain">exclude</option>
                  </select>
                  <Input
                    size="sm"
                    placeholder="word/phrase"
                    value={rejectBlocksWord}
                    onChange={(e) => setRejectBlocksWord(e.target.value)}
                    onFocus={() => setIsTextInputFocused(true)}
                    onBlur={() => setIsTextInputFocused(false)}
                    disabled={!rejectBlocksContaining}
                    className="max-w-[120px] min-w-[80px]"
                  />
                </div>
              </div>
            </div>
          </div>

          {/* Reset Button Section */}
          {showRegenerateButton && (
            <div className="flex flex-col gap-1">
              <CustomButton color="reset" onClick={onRegenerateTranscript} disabled={disabled}>
                Reset Transcript
              </CustomButton>
              <HelperText>
                Save before resetting to preserve changes
              </HelperText>
            </div>
          )}

          {/* AI Organizer Section */}
          {showRegenerateButton && (
            <AIOrganizer />
          )}
        </div>
      </CardBody>
    </Card>
  );
};