import { openDB } from 'idb';
import axios from '../utils/axios';
import useStore from '../store';

// Helper to get store functions without needing a component
const getStoreActions = () => {
  const { startProgress, setProgress, completeProgress, setProgressError } = useStore.getState();
  return { startProgress, setProgress, completeProgress, setProgressError };
};

class ThumbnailCacheService {
  static dbName = 'spookycutter-cache';
  static storeName = 'thumbnails';
  static version = 2;
  static dbPromise = null; // Single database connection for reuse
  
  // Cache expiration settings (in milliseconds)
  static DEFAULT_EXPIRATION = 30 * 24 * 60 * 60 * 1000; // 30 days by default
  static expirationTime = ThumbnailCacheService.DEFAULT_EXPIRATION;
  
  // Set custom expiration time
  static setExpirationTime(milliseconds) {
    this.expirationTime = milliseconds;
    console.log(`ThumbnailCacheService: Set expiration time to ${milliseconds}ms (${milliseconds / (24 * 60 * 60 * 1000)} days)`);
  }

  static async initDB() {
    // Reuse existing DB connection if available
    if (this.dbPromise) {
      return this.dbPromise;
    }

    console.log('ThumbnailCacheService: Initializing IndexedDB');
    this.dbPromise = openDB(this.dbName, this.version, {
      upgrade(db, oldVersion, newVersion, transaction) {
        console.log(`ThumbnailCacheService: Upgrading database from v${oldVersion} to v${newVersion}`);
        
        // Create all stores our app needs if they don't exist
        if (!db.objectStoreNames.contains(ThumbnailCacheService.storeName)) {
          db.createObjectStore(ThumbnailCacheService.storeName);
        }
        
        // Also ensure the videos store exists for compatibility
        if (!db.objectStoreNames.contains('videos')) {
          db.createObjectStore('videos');
        }
      },
    });
    
    // Clean expired items when initializing
    this.cleanExpiredItems().catch(err => {
      console.warn('Failed to clean expired thumbnails:', err);
    });
    
    return this.dbPromise;
  }

  static async getCachedThumbnail(fileHash) {
    const db = await this.initDB();
    const cacheItem = await db.get(this.storeName, fileHash);
    
    // If nothing found, return null
    if (!cacheItem) return null;
    
    // If it's the old format (just a blob), convert it to new format
    if (cacheItem instanceof Blob) {
      const newCacheItem = {
        data: cacheItem,
        timestamp: Date.now(), // We don't know when it was cached, so using now
        lastAccessed: Date.now(),
        size: cacheItem.size
      };
      
      // Update the cache with the new format
      await this.cacheThumbnail(fileHash, cacheItem);
      
      console.log('ThumbnailCacheService: Cache hit for:', fileHash, '(converted to new format)');
      return cacheItem; // Return the blob directly for backward compatibility
    }
    
    // If it's expired, remove it and return null
    if (this.isExpired(cacheItem)) {
      console.log('ThumbnailCacheService: Expired thumbnail found for:', fileHash);
      await db.delete(this.storeName, fileHash);
      return null;
    }
    
    // Update last accessed time
    cacheItem.lastAccessed = Date.now();
    await db.put(this.storeName, cacheItem, fileHash);
    
    console.log('ThumbnailCacheService: Cache hit for:', fileHash);
    return cacheItem.data; // Return just the blob for backward compatibility
  }

  static async cacheThumbnail(fileHash, blob) {
    console.log('ThumbnailCacheService: Caching thumbnail for:', fileHash);
    const db = await this.initDB();
    
    // Create cache item with metadata
    const cacheItem = {
      data: blob,
      timestamp: Date.now(),
      lastAccessed: Date.now(),
      size: blob.size
    };
    
    await db.put(this.storeName, cacheItem, fileHash);
  }

  // Check if a cache item is expired
  static isExpired(cacheItem) {
    const now = Date.now();
    const age = now - cacheItem.timestamp;
    return age > this.expirationTime;
  }

  // Clean expired items from cache
  static async cleanExpiredItems() {
    console.log('ThumbnailCacheService: Checking for expired thumbnails...');
    
    // Get progress tracking functions
    const { startProgress, setProgress, completeProgress } = getStoreActions();
    
    // Start progress tracking
    startProgress('thumbnailCache', 'Scanning thumbnail cache for expired items');
    
    const db = await this.initDB();
    const tx = db.transaction(this.storeName, 'readwrite');
    const store = tx.objectStore(this.storeName);
    const allKeys = await store.getAllKeys();
    
    // Update progress
    setProgress('thumbnailCache', { 
      progress: 5,
      operation: `Scanning ${allKeys.length} cached thumbnails for expiration`
    });
    
    let expiredCount = 0;
    let bytesFreed = 0;
    
    for (let i = 0; i < allKeys.length; i++) {
      const key = allKeys[i];
      const cacheItem = await store.get(key);
      
      // Update progress occasionally
      if (i % 10 === 0 || i === allKeys.length - 1) {
        setProgress('thumbnailCache', { 
          progress: Math.round(((i + 1) / allKeys.length) * 90) + 5, // 5-95% during scan
          operation: `Scanning thumbnails (${i + 1}/${allKeys.length})`
        });
      }
      
      // Skip if it's just a blob (old format)
      if (cacheItem instanceof Blob) continue;
      
      if (this.isExpired(cacheItem)) {
        bytesFreed += cacheItem.size || 0;
        await store.delete(key);
        expiredCount++;
      }
    }
    
    // Update progress to show results
    setProgress('thumbnailCache', { 
      progress: 95,
      operation: expiredCount > 0 
        ? `Removed ${expiredCount} expired thumbnails (${(bytesFreed / (1024 * 1024)).toFixed(2)} MB)`
        : 'No expired thumbnails found'
    });
    
    if (expiredCount > 0) {
      const mbFreed = (bytesFreed / (1024 * 1024)).toFixed(2);
      console.log(`ThumbnailCacheService: Cleaned ${expiredCount} expired thumbnails, freed ${mbFreed} MB`);
    } else {
      console.log('ThumbnailCacheService: No expired thumbnails found');
    }
    
    await tx.done;
    
    // Mark operation as complete
    completeProgress('thumbnailCache');
    
    return { expiredCount, bytesFreed };
  }

  // Check multiple thumbnails in a single IndexedDB transaction
  static async batchCheckCache(fileHashes) {
    const db = await this.initDB();
    const tx = db.transaction(this.storeName, 'readonly');
    const store = tx.objectStore(this.storeName);
    
    // Create a map of hashes to cached status
    const cacheStatus = {};
    await Promise.all(fileHashes.map(async (hash) => {
      try {
        const cacheItem = await store.get(hash);
        
        // If it's a blob (old format) or a valid non-expired cache item
        if (cacheItem instanceof Blob || (cacheItem && !this.isExpired(cacheItem))) {
          cacheStatus[hash] = true;
        } else {
          cacheStatus[hash] = false;
        }
      } catch (e) {
        cacheStatus[hash] = false;
      }
    }));
    
    await tx.done;
    return cacheStatus;
  }

  static async prefetchThumbnails(fileHashes) {
    console.log('ThumbnailCacheService: Prefetching thumbnails:', fileHashes);
    if (!fileHashes || fileHashes.length === 0) return;
    
    // Get progress tracking functions
    const { startProgress, setProgress, completeProgress, setProgressError } = getStoreActions();
    
    try {
      // Start progress tracking if there are thumbnails to fetch
      startProgress('thumbnailCache', `Checking cache status for ${fileHashes.length} thumbnails`);
    
      // First check which thumbnails are already cached
      const cacheStatus = await this.batchCheckCache(fileHashes);
      
      // Only fetch thumbnails that aren't already cached
      const hashesToFetch = fileHashes.filter(hash => !cacheStatus[hash]);
      
      if (hashesToFetch.length === 0) {
        console.log('ThumbnailCacheService: All thumbnails already cached');
        setProgress('thumbnailCache', { 
          progress: 100,
          operation: 'All thumbnails already in cache'
        });
        completeProgress('thumbnailCache');
        return;
      }
      
      console.log('ThumbnailCacheService: Fetching missing thumbnails:', hashesToFetch);
      setProgress('thumbnailCache', { 
        progress: 10,
        operation: `Fetching ${hashesToFetch.length} missing thumbnails`
      });
      
      // Process in batches of 3 to avoid overwhelming the server
      const batchSize = 3;
      const db = await this.initDB();
      const writeTx = db.transaction(this.storeName, 'readwrite');
      const store = writeTx.objectStore(this.storeName);
      
      for (let i = 0; i < hashesToFetch.length; i += batchSize) {
        const batch = hashesToFetch.slice(i, i + batchSize);
        
        // Update progress
        const percentComplete = Math.min(90, 10 + Math.round((i / hashesToFetch.length) * 80));
        setProgress('thumbnailCache', {
          progress: percentComplete,
          operation: `Fetching thumbnails (${i + 1}-${Math.min(i + batchSize, hashesToFetch.length)}/${hashesToFetch.length})`
        });
        
        // Process batch in parallel
        await Promise.all(batch.map(async (hash) => {
          try {
            // Use axios instead of fetch to include auth headers
            const response = await axios.get(`/project/${hash}/thumb`, {
              responseType: 'blob'
            });
            
            // Create cache item with metadata
            const cacheItem = {
              data: response.data,
              timestamp: Date.now(),
              lastAccessed: Date.now(),
              size: response.data.size
            };
            
            // Store in IndexedDB
            await store.put(cacheItem, hash);
            console.log('ThumbnailCacheService: Successfully cached:', hash);
          } catch (error) {
            console.warn('ThumbnailCacheService: Failed to fetch thumbnail:', hash, error);
          }
        }));
        
        // Small delay between batches to prevent overwhelming the server
        if (i + batchSize < hashesToFetch.length) {
          await new Promise(resolve => setTimeout(resolve, 200));
        }
      }
      
      await writeTx.done;
      
      // Mark as complete
      setProgress('thumbnailCache', {
        progress: 100,
        operation: `Successfully cached ${hashesToFetch.length} thumbnails`
      });
      completeProgress('thumbnailCache');
      
    } catch (error) {
      console.error('ThumbnailCacheService: Error prefetching thumbnails:', error);
      
      // Set error in progress tracking
      const { setProgressError } = getStoreActions();
      setProgressError('thumbnailCache', error.message || 'Failed to prefetch thumbnails');
    }
  }

  static async clearCache() {
    try {
      // Get progress tracking functions
      const { startProgress, completeProgress, setProgressError } = getStoreActions();
      
      // Start progress tracking
      startProgress('thumbnailCache', 'Clearing thumbnail cache');
      
      console.log('ThumbnailCacheService: Clearing cache');
      const db = await this.initDB();
      await db.clear(this.storeName);
      
      // Complete the operation
      completeProgress('thumbnailCache');
      
    } catch (error) {
      console.error('ThumbnailCacheService: Failed to clear cache:', error);
      
      // Set error in progress tracking
      const { setProgressError } = getStoreActions();
      setProgressError('thumbnailCache', error.message || 'Failed to clear thumbnail cache');
      
      throw error;
    }
  }
  
  // Get cache stats
  static async getCacheStats() {
    const db = await this.initDB();
    const tx = db.transaction(this.storeName, 'readonly');
    const store = tx.objectStore(this.storeName);
    const allKeys = await store.getAllKeys();
    
    let totalSize = 0;
    let oldestTimestamp = Date.now();
    let newestTimestamp = 0;
    let itemCount = 0;
    
    for (const key of allKeys) {
      const cacheItem = await store.get(key);
      
      // Handle old format (just blob)
      if (cacheItem instanceof Blob) {
        totalSize += cacheItem.size;
        itemCount++;
        continue;
      }
      
      // Handle new format with metadata
      if (cacheItem && cacheItem.data) {
        totalSize += cacheItem.size || 0;
        itemCount++;
        
        if (cacheItem.timestamp < oldestTimestamp) {
          oldestTimestamp = cacheItem.timestamp;
        }
        
        if (cacheItem.timestamp > newestTimestamp) {
          newestTimestamp = cacheItem.timestamp;
        }
      }
    }
    
    const stats = {
      itemCount,
      totalSize,
      totalSizeMB: (totalSize / (1024 * 1024)).toFixed(2),
      oldestItem: oldestTimestamp === Date.now() ? null : new Date(oldestTimestamp),
      newestItem: newestTimestamp === 0 ? null : new Date(newestTimestamp)
    };
    
    console.log('ThumbnailCacheService: Cache stats:', stats);
    await tx.done;
    return stats;
  }
}

// Export the service
export default ThumbnailCacheService; 