import React, { useEffect, forwardRef } from 'react';
import { Button } from "@nextui-org/react";
import { BiMoon as MoonIcon, BiSun as SunIcon } from "react-icons/bi";
import { useTheme as useNextTheme } from 'next-themes'

export const ThemeToggle = forwardRef((props, ref) => {
  const { setTheme, theme } = useNextTheme();

  useEffect(() => {
    document.documentElement.classList.toggle('dark', theme === 'dark');
  }, [theme]);

  const toggleTheme = () => {
    setTheme(theme === 'light' ? 'dark' : 'light');
  }

  return (
    <Button 
      ref={ref}
      onPress={toggleTheme} 
      className="theme-toggle text-2xl !bg-light-bg dark:!bg-dark-bg hover:!bg-slate-100 dark:hover:!bg-slate-700" 
      aria-label="Toggle Theme" 
      title={`Toggle ${theme === 'light' ? 'Dark' : 'Light'} Mode`}
      isIconOnly
    >
      {theme === 'light' ? <MoonIcon /> : <SunIcon />}
    </Button>
  );
});
