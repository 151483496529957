import React, { useState, useEffect } from 'react';
import { 
  Modal, 
  ModalContent, 
  ModalHeader, 
  ModalBody, 
  ModalFooter,
  useDisclosure,
  Button,
  Progress,
  Card,
  CardBody,
  CardHeader,
  Divider,
} from "@nextui-org/react";
import { toast } from 'react-hot-toast';
import VideoCacheService from '../../services/VideoCacheService';
import ThumbnailCacheService from '../../services/ThumbnailCacheService';
import ProgressBar from '../ProgressBar';
import useStore from '../../store';

const CacheSettings = () => {
  const { isOpen, onOpen, onOpenChange, onClose } = useDisclosure();
  const [isLoading, setIsLoading] = useState(false);
  const [thumbnailStats, setThumbnailStats] = useState(null);
  const [videoStats, setVideoStats] = useState(null);
  const [totalUsage, setTotalUsage] = useState(null);
  const [storageEstimate, setStorageEstimate] = useState({ quota: 0, usage: 0 });
  
  // Get progress data from store
  const videoCacheProgress = useStore(state => state.progressData.videoCache);
  const thumbnailCacheProgress = useStore(state => state.progressData.thumbnailCache);

  // Load cache statistics
  const loadCacheStats = async () => {
    setIsLoading(true);
    try {
      // Load thumbnail stats
      const thumbStats = await ThumbnailCacheService.getCacheStats();
      setThumbnailStats(thumbStats);
      
      // Load video stats
      const vidStats = await VideoCacheService.getCacheSize();
      setVideoStats(vidStats);
      
      // Calculate total usage
      const total = thumbStats.totalSize + vidStats.totalSize;
      setTotalUsage({
        totalSize: total,
        totalSizeMB: (total / (1024 * 1024)).toFixed(2),
        formatted: VideoCacheService.formatBytes(total)
      });
      
      // Get storage estimate
      if (navigator.storage && navigator.storage.estimate) {
        const estimate = await navigator.storage.estimate();
        setStorageEstimate(estimate);
      }
    } catch (error) {
      console.error('Error loading cache stats:', error);
      toast.error('Failed to load cache statistics');
    } finally {
      setIsLoading(false);
    }
  };

  // Clear thumbnail cache
  const clearThumbnailCache = async () => {
    if (!confirm('Are you sure you want to clear the thumbnail cache? You will need to reload thumbnails when viewing projects.')) {
      return;
    }
    
    try {
      await ThumbnailCacheService.clearCache();
      toast.success('Thumbnail cache cleared');
      await loadCacheStats();
    } catch (error) {
      console.error('Error clearing thumbnail cache:', error);
      toast.error('Failed to clear thumbnail cache');
    }
  };

  // Clear video cache
  const clearVideoCache = async () => {
    if (!confirm('Are you sure you want to clear the video cache? You will need to redownload videos when opening projects.')) {
      return;
    }
    
    try {
      await VideoCacheService.clearCache();
      toast.success('Video cache cleared');
      await loadCacheStats();
    } catch (error) {
      console.error('Error clearing video cache:', error);
      toast.error('Failed to clear video cache');
    }
  };

  // Clear all cache
  const clearAllCache = async () => {
    if (!confirm('Are you sure you want to clear ALL cached data? You will need to redownload everything.')) {
      return;
    }
    
    try {
      await Promise.all([
        ThumbnailCacheService.clearCache(),
        VideoCacheService.clearCache()
      ]);
      toast.success('All cache cleared');
      await loadCacheStats();
    } catch (error) {
      console.error('Error clearing all cache:', error);
      toast.error('Failed to clear cache');
    }
  };

  // Clean expired items
  const cleanExpiredItems = async () => {
    try {
      await Promise.all([
        ThumbnailCacheService.cleanExpiredItems(),
        VideoCacheService.cleanExpiredItems()
      ]);
      
      await loadCacheStats();
    } catch (error) {
      console.error('Error cleaning expired items:', error);
      toast.error('Failed to clean expired items');
    }
  };

  // Load stats when modal opens
  useEffect(() => {
    if (isOpen) {
      loadCacheStats();
    }
  }, [isOpen]);

  // Reload stats when any cache operations complete
  useEffect(() => {
    const shouldReload = 
      (videoCacheProgress && videoCacheProgress.status === 'complete') ||
      (thumbnailCacheProgress && thumbnailCacheProgress.status === 'complete');
      
    if (shouldReload && isOpen) {
      loadCacheStats();
    }
  }, [videoCacheProgress, thumbnailCacheProgress, isOpen]);

  return (
    <>
      <Button onClick={onOpen} color="default" variant="flat" size="sm">
        Manage Cache
      </Button>
      
      <Modal isOpen={isOpen} onOpenChange={onOpenChange} size="lg">
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="text-xl">Cache Settings</ModalHeader>
              
              <ModalBody>
                {/* Show progress bars for active operations */}
                <div className="space-y-4 mb-4">
                  <ProgressBar operation="videoCache" className="w-full" />
                  <ProgressBar operation="thumbnailCache" className="w-full" />
                </div>
                
                {isLoading && !videoCacheProgress.isActive && !thumbnailCacheProgress.isActive ? (
                  <div className="flex flex-col items-center justify-center py-10">
                    <Progress
                      size="sm"
                      isIndeterminate
                      aria-label="Loading..."
                      className="max-w-md"
                    />
                    <p className="mt-4">Loading cache statistics...</p>
                  </div>
                ) : (
                  <>
                    {/* Storage usage */}
                    {storageEstimate.quota > 0 && (
                      <div className="mb-4">
                        <h3 className="text-md font-semibold mb-2">Storage Usage</h3>
                        <Progress 
                          value={(storageEstimate.usage / storageEstimate.quota) * 100} 
                          color={
                            (storageEstimate.usage / storageEstimate.quota) > 0.9
                              ? "danger"
                              : (storageEstimate.usage / storageEstimate.quota) > 0.7
                              ? "warning"
                              : "primary"
                          }
                          showValueLabel
                          className="mb-2"
                        />
                        <p className="text-sm text-default-500">
                          {VideoCacheService.formatBytes(storageEstimate.usage)} of {VideoCacheService.formatBytes(storageEstimate.quota)} used
                        </p>
                      </div>
                    )}
                    
                    {/* Total usage */}
                    {totalUsage && (
                      <Card className="mb-4">
                        <CardHeader className="text-md font-semibold">SpookyCutter Cache Usage</CardHeader>
                        <Divider />
                        <CardBody>
                          <div className="grid grid-cols-2 gap-4">
                            <div>
                              <p className="text-sm text-default-500">Total Size</p>
                              <p className="text-lg font-semibold">{totalUsage.formatted}</p>
                            </div>
                            <div>
                              <p className="text-sm text-default-500">Items</p>
                              <p className="text-lg font-semibold">
                                {(thumbnailStats?.itemCount || 0) + (videoStats?.videoCount || 0)}
                              </p>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    )}
                    
                    {/* Video cache stats */}
                    {videoStats && (
                      <Card className="mb-4">
                        <CardHeader className="text-md font-semibold">Video Cache</CardHeader>
                        <Divider />
                        <CardBody>
                          <div className="grid grid-cols-2 gap-4">
                            <div>
                              <p className="text-sm text-default-500">Videos Cached</p>
                              <p className="text-lg font-semibold">{videoStats.videoCount}</p>
                            </div>
                            <div>
                              <p className="text-sm text-default-500">Size</p>
                              <p className="text-lg font-semibold">{videoStats.formatted}</p>
                            </div>
                            {videoStats.oldestVideo && (
                              <div>
                                <p className="text-sm text-default-500">Oldest Video</p>
                                <p className="text-md">
                                  {videoStats.oldestVideo.toLocaleDateString()}
                                </p>
                              </div>
                            )}
                            {videoStats.newestVideo && (
                              <div>
                                <p className="text-sm text-default-500">Newest Video</p>
                                <p className="text-md">
                                  {videoStats.newestVideo.toLocaleDateString()}
                                </p>
                              </div>
                            )}
                          </div>
                          <Button 
                            color="danger" 
                            variant="flat" 
                            size="sm" 
                            className="mt-4"
                            onClick={clearVideoCache}
                            isDisabled={isLoading || videoStats.videoCount === 0 || videoCacheProgress.isActive}
                          >
                            Clear Video Cache
                          </Button>
                        </CardBody>
                      </Card>
                    )}
                    
                    {/* Thumbnail cache stats */}
                    {thumbnailStats && (
                      <Card className="mb-4">
                        <CardHeader className="text-md font-semibold">Thumbnail Cache</CardHeader>
                        <Divider />
                        <CardBody>
                          <div className="grid grid-cols-2 gap-4">
                            <div>
                              <p className="text-sm text-default-500">Thumbnails Cached</p>
                              <p className="text-lg font-semibold">{thumbnailStats.itemCount}</p>
                            </div>
                            <div>
                              <p className="text-sm text-default-500">Size</p>
                              <p className="text-lg font-semibold">{thumbnailStats.totalSizeMB} MB</p>
                            </div>
                            {thumbnailStats.oldestItem && (
                              <div>
                                <p className="text-sm text-default-500">Oldest Thumbnail</p>
                                <p className="text-md">
                                  {thumbnailStats.oldestItem.toLocaleDateString()}
                                </p>
                              </div>
                            )}
                            {thumbnailStats.newestItem && (
                              <div>
                                <p className="text-sm text-default-500">Newest Thumbnail</p>
                                <p className="text-md">
                                  {thumbnailStats.newestItem.toLocaleDateString()}
                                </p>
                              </div>
                            )}
                          </div>
                          <Button 
                            color="danger" 
                            variant="flat" 
                            size="sm" 
                            className="mt-4"
                            onClick={clearThumbnailCache}
                            isDisabled={isLoading || thumbnailStats.itemCount === 0 || thumbnailCacheProgress.isActive}
                          >
                            Clear Thumbnail Cache
                          </Button>
                        </CardBody>
                      </Card>
                    )}
                    
                    {/* Expiration settings */}
                    <Card className="mb-4">
                      <CardHeader className="text-md font-semibold">Cache Expiration</CardHeader>
                      <Divider />
                      <CardBody>
                        <p className="mb-2 text-sm">
                          Video cache expires after {VideoCacheService.expirationTime / (24 * 60 * 60 * 1000)} days of disuse.
                          <br />
                          Thumbnail cache expires after {ThumbnailCacheService.expirationTime / (24 * 60 * 60 * 1000)} days.
                        </p>
                        <Button 
                          color="primary" 
                          variant="flat" 
                          size="sm" 
                          onClick={cleanExpiredItems}
                          isDisabled={videoCacheProgress.isActive || thumbnailCacheProgress.isActive}
                        >
                          Clean Expired Items Now
                        </Button>
                      </CardBody>
                    </Card>
                  </>
                )}
              </ModalBody>
              
              <ModalFooter>
                <Button 
                  color="danger" 
                  variant="flat" 
                  isDisabled={
                    isLoading || 
                    !totalUsage || 
                    totalUsage.totalSize === 0 || 
                    videoCacheProgress.isActive || 
                    thumbnailCacheProgress.isActive
                  }
                  onClick={clearAllCache}
                >
                  Clear All Cache
                </Button>
                <Button color="primary" onPress={onClose}>
                  Close
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
};

export default CacheSettings; 