import React, { useEffect, useState } from 'react';
import { Card, CardBody, Select, SelectItem, Button } from "@nextui-org/react";
import useStore from '../../store';
import TranslationService from '../../services/TranslationService';
import axios from '../../utils/axios';  // Use our configured axios instance

export function TranslationSettings({ handleTranscript }) {
  const {
    currentLanguage,
    setCurrentLanguage,
    availableLanguages = [],
    sourceLanguage,
    videoSHA,
    setTranscriptBlocks,
    transcriptBlocks,
    setAvailableLanguages
  } = useStore();

  const [isTranslating, setIsTranslating] = useState(false);

  // Add validation for current language
  const validateCurrentLanguage = () => {
    const validLanguages = [sourceLanguage, ...availableLanguages.map(lang => lang.code)];
    if (currentLanguage && !validLanguages.includes(currentLanguage)) {
      console.log('[TRANSLATE] Resetting invalid current language:', {
        current: currentLanguage,
        valid: validLanguages
      });
      setCurrentLanguage(sourceLanguage);
    }
  };

  // Check current language whenever available languages change
  useEffect(() => {
    validateCurrentLanguage();
  }, [availableLanguages, sourceLanguage]);

  // Scan for available languages on mount
  useEffect(() => {
    const scanForLanguages = () => {
      const languagesSet = new Set(['english']); // Always include source language
      
      transcriptBlocks.forEach(block => {
        block.words.forEach(word => {
          if (word.translations) {
            Object.keys(word.translations).forEach(lang => languagesSet.add(lang));
          }
        });
      });

      const availableLangs = Array.from(languagesSet)
        .filter(lang => lang !== 'english') // Remove English as it's the source
        .map(code => ({
          code,
          dateCreated: new Date().toISOString()
        }));

      if (availableLangs.length > 0) {
        console.log('Found languages from transcript scan:', availableLangs);
        setAvailableLanguages(availableLangs);
      }
    };

    scanForLanguages();
  }, [transcriptBlocks, setAvailableLanguages]);

  const handleLanguageChange = (newLanguage) => {
    setCurrentLanguage(newLanguage);
    console.log('Language changed to:', newLanguage);
  };

  const languageMap = {
    'en': 'english',
    'en-force': 'english (force translation)',
    'es': 'spanish',
    'fr': 'french',
    'ja': 'japanese',
    'it': 'italian',
    'ru': 'russian',
    'pl': 'polish',
    'ko': 'korean',
  };

  // Function to get display name for a language code
  const getDisplayName = (code) => {
    return languageMap[code] || code;
  };

  const handleTranslationRequest = async (targetLanguage) => {
    if (!sourceLanguage) {
      console.error('[TRANSLATE] No source language set');
      return;
    }

    try {
      setIsTranslating(true);
      console.log('[TRANSLATE] Requesting translation:', { 
        targetLanguage, 
        sourceLanguage,
        videoSHA 
      });
      
      const result = await TranslationService.requestTranslation(
        videoSHA,
        targetLanguage,
        sourceLanguage
      );

      if (result.availableLanguages) {
        setAvailableLanguages(result.availableLanguages);
      }

      // Use our configured axios instance
      const updatedTranscript = await axios.get('/transcript/' + videoSHA);
      console.log('[TRANSLATE] Got updated transcript:', updatedTranscript.data);

      // Process the updated transcript data
      if (updatedTranscript.data && updatedTranscript.data.transcript) {
        handleTranscript(updatedTranscript.data.transcript);
        
        // Set the current language to the newly translated language
        setCurrentLanguage(targetLanguage);
      }

    } catch (error) {
      console.error('[TRANSLATE] Component error:', error);
    } finally {
      setIsTranslating(false);
    }
  };

  return (
    <Card>
      <CardBody>
        <div className="flex flex-col gap-4">
          <div>
            <Select
              label="Display Language"
              selectedKeys={currentLanguage ? new Set([currentLanguage]) : new Set()}
              onChange={(e) => handleLanguageChange(e.target.value)}
              className="max-w-xs"
            >
              {/* Source language option */}
              {sourceLanguage && (
                <SelectItem 
                  key={sourceLanguage}
                  value={sourceLanguage}
                  textValue={getDisplayName(sourceLanguage)}
                >
                  {getDisplayName(sourceLanguage)} (Original)
                </SelectItem>
              )}
              
              {/* Available translations */}
              {availableLanguages
                .filter(lang => lang.code !== sourceLanguage)
                .map((lang) => (
                  <SelectItem 
                    key={lang.code}
                    value={lang.code}
                    textValue={getDisplayName(lang.code)}
                  >
                    {getDisplayName(lang.code)} ({new Date(lang.dateCreated).toLocaleDateString()})
                  </SelectItem>
                ))}
            </Select>
          </div>

          <div>
            <p className="text-small text-default-500 mb-2">Available Translations</p>
            <p className="text-small">
              <span className="italic">
                {availableLanguages.length > 0 
                  ? availableLanguages.map(lang => lang.code).join(', ')
                  : 'None yet - request a translation below'}
              </span>
            </p>
          </div>

          <div className="bg-pink-50 dark:bg-pink-900/20 p-4 rounded-lg">
            <p className="text-small text-default-500 mb-2">Request Translation</p>
            <p className="text-xs text-default-500 italic mb-2">Costs a few pennies</p>
            <div className="flex gap-2">
              <Select
                label="Target Language"
                className="max-w-xs"
                placeholder="Select target language"
                onChange={(e) => handleTranslationRequest(e.target.value)}
              >
                {Object.entries(languageMap).map(([code, name]) => (
                  <SelectItem 
                    key={code} 
                    value={code}
                    textValue={name}
                  >
                    {name}
                  </SelectItem>
                ))}
              </Select>
            </div>
          </div>
        </div>
      </CardBody>
    </Card>
  );
}
