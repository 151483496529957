import axios from 'axios';
import useStore from '../store';

const instance = axios.create({
  baseURL: '/api',  // Use relative path when using Vite proxy
  headers: {
    'Content-Type': 'application/json'
  },
  withCredentials: true
});

// Add request interceptor for auth token
instance.interceptors.request.use(
  (config) => {
    console.log('Making request to:', config.url);
    const token = useStore.getState().token;
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Add response interceptor for auth errors and debugging
instance.interceptors.response.use(
  (response) => response,
  async (error) => {
    console.error('API Error:', {
      status: error.response?.status,
      data: error.response?.data,
      config: error.config
    });

    // Only logout on 401 if it's not a login/signup attempt
    if (error.response?.status === 401 && 
        !error.config.url.includes('/auth/login') && 
        !error.config.url.includes('/auth/signup')) {
      console.log('Unauthorized request - logging out');
      useStore.getState().logout();
    }

    return Promise.reject(error);
  }
);

export default instance;
