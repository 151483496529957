import React, { useState, useEffect } from 'react';
import { ProjectBrowser } from '../../components';
import { useProjects } from '../../hooks';
import { Input } from '@nextui-org/react';
import { FaSearch } from 'react-icons/fa';

export const RetrieveFlow = () => {
  const { listAllProjectsGroupedBySHA, handleLoadProjectRemote } = useProjects();
  const [projects, setProjects] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const projects = await listAllProjectsGroupedBySHA();
        setProjects(projects);
      } catch (error) {
        console.error('Error fetching projects:', error);
      }
    };

    fetchProjects();
  }, [listAllProjectsGroupedBySHA]);

  // Filter projects based on search query
  const filteredProjects = searchQuery.trim() 
    ? projects.data?.filter(project => 
        project.projectName?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        project.filename?.toLowerCase().includes(searchQuery.toLowerCase())
      ) 
    : projects.data;

  return (
    <div className="h-full overflow-hidden center-section grid grid-cols-12 grid-rows-[auto,1fr] col-span-12 bg-default-50">
      {/* Search Input */}
      <div className="col-span-12 px-4 pt-4">
        <Input
          classNames={{
            base: "max-w-md mx-auto",
            inputWrapper: "border-1 border-default-200 bg-default-100/50 hover:bg-default-200/40 focus-within:!bg-default-100",
          }}
          placeholder="Search projects by name..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          startContent={<FaSearch className="text-default-400" />}
          clearable
          onClear={() => setSearchQuery('')}
        />
      </div>
      
      {/* Main Content */}
      <div className="col-span-12 flex-1 overflow-auto">
        <div className="h-full max-w-4xl mx-auto px-4 py-8">
          <ProjectBrowser
            onLoadProject={handleLoadProjectRemote}
            onListProjects={listAllProjectsGroupedBySHA}
            showGroupedByVideo={true}
            variant="expanded"
            hideInputs={true}
            searchFilter={searchQuery}
          />
        </div>
      </div>
    </div>
  );
};

export default RetrieveFlow;