import axios from '../../utils/axios';
import useStore from '../../store';

// Helper to get store functions without needing a component
const getStoreActions = () => {
  const { startProgress, setProgress, completeProgress, setProgressError } = useStore.getState();
  return { startProgress, setProgress, completeProgress, setProgressError };
};

/**
 * Gets audio/video duration from a file
 * @param {File} file - The file to get duration for
 * @returns {Promise<number>} - Duration in seconds
 */
const getFileDuration = (file) => {
  return new Promise((resolve, reject) => {
    // Check if it's an audio or video file
    if (!file.type.startsWith('audio/') && !file.type.startsWith('video/')) {
      resolve(0); // Not a media file, can't determine duration
      return;
    }

    // Create a URL for the file
    const url = URL.createObjectURL(file);
    
    // Create appropriate element based on file type
    const element = file.type.startsWith('audio/') ? new Audio() : document.createElement('video');
    
    // Set up event handlers
    element.addEventListener('loadedmetadata', () => {
      // Clean up
      URL.revokeObjectURL(url);
      resolve(element.duration);
    });
    
    element.addEventListener('error', () => {
      // Clean up
      URL.revokeObjectURL(url);
      reject(new Error('Could not load media metadata'));
    });
    
    // Load the file
    element.src = url;
  });
};

/**
 * Estimates the time it will take to transcribe an audio file
 * @param {number} durationInSeconds - Audio duration in seconds
 * @returns {Promise<number>} - Estimated transcription time in seconds
 */
const getEstimatedTranscriptionTime = async (durationInSeconds) => {
  if (!durationInSeconds) return 10; // Default to 10 seconds if no duration
  
  // Use the reliable fallback formula directly
  // Fallback uses a reasonable estimate of ~10% of audio duration with a minimum of 5 seconds
  const estimatedTime = Math.max(durationInSeconds * 0.1, 5);
  console.log('Using direct fallback estimate for transcription time:', estimatedTime, 'seconds');
  return estimatedTime;
};

/**
 * Simulates transcription progress based on estimated transcription time
 * @param {number} estimatedTime - Estimated time in seconds
 * @param {function} updateCallback - Callback to update progress
 * @returns {function} - Function to stop the simulation
 */
const simulateTranscriptionProgress = (estimatedTime, updateCallback) => {
  if (!estimatedTime || estimatedTime <= 0) return () => {};
  
  // Start from 0% of transcription (95% of overall upload from previous step)
  let progress = 0;
  const intervalTime = 500; // Update every 500ms
  const steps = Math.max(estimatedTime * 1000 / intervalTime, 10); // Ensure at least 10 steps
  const incrementPerStep = 100 / steps;
  
  console.log(`Simulating transcription progress over ${estimatedTime}s with ${steps} steps`);
  
  const interval = setInterval(() => {
    progress += incrementPerStep;
    
    if (progress >= 99) {
      progress = 99; // Cap at 99% until we get server confirmation
      clearInterval(interval);
    }
    
    // Calculate overall progress (95-100% range reserved for transcription)
    const overallProgress = 95 + (progress * 0.05);
    updateCallback(Math.min(Math.round(overallProgress), 99), progress);
  }, intervalTime);
  
  // Return a function to stop the simulation
  return () => clearInterval(interval);
};

/**
 * Checks if a file exists on the server.
 * @param {string} apiUrl - The base API URL
 * @param {string} fileHash - The hash of the file to check
 */
const checkFileExists = async (apiUrl, fileHash) => {
  console.log('FileUploadService: checkFileExists:', { apiUrl, fileHash });
  try {
    // Get token from localStorage or your auth store
    const token = localStorage.getItem('token');
    console.log('Using auth token:', token ? 'Token exists' : 'No token found');

    const response = await axios.get(`/check/${fileHash}`, {
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    });
    
    console.log('FileUploadService: checkFileExists: Response:', response.data);
    return response.data.exists;
  } catch (error) {
    console.warn("FileUploadService: checkFileExists: Error:", {
      status: error.response?.status,
      data: error.response?.data
    });
    throw error; // Let the caller handle the error
  }
};

/**
 * Fetches a transcript for a file.
 * @param {string} apiUrl - The base API URL
 * @param {string} fileHash - The hash of the file
 */
const fetchTranscript = async (apiUrl, fileHash) => {
  console.log('FileUploadService: fetchTranscript:', { apiUrl, fileHash });
  try {
    const token = localStorage.getItem('token');
    const response = await axios.get(`/transcript/${fileHash}`, {
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    });
    
    console.log('FileUploadService: fetchTranscript: Response:', response.data);
    
    if (!response.data.transcript) {
      throw new Error('Transcript data not found in the response');
    }
    
    return response.data.transcript;
  } catch (error) {
    console.error('FileUploadService: fetchTranscript: Error:', {
      status: error.response?.status,
      data: error.response?.data
    });
    throw error;
  }
};

const CLOUDFLARE_SAFE_CHUNK_SIZE = 95 * 1024 * 1024; // 95MB to be safe
const REGULAR_UPLOAD_LIMIT = 90 * 1024 * 1024; // 90MB threshold for regular uploads

/**
 * Uploads a file to the server.
 * @param {File} file - The file to upload
 * @param {string} apiUrl - The base API URL
 * @param {string} fileHash - The hash of the file
 */
const uploadFile = async (file, apiUrl, fileHash) => {
  console.log('FileUploadService: uploadFile:', { file, apiUrl, fileHash });
  
  // Get progress tracking functions
  const { startProgress, setProgress, completeProgress, setProgressError } = getStoreActions();
  
  // Start progress tracking
  startProgress('upload', `Uploading ${file.name}`);
  
  // Get file duration for more accurate progress estimation
  let fileDuration = 0;
  try {
    fileDuration = await getFileDuration(file);
    console.log('File duration:', fileDuration, 'seconds');
  } catch (error) {
    console.warn('Could not determine file duration:', error);
  }
  
  // Use chunked upload for large files
  if (file.size > REGULAR_UPLOAD_LIMIT) {
    return uploadLargeFile(file, fileHash, fileDuration);
  }

  // Regular upload for smaller files
  const formData = new FormData();
  formData.append('file', file);
  formData.append('hash', fileHash);

  try {
    const token = localStorage.getItem('token');
    
    // Create upload request with progress tracking
    const response = await axios.post('/upload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${token}`
      },
      maxBodyLength: Infinity,
      maxContentLength: Infinity,
      onUploadProgress: (progressEvent) => {
        if (progressEvent.total) {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setProgress('upload', { 
            progress: percentCompleted,
            operation: `Uploading ${file.name} (${percentCompleted}%)`
          });
        }
      }
    });

    console.log('Upload response:', response.data);
    
    // Get estimated transcription time
    const estimatedTranscriptionTime = await getEstimatedTranscriptionTime(fileDuration);
    
    // Start transcription progress simulation
    let stopSimulation;
    try {
      stopSimulation = simulateTranscriptionProgress(
        estimatedTranscriptionTime,
        (overallProgress, transcriptionProgress) => {
          setProgress('upload', { 
            progress: overallProgress,
            operation: `Transcribing video on server (${Math.round(transcriptionProgress)}%)...`
          });
        }
      );
      
      // Wait for transcription to complete or timeout
      const timeoutDuration = Math.max(estimatedTranscriptionTime * 2, 30) * 1000; // Double the estimate or at least 30 seconds
      
      const transcriptionResult = await Promise.race([
        // Simulate normal completion
        new Promise(resolve => {
          setTimeout(() => {
            stopSimulation();
            resolve({ success: true });
          }, estimatedTranscriptionTime * 1000);
        }),
        
        // Set a timeout to handle server issues
        new Promise((_, reject) => {
          setTimeout(() => {
            reject(new Error('Transcription is taking longer than expected. The server might be busy.'));
          }, timeoutDuration);
        })
      ]);
      
      if (transcriptionResult.success) {
        completeProgress('upload');
      }
    } catch (transcriptionError) {
      if (stopSimulation) stopSimulation();
      
      // Show a more specific error for transcription issues
      setProgressError('upload', 
        transcriptionError.message || 
        'Transcription failed. The server might be busy, please try again later.'
      );
      
      throw transcriptionError;
    }
    
    return response.data;
  } catch (error) {
    console.error('FileUploadService: uploadFile: Error:', {
      status: error.response?.status,
      data: error.response?.data,
      message: error.message
    });
    
    // Error message with more context
    const errorMessage = error.response?.status === 524 
      ? 'Gateway timeout error. The server is taking too long to process your file.'
      : error.message || 'Failed to upload file';
      
    setProgressError('upload', errorMessage);
    throw error;
  }
};

/**
 * Uploads a large file in chunks.
 * @param {File} file - The file to upload
 * @param {string} fileHash - The hash of the file
 * @param {number} fileDuration - Duration of the file in seconds (if media file)
 */
const uploadLargeFile = async (file, fileHash, fileDuration = 0) => {
  console.log('FileUploadService: uploadLargeFile:', { file, fileHash, fileDuration });
  
  // Get progress tracking functions
  const { startProgress, setProgress, completeProgress, setProgressError } = getStoreActions();
  
  // Start progress tracking if not already started
  startProgress('upload', `Preparing to upload ${file.name} in chunks`);
  
  const totalChunks = Math.ceil(file.size / CLOUDFLARE_SAFE_CHUNK_SIZE);
  
  let stopSimulation = null;
  
  try {
    const token = localStorage.getItem('token');
    
    // Upload each chunk
    for (let chunkIndex = 0; chunkIndex < totalChunks; chunkIndex++) {
      const start = chunkIndex * CLOUDFLARE_SAFE_CHUNK_SIZE;
      const end = Math.min(start + CLOUDFLARE_SAFE_CHUNK_SIZE, file.size);
      const chunk = file.slice(start, end);
      
      const formData = new FormData();
      formData.append('file', chunk);
      formData.append('hash', fileHash);
      formData.append('chunkIndex', chunkIndex);
      formData.append('totalChunks', totalChunks);
      formData.append('filename', file.name);
      
      // Update progress to show which chunk we're on
      setProgress('upload', { 
        // Calculate overall progress based on chunks
        progress: Math.round((chunkIndex / totalChunks) * 100),
        operation: `Uploading chunk ${chunkIndex + 1}/${totalChunks} of ${file.name}`
      });
      
      const response = await axios.post('/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${token}`,
          'X-Chunk-Upload': 'true'
        },
        maxBodyLength: Infinity,
        maxContentLength: Infinity,
        onUploadProgress: (progressEvent) => {
          if (progressEvent.total) {
            // Calculate the overall progress considering both chunk index and current chunk progress
            const chunkProgress = (progressEvent.loaded / progressEvent.total);
            const overallProgress = (
              ((chunkIndex + chunkProgress) / totalChunks) * 100
            );
            
            setProgress('upload', { 
              progress: Math.round(overallProgress),
              operation: `Uploading chunk ${chunkIndex + 1}/${totalChunks} of ${file.name}`
            });
          }
        }
      });

      console.log(`Chunk ${chunkIndex + 1}/${totalChunks} uploaded successfully`);
    }

    // Get estimated transcription time
    const estimatedTranscriptionTime = await getEstimatedTranscriptionTime(fileDuration);
    console.log('Estimated transcription time:', estimatedTranscriptionTime);
    
    // Start transcription progress simulation
    stopSimulation = simulateTranscriptionProgress(
      estimatedTranscriptionTime,
      (overallProgress, transcriptionProgress) => {
        setProgress('upload', { 
          progress: overallProgress,
          operation: `Transcribing video on server (${Math.round(transcriptionProgress)}%)...`
        });
      }
    );

    // Final request to complete the upload
    try {
      const finalResponse = await axios.post('/upload/complete', {
        hash: fileHash,
        totalChunks,
        filename: file.name
      }, {
        headers: {
          'Authorization': `Bearer ${token}`
        },
        timeout: Math.max(estimatedTranscriptionTime * 2, 60) * 1000 // Double the estimate or at least 60 seconds
      });
      
      // Stop simulation once we get a response
      if (stopSimulation) stopSimulation();
      
      // Mark progress as complete
      completeProgress('upload');
      
      console.log('Upload completed successfully:', finalResponse.data);
      return finalResponse.data;
    } catch (error) {
      // Stop simulation on error
      if (stopSimulation) stopSimulation();
      
      // Special handling for gateway timeout errors
      if (error.response?.status === 524) {
        const timeoutError = new Error(
          'Server timeout while transcribing. Your file was uploaded but transcription is still processing. ' +
          'Try refreshing in a few minutes to check if it completed.'
        );
        setProgressError('upload', timeoutError.message);
        throw timeoutError;
      }
      
      throw error;
    }
  } catch (error) {
    // Ensure simulation is stopped if it was started
    if (stopSimulation) stopSimulation();
    
    console.error('FileUploadService: uploadLargeFile: Error:', {
      status: error.response?.status,
      data: error.response?.data,
      message: error.message
    });
    
    // More descriptive error based on status code
    let errorMessage = 'Failed to upload file chunks';
    
    if (error.response) {
      switch (error.response.status) {
        case 401:
          errorMessage = 'Authentication error. Please log in again.';
          break;
        case 413:
          errorMessage = 'File is too large for the server to process.';
          break;
        case 503:
        case 504:
        case 524:
          errorMessage = 'Server is busy or unavailable. Please try again later.';
          break;
        default:
          errorMessage = error.message || errorMessage;
      }
    }
    
    setProgressError('upload', errorMessage);
    throw error;
  }
};

const FileUploadService = {
  checkFileExists,
  fetchTranscript,
  uploadFile,
  getFileDuration,
  getEstimatedTranscriptionTime
};

export default FileUploadService;
