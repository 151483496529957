import React from 'react';

export const FileUploadSection = ({ onFileUpload, isUploading, uploadError, compact, hide }) => {
  const [isDragging, setIsDragging] = React.useState(false);

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);

    const files = e.dataTransfer.files;
    if (files && files[0]) {
      const event = { target: { files: files } };
      onFileUpload(event);
    }
  };

  if (hide) return null;

  return (
    <div className="upload-section w-full">
      <div className="flex justify-center">
        <label
          htmlFor="media-upload"
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
          className={`
            w-full cursor-pointer flex flex-col items-center justify-center p-12
            border-2 border-dashed rounded-xl
            transition-all duration-200 ease-in-out
            ${isDragging 
              ? 'border-primary bg-primary/5 scale-102' 
              : 'border-default-300 hover:border-primary hover:bg-default-100'
            }
          `}
        >
          <div className="mb-4">
            <svg 
              xmlns="http://www.w3.org/2000/svg" 
              width="32" 
              height="32" 
              viewBox="0 0 24 24" 
              fill="none" 
              stroke="currentColor" 
              strokeWidth="2" 
              strokeLinecap="round" 
              strokeLinejoin="round"
              className={`
                transition-colors duration-200
                ${isDragging ? 'text-primary' : 'text-default-400'}
              `}
            >
              <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"/>
              <polyline points="17 8 12 3 7 8"/>
              <line x1="12" y1="3" x2="12" y2="15"/>
            </svg>
          </div>
          <span className={`
            text-base transition-colors duration-200
            ${isDragging ? 'text-primary' : 'text-default-600'}
          `}>
            {isUploading ? "Uploading..." : "Click or drop video here"}
          </span>
          <input
            id="media-upload"
            type="file"
            accept="video/mp4,video/quicktime"
            onChange={onFileUpload}
            className="hidden"
          />
        </label>
      </div>
      {uploadError && (
        <div className="text-danger mt-2 text-center">{uploadError}</div>
      )}
    </div>
  );
};
