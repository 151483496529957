import React from 'react';
import { Modal, ModalContent, Spinner } from '@nextui-org/react';

const VideoLoadingOverlay = ({ progress, operation = "downloading" }) => {
  // Only show if we're actually loading or downloading (progress < 100)
  if (progress >= 100) return null;
  
  return (
    <Modal 
      isOpen={true}
      hideCloseButton
      isDismissable={false}
      size="sm"
      classNames={{
        base: "bg-background/40 backdrop-blur-lg backdrop-saturate-150 border border-default-200 shadow-lg",
        body: "p-8"
      }}
    >
      <ModalContent>
        <div className="flex flex-col items-center">
          <h2 className="text-2xl font-bold mb-3 bg-gradient-to-r from-primary to-secondary bg-clip-text text-transparent">
            Loading Video
          </h2>
          <div className="mb-4">
            <Spinner 
              color="secondary"
              size="lg"
            />
          </div>
          <p className="text-default-600">
            Please wait while the video loads...
          </p>
        </div>
      </ModalContent>
    </Modal>
  );
};

export default VideoLoadingOverlay; 