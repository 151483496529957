// Progress tracking slice for the global store
// This tracks progress of various operations like uploads, downloads, and cache operations

const createProgressSlice = (set) => ({
  // Progress tracking
  progressData: {
    // Upload progress
    upload: {
      isActive: false,
      progress: 0,
      status: 'idle', // 'idle', 'active', 'complete', 'error'
      error: null,
      operation: '', // description of what's happening
    },
    // Video cache progress
    videoCache: {
      isActive: false,
      progress: 0,
      status: 'idle',
      error: null,
      operation: '',
    },
    // Thumbnail cache progress
    thumbnailCache: {
      isActive: false,
      progress: 0,
      status: 'idle',
      error: null,
      operation: '',
    },
  },

  // Set progress for a specific operation
  setProgress: (operation, data) => 
    set((state) => ({
      progressData: {
        ...state.progressData,
        [operation]: {
          ...state.progressData[operation],
          ...data,
        },
      },
    })),

  // Reset progress for a specific operation
  resetProgress: (operation) =>
    set((state) => ({
      progressData: {
        ...state.progressData,
        [operation]: {
          isActive: false,
          progress: 0,
          status: 'idle',
          error: null,
          operation: '',
        },
      },
    })),

  // Start tracking progress for an operation
  startProgress: (operation, description = '') =>
    set((state) => ({
      progressData: {
        ...state.progressData,
        [operation]: {
          isActive: true,
          progress: 0,
          status: 'active',
          error: null,
          operation: description,
        },
      },
    })),

  // Complete an operation
  completeProgress: (operation) =>
    set((state) => ({
      progressData: {
        ...state.progressData,
        [operation]: {
          ...state.progressData[operation],
          isActive: false,
          progress: 100,
          status: 'complete',
        },
      },
    })),

  // Set error for an operation
  setProgressError: (operation, error) =>
    set((state) => ({
      progressData: {
        ...state.progressData,
        [operation]: {
          ...state.progressData[operation],
          isActive: false,
          status: 'error',
          error,
        },
      },
    })),
});

export default createProgressSlice; 