import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useStore from '../../store';
import { useFileUpload } from '../../hooks';
import { FileUploadSection } from '../../components';
import ProgressBar from '../ProgressBar';
import { Card, CardBody } from '@nextui-org/react';

export const UploadFlow = () => {
  const navigate = useNavigate();
  const {
    isUploading,
    uploadError,
    handleFileUpload
  } = useFileUpload();

  const { videoSHA, projectFileName, resetState } = useStore();
  const uploadProgress = useStore((state) => state.progressData.upload);

  // Reset state when component mounts
  useEffect(() => {
    resetState();
  }, [resetState]);

  // Add effect to navigate when upload is complete
  useEffect(() => {
    if (!isUploading && videoSHA && projectFileName) {
      navigate(`/project/${videoSHA}/${projectFileName}`);
    }
  }, [isUploading, videoSHA, projectFileName, navigate]);

  return (
    <div className="h-full overflow-hidden center-section col-span-12">
      {!isUploading ? (
        <div className="h-full overflow-hidden w-full flex flex-col text-center justify-center items-center p-8">
          <Card className="max-w-2xl w-full bg-background/40 backdrop-blur-lg backdrop-saturate-150 border border-default-200 shadow-lg hover:shadow-xl transition-shadow">
            <CardBody className="p-12">
              <div className="flex flex-col items-center">
                <h2 className="text-4xl font-bold mb-8 bg-gradient-to-r from-primary via-secondary to-primary bg-clip-text text-transparent animate-gradient">
                  Let's start cutting
                </h2>
                <div className="w-full max-w-md">
                  <FileUploadSection
                    onFileUpload={handleFileUpload}
                    isUploading={isUploading}
                    uploadError={uploadError}
                  />
                </div>
              </div>
            </CardBody>
          </Card>
        </div>
      ) : (
        <div className="center-section col-span-12 flex flex-col text-center justify-center items-center p-8">
          <Card className="max-w-xl w-full bg-background/40 backdrop-blur-lg backdrop-saturate-150 border border-default-200 shadow-lg">
            <CardBody className="p-8">
              <div className="flex flex-col items-center">
                <h2 className="text-2xl font-bold mb-3 bg-gradient-to-r from-primary to-secondary bg-clip-text text-transparent">
                  Processing Your Video
                </h2>
                <p className="text-default-600 mb-8">
                  {uploadProgress.isActive 
                    ? uploadProgress.operation 
                    : "Just a moment while we get everything ready"}
                </p>

                {/* Show proper progress bar if we have progress data */}
                {uploadProgress.isActive ? (
                  <ProgressBar operation="upload" className="w-full" />
                ) : (
                  <div className="w-full h-1 bg-default-100 rounded-full overflow-hidden">
                    <div className="h-full bg-gradient-to-r from-primary to-secondary animate-loading-bar"></div>
                  </div>
                )}
              </div>
            </CardBody>
          </Card>
        </div>
      )}
    </div>
  );
};

export default UploadFlow;