import { useCallback, useEffect, useState } from 'react';
import useStore from '../../store';
import { useBlockSplit, useBlockHandlers, useWordNavigation } from '../../hooks';
import { useDragAndDrop } from '../../hooks/useDragAndDrop';

export function useKeyPress(handleBlockNavigation, handleVideoPlayPause, isPlaying, handleSeek) {
  const { 
    currentBlockIndex, 
    getSelectedWordsInCurrentBlock, 
    isolateSelectedWords, 
    clearSelection,
    getCurrentWordIndex,
    undo,
    redo,
    canUndo,
    canRedo,
    isDirty,
    saveProject,
    transcriptBlocks,
    selectedBlockIndex,
    setSelectedBlockIndex,
    setCurrentBlockIndex,
    rejectAllBlocksBefore,
    rejectAllBlocksAfter
  } = useStore();
  const { handleSplit, handleSplitAndReject, handleSplitAndRejectLeft, handleSplitAndRejectRight } = useBlockSplit(handleSeek);
  const { handleBlockRejection } = useBlockHandlers(handleSeek);
  const { handleWordNavigation } = useWordNavigation();
  const { handleDragEnd } = useDragAndDrop(transcriptBlocks, useStore.getState().setTranscriptBlocks);
  const [isEnterPressed, setIsEnterPressed] = useState(false);

  const handleBlockMove = useCallback((direction) => {
    const currentIndex = selectedBlockIndex !== null ? selectedBlockIndex : currentBlockIndex;
    const newIndex = currentIndex + direction;

    // Check if move is valid
    if (newIndex >= 0 && newIndex < transcriptBlocks.length) {
      handleDragEnd({
        source: { index: currentIndex },
        destination: { index: newIndex }
      });
      // Update selection to follow the moved block
      setSelectedBlockIndex(newIndex);
      setCurrentBlockIndex(newIndex);
    }
  }, [selectedBlockIndex, currentBlockIndex, transcriptBlocks.length, handleDragEnd, setSelectedBlockIndex, setCurrentBlockIndex]);

  const handleKeyPress = useCallback((event) => {
    // Handle CMD+S at the very start, before any other logic
    if ((event.metaKey || event.ctrlKey) && event.key.toLowerCase() === 's') {
      event.preventDefault();  // Prevent browser's save dialog
      event.stopPropagation();
      if (isDirty) {
        console.log('Opening save modal via CMD/CTRL + S');
        useStore.getState().setIsSaveModalOpen(true);
      }
      return;
    }

    console.log('useKeyPress handling:', {
      key: event.key,
      target: event.target.tagName,
      isSearchFocused: useStore.getState().isSearchInputFocused
    });

    if (event.target.tagName === 'INPUT' || event.target.tagName === 'TEXTAREA') {
      console.log('Preventing key handling - input focused');
      return;
    }

    console.log('useKeyPress handling key:', event.key, 'cmd/ctrl:', event.metaKey || event.ctrlKey);

    // Track Enter key state
    if (event.key === 'Enter') {
      setIsEnterPressed(true);
    }

    // Handle block movement when Enter is pressed
    if (isEnterPressed) {
      if (['ArrowUp', 'w', 'W'].includes(event.key)) {
        event.preventDefault();
        handleBlockMove(-1);
        return;
      }
      if (['ArrowDown', 's', 'S'].includes(event.key)) {
        event.preventDefault();
        handleBlockMove(1);
        return;
      }
    }

    // Handle block navigation for CMD/CTRL + arrows or W/S
    if ((event.metaKey || event.ctrlKey) && 
        (['ArrowUp', 'ArrowDown', 'w', 's'].includes(event.key.toLowerCase()))) {
      event.preventDefault();
      if (isPlaying) return;
      
      const direction = (event.key === 'ArrowUp' || event.key.toLowerCase() === 'w') ? -1 : 1;
      handleBlockNavigation(direction);
      return;
    }

    console.log('Key pressed:', event.key, 'Key code:', event.code);

    if (event.ctrlKey || event.metaKey) {
      switch (event.key.toLowerCase()) {
        case 'z':
          if (event.shiftKey && canRedo()) {
            event.preventDefault();
            redo();
          } else if (canUndo()) {
            event.preventDefault();
            undo();
          }
          break;
        case 'y':
          if (canRedo()) {
            event.preventDefault();
            redo();
          }
          break;
        case 'd':
          event.preventDefault();
          const blockToDuplicate = transcriptBlocks[currentBlockIndex];
          if (blockToDuplicate) {
            const duplicatedBlock = {
              ...blockToDuplicate,
              id: crypto.randomUUID(),
            };
            useStore.getState().addBlock(duplicatedBlock, currentBlockIndex + 1);
          }
          return;
        case 'r':
        case 'backspace':
        case 'delete':
          event.preventDefault();
          const blockToDelete = transcriptBlocks[currentBlockIndex];
          if (blockToDelete) {
            useStore.getState().removeBlock(currentBlockIndex);
            
            // Navigate to previous block if available, otherwise next
            if (currentBlockIndex > 0) {
              handleBlockNavigation(-1);
            } else if (transcriptBlocks.length > 1) {
              handleBlockNavigation(1);
            }
          }
          return;
      }
      return;
    }

    if (event.code === 'Space') {
      event.preventDefault();
      handleVideoPlayPause(!isPlaying);
      return;
    }

    if(isPlaying) {
      console.log('Only spacebar is allowed while video is playing');
      return;
    }

    console.log('Video is not playing');

    if (event.key === 'r' || event.key === 'Backspace' || event.key === 'Delete') {
      console.log('Reject action triggered');
      event.preventDefault(); // Prevent default backspace behavior
      if (getSelectedWordsInCurrentBlock()) {
        handleSplitAndReject();
        clearSelection();
        console.log('Selected words after rejection:', getSelectedWordsInCurrentBlock());
      } else {
        console.log('Rejecting current block');
        handleBlockRejection(currentBlockIndex);
      }
    } else if (event.key === 'e' || event.key === 'k') {
      handleSplit();
    } else if (event.key.toLowerCase() === 'j') {
      if (event.shiftKey) {
        event.preventDefault();
        const currentBlock = transcriptBlocks[currentBlockIndex];
        if (currentBlock) {
          rejectAllBlocksBefore(currentBlockIndex);
          
          // Keep the selection and seek logic
          useStore.getState().setSelectedBlockIndex(currentBlockIndex);
          useStore.getState().setCurrentBlockIndex(currentBlockIndex);
          handleSeek(currentBlock.start + 0.001);
        }
      } else {
        handleSplitAndRejectLeft();
      }
    } else if (event.key.toLowerCase() === 'l') {
      if (event.shiftKey) {
        event.preventDefault();
        const currentBlock = transcriptBlocks[currentBlockIndex];
        if (currentBlock) {
          rejectAllBlocksAfter(currentBlockIndex);
          
          // Keep the selection and seek logic
          useStore.getState().setSelectedBlockIndex(currentBlockIndex);
          useStore.getState().setCurrentBlockIndex(currentBlockIndex);
          handleSeek(currentBlock.start + 0.001);
        }
      } else {
        handleSplitAndRejectRight();
      }
    } else if (['a', 'd'].includes(event.key.toLowerCase()) || 
               event.key === 'ArrowLeft' || event.key === 'ArrowRight') {
      const direction = (event.key.toLowerCase() === 'a' || event.key === 'ArrowLeft') ? -1 : 1;
      handleWordNavigation(direction, event.shiftKey);
    } else if (['w', 's'].includes(event.key.toLowerCase())) {
      if (!event.ctrlKey && !event.metaKey) {
        const direction = event.key.toLowerCase() === 'w' ? -1 : 1;
        if (!event.shiftKey) {
          // For non-shift vertical navigation, treat it like arrow keys
          handleBlockNavigation(direction);
          clearSelection();
        } else {
          // For shift+vertical, use word navigation
          handleWordNavigation(direction, event.shiftKey);
        }
      }
    } else if (['ArrowUp', 'ArrowDown'].includes(event.key)) {
      const direction = event.key === 'ArrowUp' ? -1 : 1;
      handleBlockNavigation(direction);
      clearSelection();
    } else if (event.key === 'i' || event.key === 'I') {
      if (getSelectedWordsInCurrentBlock()) {
        console.log('Isolating selected words');
        isolateSelectedWords();
        clearSelection();
      } else {
        console.log('No words selected for isolation');
      }
    } else if (event.key.toLowerCase() === 't' && !event.ctrlKey && !event.metaKey) {
      event.preventDefault();
      useStore.getState().toggleContextualToolbar();
    } else if (event.key.toLowerCase() === 'f' && !event.ctrlKey && !event.metaKey) {
      event.preventDefault();
      useStore.getState().toggleFindBar();
      
      setTimeout(() => {
        const searchInput = document.querySelector('.find-bar input');
        if (searchInput) {
          searchInput.focus();
        }
      }, 0);
    }

    // Add ESC handler
    if (event.key === 'Escape') {
      const isFindBarVisible = useStore.getState().isFindBarVisible;
      if (isFindBarVisible) {
        useStore.getState().toggleFindBar();
      }
      return;
    }
  }, [
    handleBlockNavigation, 
    handleVideoPlayPause, 
    isPlaying, 
    handleSeek, 
    isolateSelectedWords, 
    clearSelection, 
    getSelectedWordsInCurrentBlock, 
    handleSplitAndReject, 
    handleBlockRejection, 
    currentBlockIndex, 
    handleSplit, 
    handleWordNavigation,
    getCurrentWordIndex,
    handleSplitAndRejectLeft,
    handleSplitAndRejectRight,
    undo,
    redo,
    canUndo,
    canRedo,
    isDirty,
    saveProject,
    transcriptBlocks,
    isEnterPressed,
    handleBlockMove,
    rejectAllBlocksBefore,
    rejectAllBlocksAfter
  ]);

  useEffect(() => {
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [handleKeyPress]);

  // Add keyup handler for Enter
  useEffect(() => {
    const handleKeyUp = (event) => {
      if (event.key === 'Enter') {
        setIsEnterPressed(false);
      }
    };

    window.addEventListener('keyup', handleKeyUp);
    return () => {
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, []);

  return null;
}

export default useKeyPress;
