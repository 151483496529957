import { useState, useCallback } from 'react';
import { API_ENDPOINTS } from '../../config';
import { ProjectStateService, ProjectValidationService } from '../../services';
import useStore from '../../store';
import FileUploadService from '../../services/FileUploadService';
import { toast } from 'react-hot-toast';
import VideoCacheService from '../../services/VideoCacheService';
import ThumbnailCacheService from '../../services/ThumbnailCacheService';

export function useProjects() {
  const { projectState, isDirty, lastSaved, setProjectState, setIsDirty, saveProject, loadProject, projectFileName, projectName } = useStore();
  const videoSHA = useStore(state => state.videoSHA);
  const [files, setFiles] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingProgress, setLoadingProgress] = useState(0);
  const {
    setTranscriptBlocks,
    setProjectName,
    setProjectFileName,
    setTranscriptData,
  } = useStore();

  const setVideoFile = useStore(state => state.setVideoFile);
  const [isVideoLoading, setIsVideoLoading] = useState(false);
  const [videoLoadingProgress, setVideoLoadingProgress] = useState(0);
  const [videoLoadingOperation, setVideoLoadingOperation] = useState("checking");

  const listAllProjectsGroupedBySHA = useCallback(async () => {
    console.log('useProjects :: listAllProjectsGroupedBySHA from backend:', `/project/listAll`);
    try {
      const projects = await ProjectStateService.listAllProjectsGroupedBySHA();
      return projects;
    } catch (error) {
      console.error('Error listing all projects:', error);
      throw error;
    }
  }, []);

  const fetchFiles = useCallback(async (filterBySHA = null) => {
    try {
      setIsLoading(true);
      setLoadingProgress(0);
      console.log('fetchFiles: Starting with filterBySHA:', filterBySHA);
      
      // Step 1: Get project list (10%)
      const response = await listAllProjectsGroupedBySHA();
      console.log('listAllProjectsGroupedBySHA response:', response);
      const projectGroups = response?.data || [];
      setLoadingProgress(10);

      // Prepare project list
      let projectsToFetch;
      if (filterBySHA) {
        const shaGroup = projectGroups.find(group => group.fileHash === filterBySHA);
        console.log('Found SHA group:', shaGroup);
        if (!shaGroup) {
          setFiles([]);
          return;
        }
        projectsToFetch = shaGroup.projects.map(project => ({
          fileHash: shaGroup.fileHash,
          filename: project.filename
        }));
      } else {
        projectsToFetch = projectGroups.flatMap(shaGroup => 
          shaGroup.projects.map(project => ({
            fileHash: shaGroup.fileHash,
            filename: project.filename
          }))
        );
      }

      // Step 2: Start thumbnail prefetch AFTER getting data - prioritize UI data first
      setLoadingProgress(20);

      // Step 3: Batch fetch project data (20% - 90%)
      const batchResponse = await ProjectStateService.batchGetProjects(projectsToFetch);
      if (!batchResponse.success) {
        throw new Error('Batch request failed');
      }
      setLoadingProgress(90);

      // Step 4: Process results
      const projectsWithData = batchResponse.data.map(result => {
        if (!result.success) {
          console.warn(`Could not load project data for ${result.filename}:`, result.error);
          return {
            filename: result.filename,
            videoSHA: result.fileHash,
            projectName: result.filename.replace('.json', ''),
            metadata: null
          };
        }

        return {
          filename: result.filename,
          videoSHA: result.fileHash,
          projectName: result.projectData.projectName || result.filename.replace('.json', ''),
          originalFilename: result.manifest?.originalFilename,
          metadata: result.projectData.metadata
        };
      });

      // Helper function to get timestamp from filename or metadata
      const getTimestamp = (project) => {
        if (project.metadata?.lastModified) {
          return new Date(project.metadata.lastModified).getTime();
        }
        const match = project.filename?.match(/(\d+)\.json$/);
        return match ? parseInt(match[1]) : 0;
      };

      // Sort by lastModified timestamp
      const sortedProjects = projectsWithData.sort((a, b) => {
        const aTime = getTimestamp(a);
        const bTime = getTimestamp(b);
        return bTime - aTime; // newest first
      });

      console.log('Final sorted projects:', sortedProjects);
      setFiles(sortedProjects);
      setLoadingProgress(100);
      
      // Now that UI is ready, prefetch thumbnails in the background
      // Only prefetch the first 10 thumbnails to avoid overwhelming the server
      const uniqueHashes = [...new Set(projectsWithData.map(p => p.videoSHA))];
      setTimeout(() => {
        ThumbnailCacheService.prefetchThumbnails(uniqueHashes.slice(0, 10));
      }, 300); // Small delay to let the UI render first
      
    } catch (err) {
      console.error('fetchFiles error:', err);
      setError(err.message);
      setFiles([]);
    } finally {
      setIsLoading(false);
    }
  }, [listAllProjectsGroupedBySHA]);

  const save = async (saveAsNew = false) => {
    try {
      console.log('Starting save process...');
      const state = {
        videoSHA: projectState.videoSHA || videoSHA,
        // Only set new projectFileName if saving as new
        projectFileName: saveAsNew 
          ? `${Date.now()}.json` 
          : (projectState.projectFileName || projectFileName),
        projectName: projectState.projectName || projectName || new Date().getTime().toString(),
        originalTranscript: projectState.originalTranscript || '',
        decisions: {
          blocks: projectState.transcriptBlocks || [],
          settings: {
            breakAtPunctuation: projectState.breakAtPunctuation || false,
            maxCharPerSection: projectState.maxCharPerSection || 500,
            breakOnSpeakerChange: projectState.breakOnSpeakerChange || false,
            rejectLongSilences: projectState.rejectLongSilences || false,
            longSilenceThreshold: projectState.longSilenceThreshold || 1.0,
          }
        },
        metadata: {
          // For new projects or if dateCreated doesn't exist, set it
          dateCreated: saveAsNew || !projectState?.metadata?.dateCreated 
            ? new Date().toISOString() 
            : projectState.metadata.dateCreated,
          // Always update lastModified
          lastModified: new Date().toISOString(),
          version: '1.0.0'
        }
      };

      console.log('About to save state with metadata:', {
        dateCreated: state.metadata.dateCreated,
        lastModified: state.metadata.lastModified
      });

      await ProjectStateService.save(state);
      await ProjectStateService.saveToBackend(
        projectState.videoSHA, 
        state.projectFileName, 
        state
      );
      console.log('Save completed');

      // Dispatch event after successful save to refresh project list
      window.dispatchEvent(new CustomEvent('projectSaved'));

      setIsDirty(false);
      setProjectState(state);
    } catch (error) {
      console.error('Error saving project:', error);
      throw error;
    }
  };

  const loadLocal = async () => {
    try {
      console.log('Starting load process...');
      const state = await ProjectStateService.load();
      console.log('Loaded state:', state);

    // Validate the loaded state
    const isValid = await ProjectValidationService.validate(state, videoSHA);
    if (!isValid) {
      throw new Error('Project state failed validation');
    }

      setProjectState(state);
      setIsDirty(false);
      loadProject(state);

      // the store transcriptBlocks should be hydrated now
      console.log('Load completed');
      return state;
    } catch (error) {
      console.error('Error loading project:', error);
      throw error;
    }
  }

  const loadRemoteProject = useCallback(async (project) => {
    try {
      console.log('Starting loadRemoteProject for:', project);
      setIsVideoLoading(true);
      setVideoLoadingProgress(0);
      setVideoLoadingOperation("checking");
      
      // Check if video is already cached
      const cachedVideo = await VideoCacheService.getCachedVideo(project.videoSHA);
      let videoConfig;

      if (cachedVideo) {
        console.log('Using cached video for SHA:', project.videoSHA);
        videoConfig = {
          url: URL.createObjectURL(cachedVideo),
          isCached: true
        };
        setVideoLoadingOperation("cached");
        setVideoLoadingProgress(100);
      } else {
        console.log('Video not cached, fetching from server...');
        // Ensure we keep the cache_miss state for a moment before changing to downloading
        setVideoLoadingOperation("cache_miss");
        
        // Add a short delay to make sure the user sees "Video not in cache" message
        await new Promise(resolve => setTimeout(resolve, 500));
        
        // Now change to downloading state
        setVideoLoadingOperation("downloading");
        videoConfig = {
          url: `/api/project/${project.videoSHA}/video`
        };
        
        // Stream and cache the video with progress
        const response = await fetch(videoConfig.url);
        const reader = response.body.getReader();
        const contentLength = +response.headers.get('Content-Length');
        
        let receivedLength = 0;
        const chunks = [];

        while(true) {
          const {done, value} = await reader.read();
          
          if (done) break;
          
          chunks.push(value);
          receivedLength += value.length;
          
          const progress = (receivedLength / contentLength) * 100;
          setVideoLoadingProgress(Math.round(progress));
        }
        
        // Now processing the video
        setVideoLoadingOperation("processing");
        
        // Create a blob from the chunks and cache it
        const videoBlob = new Blob(chunks);
        
        // Cache the video in the background
        VideoCacheService.cacheVideo(project.videoSHA, videoBlob)
          .then(() => {
            console.log('Successfully cached video:', project.videoSHA);
          })
          .catch(error => {
            console.warn('Failed to cache video:', error);
          });
        
        // Create object URL for immediate use
        videoConfig.url = URL.createObjectURL(videoBlob);
        videoConfig.blob = videoBlob; // Keep reference to the blob
        setVideoLoadingProgress(100);
      }

      // Fetch manifest to get original filename
      let originalFilename = null;
      try {
        const manifestResponse = await ProjectStateService.getManifest(project.videoSHA);
        console.log('Got manifest:', manifestResponse);
        originalFilename = manifestResponse?.manifest?.originalFilename;
      } catch (error) {
        console.warn('Failed to get manifest:', error);
      }

      // First try to get the transcript using FileUploadService
      let transcriptData = null;
      try {
        transcriptData = await FileUploadService.fetchTranscript('/api', project.videoSHA);
        console.log('Got transcript data:', transcriptData);
      } catch (error) {
        console.warn('Failed to get transcript:', error);
      }

      // Then load the project state
      const projectPath = project.filename || project.projectFileName + '.json';
      console.log('Loading project from path:', projectPath);
      
      try {
        const state = await ProjectStateService.loadProject(
          project.videoSHA, 
          projectPath
        );
        console.log('Loaded project state:', state);

        // Process the blocks through TranscriptProcessorService to ensure silences are properly formatted
        let processedBlocks = state.decisions?.blocks || [];
        if (transcriptData && processedBlocks.length > 0) {
          processedBlocks = processedBlocks.map(block => {
            if (!block.words) return block;
            
            return {
              ...block,
              words: block.words.map(word => {
                if (word.type === 'silence') {
                  const duration = word.end - word.start;
                  return {
                    ...word,
                    renderedContent: '[' + '.'.repeat(Math.ceil(duration)) + ']'
                  };
                }
                return word;
              })
            };
          });
        }

        // Update the store with complete state
        useStore.setState({
          transcriptBlocks: processedBlocks,
          projectName: state.projectName,
          projectFileName: state.projectFileName,
          videoSHA: project.videoSHA,
          originalTranscript: transcriptData?.text || '',
          transcriptData: transcriptData,
          hasTranscriptData: !!transcriptData,
          sourceLanguage: state.sourceLanguage || transcriptData?.language || null,
          videoFileName: originalFilename ?? state.videoFileName ?? 'Unknown File',
        });

        const updatedState = {
          ...state,
          decisions: {
            ...state.decisions,
            blocks: processedBlocks
          }
        };

        setProjectState(updatedState);
        setIsDirty(false);

        setVideoFile(videoConfig);
        setIsVideoLoading(false);
        return updatedState;
      } catch (error) {
        console.error('Error loading project state:', error);
        if (error.response) {
          console.error('Response data:', error.response.data);
          console.error('Response status:', error.response.status);
        }
        throw new Error(`Failed to load project: ${error.message}`);
      }
    } catch (error) {
      setIsVideoLoading(false);
      console.error('Error in loadRemoteProject:', error);
      throw error;
    }
  }, [setProjectState, setIsDirty]);


  const loadSelectedProject = useCallback(async (videoSHA, filename) => {
    try {
      const project = await loadRemoteProject(videoSHA, filename);

      if (!project) {
        console.warn('No project data found');
        return;
      }

      if (project.originalTranscript) {
        setTranscriptData(project.originalTranscript);
      }

      setTranscriptBlocks(project.decisions?.blocks || []);
      setProjectName(project.projectName || '');
      setProjectFileName(filename.replace('.json', ''));

    } catch (error) {
      console.error('Load project error:', error);
      setError('Failed to load project. Please try again.');
    }
  }, [loadRemoteProject, setTranscriptBlocks, setProjectName, setProjectFileName, setTranscriptData]);

  const listProjectsForSHA = useCallback(async (videoSHA) => {
    console.log('useProjects :: listProjectsForSHA for SHA:', videoSHA);
    try {
      const response = await ProjectStateService.listAllProjectsGroupedBySHA();
      console.log('Response from listAllProjectsGroupedBySHA:', response);
      
      if (!response?.data) {
        console.log('No data in response');
        return [];
      }

      const shaGroup = response.data.find(group => group.fileHash === videoSHA);
      
      if (!shaGroup) {
        console.log('No projects found for SHA:', videoSHA);
        return [];
      }

      return shaGroup.projects;
    } catch (error) {
      console.error('Error listing projects for SHA:', error);
      throw error;
    }
  }, []);

  const moveToTrash = useCallback(async (videoSHA, filename) => {
    try {
      const response = await ProjectStateService.moveProjectToTrash(videoSHA, filename);
      
      if (response.success) {
        // Show success toast
        toast.success('Project moved to trash');
        
        // Dispatch projectDeleted event
        console.log('Dispatching projectDeleted event');
        window.dispatchEvent(new CustomEvent('projectDeleted', {
          detail: { videoSHA, filename }
        }));
        
        // Refresh the files list
        await fetchFiles(videoSHA);
      }
    } catch (error) {
      console.error('Error moving project to trash:', error);
      
      // Handle specific error cases
      if (error.response?.status === 404) {
        toast.error('Project not found. It may have been already moved or deleted.');
      } else if (error.response?.status === 403) {
        toast.error('You do not have permission to move this project to trash.');
      } else {
        toast.error('Failed to move project to trash. Please try again.');
      }
      
      setError('Failed to move project to trash. Please try again.');
    }
  }, [fetchFiles]);

  return {
    projectState,
    isDirty,
    lastSaved,
    setProjectState,
    setIsDirty,
    saveProject: save,
    handleLoadProjectRemote: loadRemoteProject,
    handleLoadLocalProject: loadLocal,
    listAllProjectsGroupedBySHA,
    loadSelectedProject,
    fetchFiles,
    loadProject,
    files,
    error,
    listProjectsForSHA,
    moveToTrash,
    isVideoLoading,
    videoLoadingProgress,
    isLoading,
    loadingProgress,
    videoLoadingOperation,
  };
}

export default useProjects;