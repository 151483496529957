import { useState, useEffect } from 'react';
import { Button, ButtonGroup, Tooltip, Modal } from '@nextui-org/react';
import useStore from '../../store';
import { ProjectStateService } from '../../services';
import { KeyboardShortcutsModal } from '../KeyboardShortcuts/KeyboardShortcutsModal';
import { SaveModal } from '../SaveModal';
import { FiFilter as IsolateIcon, FiScissors as ScissorsIcon, FiMinusCircle as RejectIcon } from "react-icons/fi";
import { BiSearch as SearchIcon, BiCopy, BiUndo, BiRedo, BiSave } from "react-icons/bi";
import { TbGitCherryPick } from "react-icons/tb";
import { PiCursorTextLight } from "react-icons/pi";
import { VscBug } from "react-icons/vsc";
import { BsKeyboard } from "react-icons/bs";
import './ActionBar.css';

const ActionButton = ({ icon: Icon, text, tooltip, onClick, disabled, className, isIconOnly, size = "sm", isBottomRow = false }) => (
  <Tooltip content={tooltip || text} placement={isBottomRow ? "bottom" : "top"}>
    <Button
      size={size}
      isDisabled={disabled}
      onClick={onClick}
      className={`action-button transition-all duration-200 ${className || ''}`}
    >
      <div className="action-button-inner">
        <Icon size={20} className="flex-shrink-0" />
        <span className="button-text">
          {text}
        </span>
      </div>
    </Button>
  </Tooltip>
);

export const ActionBar = ({ onFineSeek }) => {
  const {
    selectedBlockIndex,
    transcriptBlocks,
    isFindBarVisible,
    toggleFindBar,
    addBlock,
    isDirty,
    canUndo,
    canRedo,
    undo,
    redo,
    setIsSaveModalOpen,
    isSaveModalOpen,
    closeSaveModal,
    saveProject,
    splitBlock,
    isolateSelectedWords,
    handleRejectBlock,
    selectedWordsInBlock,
    isPlaying,
    clearSelection,
    getSelectedWordsInCurrentBlock,
  } = useStore();

  const [isKeyboardShortcutsVisible, setIsKeyboardShortcutsVisible] = useState(false);
  const [isDebugVisible, setIsDebugVisible] = useState(false);

  const selectedBlock = selectedBlockIndex !== null ? transcriptBlocks[selectedBlockIndex] : null;
  const selectedWords = getSelectedWordsInCurrentBlock();

  const handleSave = () => {
    if (isDirty) {
      setIsSaveModalOpen(true);
    }
  };

  const handleReject = () => {
    if (isPlaying || selectedBlockIndex === null) return;
    
    const selectedWords = selectedWordsInBlock[selectedBlockIndex];
    if (selectedWords?.length > 0) {
      useStore.getState().splitBlockAndRejectSelectedWords();
      clearSelection();
    } else {
      handleRejectBlock(selectedBlockIndex);
    }
  };

  return (
    <div className="action-bar-container" style={{ containerType: 'inline-size' }}>
      <div className="action-row-top">
        {/* File Operations */}
        <div className="action-group">
          <ActionButton
            icon={BiSave}
            text="Save"
            tooltip="Save Project"
            onClick={handleSave}
            disabled={!isDirty}
            className={isDirty ? 'bg-gradient-to-tr from-purple-500 to-blue-500 text-white' : 'bg-gradient-to-tr from-purple-500/30 to-blue-500/30'}
          />
          <ActionButton
            icon={BiUndo}
            text="Undo"
            tooltip="Undo (Ctrl+Z)"
            onClick={undo}
            disabled={!canUndo()}
            className={canUndo() ? 'bg-gradient-to-tr from-pink-500/75 to-yellow-500/75 text-white' : 'bg-gradient-to-tr from-pink-500/20 to-yellow-500/20'}
          />
          <ActionButton
            icon={BiRedo}
            text="Redo"
            tooltip="Redo (Ctrl+Y)"
            onClick={redo}
            disabled={!canRedo()}
            className={canRedo() ? 'bg-gradient-to-tr from-orange-500/75 to-green-500/75 text-white' : 'bg-gradient-to-tr from-orange-500/20 to-green-500/20'}
          />
        </div>

        {/* Utilities */}
        <div className="action-group">
          <ActionButton
            icon={BsKeyboard}
            text="Shortcuts"
            tooltip="Keyboard Shortcuts"
            onClick={() => setIsKeyboardShortcutsVisible(true)}
            className="bg-gray-100/70 hover:bg-gray-500/60"
          />
          <ActionButton
            icon={VscBug}
            text="Debug"
            tooltip="Debug State"
            onClick={() => setIsDebugVisible(!isDebugVisible)}
            className="bg-gray-100/70 hover:bg-gray-500/60"
          />
        </div>
      </div>

      <div className="action-row-bottom">
        {/* Block Operations */}
        <div className="action-group">
          <ActionButton
            icon={ScissorsIcon}
            text="Split"
            tooltip="Split at selection or Current Word"
            onClick={splitBlock}
            disabled={!selectedBlock}
            className={`bg-yellow-100/70 ${selectedBlock?.isRejected ? 'hover:bg-red-500/60' : 'hover:bg-green-500/60'}`}
            isBottomRow={true}
          />
          <ActionButton
            icon={RejectIcon}
            text="Reject"
            tooltip="Reject selected words or block (R)"
            onClick={handleReject}
            disabled={!selectedBlock}
            className={`bg-red-100/70 ${selectedBlock?.isRejected ? 'hover:bg-red-500/60' : 'hover:bg-red-500/60'}`}
            isBottomRow={true}
          />
          <ActionButton
            icon={TbGitCherryPick}
            text="Isolate"
            tooltip="Isolate selected words, reject the rest"
            onClick={isolateSelectedWords}
            disabled={!selectedBlock || !selectedWords?.length}
            className={`bg-blue-100/70 ${selectedBlock?.isRejected ? 'hover:bg-red-500/60' : 'hover:bg-blue-500/60'}`}
            isBottomRow={true}
          />
          <ActionButton
            icon={BiCopy}
            text="Duplicate"
            tooltip="Duplicate Selected Block"
            onClick={() => {
              const selectedBlock = transcriptBlocks[selectedBlockIndex];
              if (selectedBlock) {
                addBlock({ ...selectedBlock, id: crypto.randomUUID() }, selectedBlockIndex + 1);
              }
            }}
            disabled={!selectedBlock}
            className="bg-indigo-100/70 hover:bg-indigo-500/60"
            isBottomRow={true}
          />
        </div>

        {/* Tools */}
        <div className="action-group">
          <ActionButton
            icon={SearchIcon}
            text="Find"
            tooltip="Toggle Find Bar"
            onClick={toggleFindBar}
            className={isFindBarVisible ? 'bg-indigo-200' : 'bg-indigo-100/70'}
            isBottomRow={true}
          />
          <ActionButton
            icon={PiCursorTextLight}
            text="Edit"
            tooltip="Toggle Word Editor"
            onClick={() => useStore.getState().toggleContextualToolbar()}
            className="bg-purple-100/70 hover:bg-purple-500/60"
            isBottomRow={true}
          />
        </div>
      </div>

      <KeyboardShortcutsModal
        isOpen={isKeyboardShortcutsVisible}
        onClose={() => setIsKeyboardShortcutsVisible(false)}
      />

      {isDebugVisible && (
        <div className="fixed bottom-4 right-4 z-50 p-4 bg-background/95 backdrop-blur-sm rounded-lg border border-default-200 shadow-xl max-h-[80vh] overflow-auto w-96">
          <div className="mb-2 space-y-2">
            <div><strong>isDirty:</strong> {isDirty ? 'true' : 'false'}</div>
            <div><strong>Blocks Count:</strong> {transcriptBlocks?.length || 0}</div>
            <div><strong>History:</strong> {canUndo() ? 'U' : '·'}{canRedo() ? 'R' : '·'}</div>
          </div>
          <pre className="text-xs whitespace-pre-wrap break-words">
            {JSON.stringify(ProjectStateService.getCurrentState(), null, 2) || 'No saved state'}
          </pre>
        </div>
      )}

      {isSaveModalOpen && (
        <SaveModal
          isOpen={isSaveModalOpen}
          onClose={closeSaveModal}
          onSave={saveProject}
        />
      )}
    </div>
  );
};