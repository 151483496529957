import React from 'react';
import { createBrowserRouter } from 'react-router-dom';
import App from './App';
import { Editor } from './components';
import useStore from './store';

// Protected route wrapper
const ProtectedRoute = ({ children }) => {
  const token = useStore.getState().token;
  
  if (!token) {
    return <App />;
  }
  
  return children;
};

export const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    children: [
      {
        path: 'project/:videoSHA/:projectFileName',
        element: (
          <ProtectedRoute>
            <Editor />
          </ProtectedRoute>
        ),
      },
    ],
  },
]); 