import React, { useRef, useEffect, useState, useCallback, forwardRef, useMemo } from 'react';
import ReactDOM from 'react-dom';
import useStore from '../../store';
import { useBlockHandlers, useDragAndDrop } from '../../hooks';
import { TranscriptBlock } from '..';
import { GrDrag as DraggableIcon } from "react-icons/gr";
import '../../index.css'; // Ensure this import is present to include the CSS

const TranscriptBlockComponent = (props) => {
  return <TranscriptBlock {...props} />;
};

const Row = React.forwardRef(({ index, data, onDragStart, onDragOver, onDrop, handleDragEnd: clearDragState, draggedIndex }, ref) => {
  const [isDraggable, setIsDraggable] = useState(false);
  const [showMoveButtons, setShowMoveButtons] = useState(false);
  const touchStartPosRef = useRef(null);
  const initialIndexRef = useRef(null);
  const localRef = useRef(null);

  const {
    transcriptBlocks,
    onSeek,
    handleBlockSelection,
    handleBlockRejection,
    currentBlockIndex,
    currentTime,
    selectedBlockIndex,
    selectedWords,
    handleSelectedWordsChange,
    isPlaying,
    handleDragEnd,
  } = data;
  const block = transcriptBlocks[index];

  const setRefs = useCallback((element) => {
    localRef.current = element;
    if (typeof ref === 'function') {
      ref(element);
    } else if (ref) {
      ref.current = element;
    }
  }, [ref]);

  // Setup non-passive touch event listeners
  useEffect(() => {
    const element = localRef.current;
    if (!element) return;

    const touchMoveHandler = (e) => {
      if (!touchStartPosRef.current || !isDraggable) return;
      
      e.preventDefault();
      
      const touch = e.touches[0];
      const currentY = touch.clientY;
      const deltaY = currentY - touchStartPosRef.current;
      
      const rowHeight = 60;
      const movement = Math.round(deltaY / rowHeight);
      
      if (movement !== 0) {
        console.log('Touch move', { deltaY, movement });
        const newIndex = Math.max(0, Math.min(initialIndexRef.current + movement, transcriptBlocks.length - 1));
        onDragOver(e, newIndex);
      }
    };

    element.addEventListener('touchmove', touchMoveHandler, { passive: false });
    return () => element.removeEventListener('touchmove', touchMoveHandler);
  }, [isDraggable, transcriptBlocks.length, onDragOver]);

  // Keep your existing touch handlers but remove preventDefault from them
  const handleTouchStart = (e) => {
    if (!e.target.closest('.grip-area')) return;
    
    console.log('Touch start', { index });
    const touch = e.touches[0];
    touchStartPosRef.current = touch.clientY;
    initialIndexRef.current = index;
    setIsDraggable(true);

    if (navigator.vibrate) {
      navigator.vibrate(25);
    }

    onDragStart(e, index);
  };

  const handleTouchEnd = (e) => {
    if (!isDraggable) return;
    console.log('Touch end');

    const touch = e.changedTouches[0];
    const deltaY = touch.clientY - touchStartPosRef.current;
    const rowHeight = 60;
    const movement = Math.round(deltaY / rowHeight);
    const finalIndex = Math.max(0, Math.min(initialIndexRef.current + movement, transcriptBlocks.length - 1));
    
    onDrop(e, finalIndex);
    setIsDraggable(false);
    touchStartPosRef.current = null;
    initialIndexRef.current = null;
    clearDragState();
  };

  // Add mouseup handler to document to catch all mouse up events
  useEffect(() => {
    const handleMouseUp = () => {
      setIsDraggable(false);
    };

    document.addEventListener('mouseup', handleMouseUp);
    return () => document.removeEventListener('mouseup', handleMouseUp);
  }, []);

  const handleMouseDown = (e) => {
    if (e.target.closest('.grip-area')) {
      setIsDraggable(true);
      e.currentTarget.setAttribute('draggable', 'true');
    }
  };

  const handleDragStartWrapper = (e) => {
    if (isDraggable) {
      e.dataTransfer.effectAllowed = 'move';
      onDragStart(e, index);
    } else {
      e.preventDefault();
    }
  };

  const handleDragEndWrapper = (e) => {
    setIsDraggable(false);
    e.currentTarget.removeAttribute('draggable');
    handleDragEnd(e);
  };

  // Only apply opacity when actually dragging AND mouse is still down
  const isDragging = draggedIndex === index && isDraggable;

  // Add a class to show where the item will be dropped
  const getDropIndicatorClass = () => {
    if (draggedIndex === null || draggedIndex === index) return '';
    return draggedIndex < index ? 'drop-target-below' : 'drop-target-above';
  };

  const handleGripClick = (e) => {
    if (!showMoveButtons) {
      e.stopPropagation();
      setShowMoveButtons(true);
    }
  };

  const handleMoveClick = (direction) => (e) => {
    e.stopPropagation();
    e.preventDefault();
    
    const newIndex = direction === 'up' ? index - 1 : index + 1;
    if (newIndex >= 0 && newIndex < transcriptBlocks.length) {
      handleDragEnd({
        source: { index },
        destination: { index: newIndex }
      });
      handleBlockSelection(newIndex, currentTime, false);
    }
    setShowMoveButtons(false);
  };

  // Click outside to reset
  useEffect(() => {
    const handleClickOutside = () => {
      setShowMoveButtons(false);
    };

    if (showMoveButtons) {
      document.addEventListener('click', handleClickOutside);
      return () => document.removeEventListener('click', handleClickOutside);
    }
  }, [showMoveButtons]);

  return (
    <div
      ref={setRefs}
      data-block-index={index}
      className={`draggable-row flex group relative ${isDragging ? 'opacity-30' : ''}`}
      style={{ cursor: isDragging ? 'move' : 'default' }}
      onMouseDown={handleMouseDown}
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
      onTouchCancel={handleTouchEnd}
      onDragStart={handleDragStartWrapper}
      onDragOver={(e) => onDragOver(e, index)}
      onDrop={(e) => onDrop(e, index)}
      onDragEnd={handleDragEndWrapper}
    >
      {/* Invisible drop zone that extends above and below */}
      <div
        className="absolute inset-0 -top-4 -bottom-4"
        style={{ zIndex: 1 }}
      />

      {/* Actual content */}
      <div className="flex w-full relative" style={{ zIndex: 2 }}>
        <div
          className="grip-area cursor-move active:bg-gray-400/30 hover:bg-gray-400/30 flex items-center transition-all ease-in-out duration-200 rounded-sm"
          onClick={handleGripClick}
        >
          {showMoveButtons ? (
            <div className="flex flex-col w-5">
              <button
                className={`h-1/2 flex items-center justify-center hover:bg-gray-400/40 transition-colors ${index === 0 ? 'opacity-50 cursor-not-allowed' : ''}`}
                onClick={handleMoveClick('up')}
                disabled={index === 0}
              >
                <svg className="w-5 h-5" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 15l7-7 7 7" />
                </svg>
              </button>
              <button
                className={`h-1/2 flex items-center justify-center hover:bg-gray-400/40 transition-colors ${index === transcriptBlocks.length - 1 ? 'opacity-50 cursor-not-allowed' : ''}`}
                onClick={handleMoveClick('down')}
                disabled={index === transcriptBlocks.length - 1}
              >
                <svg className="w-5 h-5" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                </svg>
              </button>
            </div>
          ) : (
            <div className="grip-lines opacity-0 w-5 group-hover:opacity-100 group-active:opacity-100 transition-all ease-in-out duration-200">
              <DraggableIcon style={{ marginLeft: '2px' }} />
            </div>
          )}
        </div>
        <div className="text-content w-full">
          <TranscriptBlockComponent
            {...block}
            onSeek={onSeek}
            onClick={() => {
              if (isPlaying) return;
              handleBlockSelection(index, block.start);
            }}
            isCurrentBlock={index === currentBlockIndex}
            currentTime={currentTime}
            isRejected={block.isRejected}
            onReject={() => {
              if (isPlaying) return;
              handleBlockRejection(index);
            }}
            isSelected={index === selectedBlockIndex}
            blockIndex={index}
            selectedWords={index === selectedBlockIndex ? selectedWords : []}
            onSelectedWordsChange={handleSelectedWordsChange}
            onBlockSelect={() => {
              if (isPlaying) return;
              handleBlockSelection(index, block.start);
            }}
          />
        </div>
      </div>
    </div>
  );
});

const ShadowRootWrapper = ({ children, placeholderRef }) => {
  const shadowRootRef = useRef(null);
  const [shadowRoot, setShadowRoot] = useState(null);

  useEffect(() => {
    if (shadowRootRef.current && !shadowRoot) {
      const shadow = shadowRootRef.current.attachShadow({ mode: 'open' });

      const styleElement = document.createElement('style');
      styleElement.textContent = `
        @import url('/src/index.css');
        .placeholder {
          width: 100%;
          height: 2px;
          background-color: rgb(var(--highlight));
          opacity: 0.8;
          border-radius: 9999px;
          margin: 4px 0;
        }
      `;
      shadow.appendChild(styleElement);

      const placeholder = document.createElement('div');
      placeholder.classList.add('placeholder');
      shadow.appendChild(placeholder);
      placeholderRef.current = placeholder;

      setShadowRoot(shadow);
    }
  }, [shadowRoot, placeholderRef]);

  return (
    <div ref={shadowRootRef}>
      {shadowRoot && ReactDOM.createPortal(children, shadowRoot)}
    </div>
  );
};

export const TranscriptOrderManager = ({ onSeek, currentTime, preventOverlap, isPreviewMode }) => {
  const [visibleBlocks, setVisibleBlocks] = useState(new Set());
  const [isScrollable, setIsScrollable] = useState(false);
  const [draggedIndex, setDraggedIndex] = useState(null);
  const [dropTargetIndex, setDropTargetIndex] = useState(null);
  const placeholderRef = useRef(null);
  const scrollContainerRef = useRef(null);
  const [viewportCenter, setViewportCenter] = useState(0);
  const observerRef = useRef(null);
  const blockRefs = useRef({});
  const [lastScrollTime, setLastScrollTime] = useState(0);
  const [lastRequestedScroll, setLastRequestedScroll] = useState(null);
  const SCROLL_THRESHOLD = 600; // ms between scrolls

  // Get state from store
  const transcriptBlocks = useStore(state => state.transcriptBlocks);
  const setTranscriptBlocks = useStore(state => state.setTranscriptBlocks);
  const currentBlockIndex = useStore(state => state.currentBlockIndex);
  const selectedBlockIndex = useStore(state => state.selectedBlockIndex);
  const selectedWords = useStore(state => state.selectedWords);
  const setSelectedWordsInBlock = useStore(state => state.setSelectedWordsInBlock);
  const isPlaying = useStore(state => state.isPlaying);
  const setSelectedBlockIndex = useStore(state => state.setSelectedBlockIndex);
  const setCurrentBlockIndex = useStore(state => state.setCurrentBlockIndex);
  const handleRejectBlock = useStore(state => state.handleRejectBlock);

  // Define handlers first
  const handleBlockSelection = useCallback((index, start) => {
    if (isPlaying) return;
    setSelectedBlockIndex(index);
    if (start !== undefined) {
      onSeek?.(start);
      setCurrentBlockIndex(index);
    }
  }, [isPlaying, setSelectedBlockIndex, onSeek, setCurrentBlockIndex]);

  const handleBlockRejection = useCallback((index) => {
    if (isPlaying) return;
    handleRejectBlock(index);
  }, [isPlaying, handleRejectBlock]);

  // Memoize the selectedWordsInBlock handler
  const handleSelectedWordsChange = useCallback((blockIndex, words) => {
    // Skip update if words array is empty or null
    if (!words || words.length === 0) {
      setSelectedWordsInBlock(blockIndex, null);
      return;
    }

    // Compare with existing selected words to prevent unnecessary updates
    const currentSelectedWords = selectedWords;
    if (blockIndex === selectedBlockIndex && 
        currentSelectedWords && 
        currentSelectedWords.length === words.length &&
        currentSelectedWords.every((word, i) => word === words[i])) {
      return;
    }

    setSelectedWordsInBlock(blockIndex, words);
  }, [setSelectedWordsInBlock, selectedWords, selectedBlockIndex]);

  // Use the drag and drop hook
  const { handleDragEnd } = useDragAndDrop(transcriptBlocks, setTranscriptBlocks);

  // Memoize the data object passed to Row components
  const rowData = useMemo(() => ({
    transcriptBlocks,
    onSeek,
    handleBlockSelection,
    handleBlockRejection,
    currentBlockIndex,
    currentTime,
    selectedBlockIndex,
    selectedWords,
    handleSelectedWordsChange,
    isPlaying,
    handleDragEnd,
  }), [
    transcriptBlocks,
    onSeek,
    handleBlockSelection,
    handleBlockRejection,
    currentBlockIndex,
    currentTime,
    selectedBlockIndex,
    selectedWords,
    handleSelectedWordsChange,
    isPlaying,
    handleDragEnd,
  ]);

  // Setup intersection observer
  useEffect(() => {
    if (!scrollContainerRef.current) return;

    const observer = new IntersectionObserver(
      (entries) => {
        setVisibleBlocks(prev => {
          const next = new Set(prev);
          entries.forEach(entry => {
            const index = parseInt(entry.target.dataset.blockIndex);
            if (entry.isIntersecting) {
              next.add(index);
            } else {
              next.delete(index);
            }
          });
          return next;
        });
      },
      {
        root: scrollContainerRef.current,
        rootMargin: '150% 0px',
        threshold: 0
      }
    );

    observerRef.current = observer;

    // Observe any existing blocks
    const elements = document.querySelectorAll('[data-block-index]');
    elements.forEach(el => observer.observe(el));

    return () => {
      observer.disconnect();
      observerRef.current = null;
    };
  }, []);

  // Re-observe blocks when they change
  useEffect(() => {
    const observer = observerRef.current;
    if (!observer) return;

    // Cleanup function to disconnect observer
    const cleanup = () => {
      observer.disconnect();
    };

    // Debounce the observation to prevent rapid re-renders
    const timeoutId = setTimeout(() => {
      cleanup();

      // Observe new blocks
      const elements = document.querySelectorAll('[data-block-index]');
      elements.forEach(el => observer.observe(el));

      // Force a visibility check
      const container = scrollContainerRef.current;
      if (!container) return;

      const containerRect = container.getBoundingClientRect();
      const scrollTop = container.scrollTop;
      const viewportMiddle = scrollTop + (containerRect.height / 2);
      
      const visibleIndices = new Set();
      elements.forEach(el => {
        const index = parseInt(el.dataset.blockIndex);
        const rect = el.getBoundingClientRect();
        const elementMiddle = rect.top + (rect.height / 2) - containerRect.top + scrollTop;
        const distanceFromCenter = Math.abs(elementMiddle - viewportMiddle);
        
        if (distanceFromCenter < containerRect.height * 1.5) {
          visibleIndices.add(index);
        }
      });

      setVisibleBlocks(visibleIndices);
    }, 100); // Debounce for 100ms

    return () => {
      cleanup();
      clearTimeout(timeoutId);
    };
  }, [transcriptBlocks.length]); // Only re-run when the number of blocks changes

  // Add effect to handle auto-scrolling when selected block changes
  useEffect(() => {
    if (selectedBlockIndex === null || !scrollContainerRef.current) return;

    const blockElement = document.querySelector(`[data-block-index="${selectedBlockIndex}"]`);
    if (!blockElement) return;

    const container = scrollContainerRef.current;
    const containerRect = container.getBoundingClientRect();
    const elementRect = blockElement.getBoundingClientRect();
    
    // Check if the block is outside the visible viewport
    const isBlockAbove = elementRect.top < containerRect.top;
    const isBlockBelow = elementRect.bottom > containerRect.bottom;
    
    // Calculate target scroll position
    const relativeTop = elementRect.top - containerRect.top;
    const centerOffset = (containerRect.height - elementRect.height) / 2;
    const targetScroll = container.scrollTop + relativeTop - centerOffset;

    // Always store the last requested scroll position
    setLastRequestedScroll(targetScroll);
    
    // Only scroll if block is outside viewport or this is the final scroll request
    if (isBlockAbove || isBlockBelow) {
      const now = Date.now();
      const timeSinceLastScroll = now - lastScrollTime;
      
      // Skip scrolling if we've scrolled too recently, unless this is the final scroll
      if (timeSinceLastScroll < SCROLL_THRESHOLD) {
        // Schedule a final scroll after the threshold
        const timeoutId = setTimeout(() => {
          if (scrollContainerRef.current && lastRequestedScroll !== null) {
            scrollContainerRef.current.scrollTo({
              top: lastRequestedScroll,
              behavior: 'smooth'
            });
            setLastScrollTime(Date.now());
            setLastRequestedScroll(null);
          }
        }, SCROLL_THRESHOLD - timeSinceLastScroll);

        return () => clearTimeout(timeoutId);
      }

      container.scrollTo({
        top: targetScroll,
        behavior: 'smooth'
      });
      
      setLastScrollTime(now);
      setLastRequestedScroll(null);
    }
  }, [selectedBlockIndex, lastScrollTime, lastRequestedScroll]);

  // Define handlers first
  const handleBlockSelectionCallback = useCallback((index, start) => {
    if (isPlaying) return;
    setSelectedBlockIndex(index);
    if (start !== undefined) {
      onSeek?.(start);
      setCurrentBlockIndex(index);
    }
  }, [isPlaying, setSelectedBlockIndex, onSeek, setCurrentBlockIndex]);

  const handleBlockRejectionCallback = useCallback((index) => {
    if (isPlaying) return;
    handleRejectBlock(index);
  }, [isPlaying, handleRejectBlock]);

  const onDragStart = useCallback((e, index) => {
    setDraggedIndex(index);
  }, []);

  const onDragOver = useCallback((e, index) => {
    e.preventDefault();
    const rect = e.currentTarget.getBoundingClientRect();
    const mouseY = e.clientY - rect.top;
    
    // Calculate potential drop position
    const dropIndex = mouseY < rect.height / 2 ? index : index + 1;
    
    // Only set dropTargetIndex if it would result in an actual move
    // Don't allow dropping right before or after the dragged item
    if (dropIndex !== draggedIndex && dropIndex !== draggedIndex + 1) {
      setDropTargetIndex(dropIndex);
    } else {
      setDropTargetIndex(null);
    }
  }, [draggedIndex]);

  const onDrop = useCallback((e, index) => {
    if (draggedIndex !== null && dropTargetIndex !== null && dropTargetIndex !== draggedIndex && dropTargetIndex !== draggedIndex + 1) {
      // Adjust destination index if dropping below the dragged item
      const adjustedDestination = dropTargetIndex > draggedIndex ? dropTargetIndex - 1 : dropTargetIndex;
      
      handleDragEnd({ 
        source: { index: draggedIndex }, 
        destination: { index: adjustedDestination } 
      });
    }
    setDraggedIndex(null);
    setDropTargetIndex(null);
  }, [draggedIndex, dropTargetIndex, handleDragEnd]);

  const clearDragState = useCallback(() => {
    setDraggedIndex(null);
    setDropTargetIndex(null);
  }, []);

  // Check if container is scrollable
  useEffect(() => {
    const checkScrollable = () => {
      if (scrollContainerRef.current) {
        const isScrollable = scrollContainerRef.current.scrollHeight > scrollContainerRef.current.clientHeight;
        setIsScrollable(isScrollable);
        
        // If not scrollable, make all blocks visible
        if (!isScrollable) {
          const allIndices = new Set(transcriptBlocks.map((_, index) => index));
          setVisibleBlocks(allIndices);
        }
      }
    };

    checkScrollable();
    // Add resize observer to check when container becomes scrollable
    const resizeObserver = new ResizeObserver(checkScrollable);
    if (scrollContainerRef.current) {
      resizeObserver.observe(scrollContainerRef.current);
    }

    return () => resizeObserver.disconnect();
  }, [transcriptBlocks]);

  // Track scroll position and update visible blocks
  useEffect(() => {
    if (!scrollContainerRef.current || !isScrollable) return;

    const handleScroll = () => {
      const container = scrollContainerRef.current;
      if (!container) return;

      const containerRect = container.getBoundingClientRect();
      const scrollTop = container.scrollTop;
      const viewportMiddle = scrollTop + (containerRect.height / 2);
      setViewportCenter(viewportMiddle);

      // Find blocks near viewport center or near selected/current block
      const elements = document.querySelectorAll('[data-block-index]');
      const visibleIndices = new Set();

      elements.forEach(el => {
        const index = parseInt(el.dataset.blockIndex);
        const rect = el.getBoundingClientRect();
        const elementMiddle = rect.top + (rect.height / 2) - containerRect.top + scrollTop;
        const distanceFromCenter = Math.abs(elementMiddle - viewportMiddle);
        
        // Consider blocks if:
        // 1. They're within viewport range
        // 2. They're near the selected block
        // 3. They're near the current block
        const isNearViewport = distanceFromCenter < containerRect.height * 1.5;
        const isNearSelected = Math.abs(index - selectedBlockIndex) <= 5;
        const isNearCurrent = Math.abs(index - currentBlockIndex) <= 5;
        
        if (isNearViewport || isNearSelected || isNearCurrent) {
          visibleIndices.add(index);
        }
      });

      setVisibleBlocks(visibleIndices);
    };

    const container = scrollContainerRef.current;
    container.addEventListener('scroll', handleScroll);
    handleScroll(); // Initial check

    return () => container.removeEventListener('scroll', handleScroll);
  }, [isScrollable, selectedBlockIndex, currentBlockIndex]);

  // Modify renderBlock to consider both scroll and non-scroll states
  const renderBlock = useCallback((block, index) => {
    const isVisible = visibleBlocks.has(index);
    const isCurrentOrSelected = index === currentBlockIndex || index === selectedBlockIndex;
    const isNearSelected = Math.abs(index - selectedBlockIndex) <= 5;
    const isNearCurrent = Math.abs(index - currentBlockIndex) <= 5;
    
    // Show block if:
    // 1. Container isn't scrollable (show all)
    // 2. Block is visible in viewport
    // 3. Block is current or selected
    // 4. Block is near current or selected
    const shouldRender = !isScrollable || isVisible || isCurrentOrSelected || isNearSelected || isNearCurrent;

    return (
      <React.Fragment key={`block-${block.id}-${index}`}>
        {draggedIndex !== null && dropTargetIndex === index && (
          <div 
            key={`drop-indicator-top-${index}`}
            className="h-px bg-sky-300/70 w-full" 
          />
        )}
        
        <div 
          data-block-index={index} 
          className="block-container p-0"
          style={{ 
            height: shouldRender ? 'auto' : '60px',
            opacity: shouldRender ? 1 : 0
          }}
        >
          {shouldRender ? (
            <Row
              key={`row-${block.id}`}
              index={index}
              draggedIndex={draggedIndex}
              data={rowData}
              onDragStart={onDragStart}
              onDragOver={onDragOver}
              onDrop={onDrop}
              handleDragEnd={clearDragState}
            />
          ) : (
            <div key={`placeholder-${index}`} className="placeholder-block" />
          )}
        </div>
        
        {draggedIndex !== null && dropTargetIndex === index + 1 && (
          <div className="h-px bg-sky-300/70 w-full" />
        )}
      </React.Fragment>
    );
  }, [
    isScrollable,
    visibleBlocks,
    currentBlockIndex,
    selectedBlockIndex,
    draggedIndex,
    dropTargetIndex,
    rowData,
    onDragStart,
    onDragOver,
    onDrop,
    clearDragState
  ]);

  return (
    <div
      ref={scrollContainerRef}
      className="h-full w-full overflow-y-auto overflow-x-hidden"
      style={{ 
        maxHeight: '100%',
        minHeight: '0',
        flex: '1 1 auto'
      }}
    >
      <div className="relative w-full h-full">
        <div className="flex flex-col gap-0">
          {transcriptBlocks.map(renderBlock)}
        </div>
      </div>
    </div>
  );
};