import { v4 as uuidv4 } from 'uuid';
import { debounce } from 'lodash';
import { ProjectStateService } from '../services';

let currentPlaybackInterval = null;  // Store interval reference at module level

const useTranscriptBlocksStore = (set, get) => {
  const debouncedSetTranscriptBlocks = debounce((blocks) => {
    console.log('Debounced set transcript blocks:', {
      blocks,
      rejectedStates: blocks.map(b => b.isRejected)
    });
    
    // Create new state snapshot with FULL block data
    const newState = {
      transcriptBlocks: blocks.map(block => ({
        ...block,
        words: block.words.map(word => ({ ...word })),
        isRejected: block.isRejected
      })),
      includeRejectedBlocksInJSON: get().includeRejectedBlocksInJSON
    };

    // Get current history state
    const { history, currentHistoryIndex } = get();
    
    console.log('History before update:', {
      historyLength: history.length,
      currentIndex: currentHistoryIndex,
      currentState: history[currentHistoryIndex]?.transcriptBlocks?.map(b => b.isRejected)
    });
    
    // Add new state to history
    set({ 
      transcriptBlocks: blocks,
      history: [...history.slice(0, currentHistoryIndex + 1), newState],
      currentHistoryIndex: currentHistoryIndex + 1
    });

    console.log('History after update:', {
      historyLength: history.length,
      newIndex: currentHistoryIndex + 1,
      newState: newState.transcriptBlocks.map(b => b.isRejected)
    });

    markDirty();
  }, 100);

  const updateTranscriptBlocks = (blocks) => {
    console.log('updateTranscriptBlocks START', {
      blockCount: blocks.length,
      operation: 'initial'
    });

    const processedBlocks = blocks
      .filter(block => block.words.length > 0)
      .map(block => ({
        ...block,
        isRejected: block.isRejected ?? false
      }));

    // Sort blocks
    processedBlocks.sort((a, b) => (a.isRejected ?? false) - (b.isRejected ?? false));
    
    // Immediate set with a flag to force re-render
    set({ 
      transcriptBlocks: processedBlocks,
      lastUpdateTimestamp: Date.now()
    });

    console.log('State updated with new blocks and timestamp');

    // Queue the debounced update for history
    debouncedSetTranscriptBlocks(processedBlocks);
  };

  // Helper to get a clean version of state for comparison
  const getCleanStateForComparison = () => {
    return {
      videoSHA: "placeholder-sha",
      originalTranscript: "",
      decisions: {
        blocks: get().transcriptBlocks.map(block => ({
          words: block.words.map(word => ({
            word: word.word,
            start: word.start,
            end: word.end
          })),
          start: block.start,
          end: block.end,
          content: block.content,
          isRejected: block.isRejected,
          id: block.id
        })),
        settings: {
          breakAtPunctuation: false,
          maxCharPerSection: 500,
          breakOnSpeakerChange: false,
          rejectLongSilences: false,
          longSilenceThreshold: 1
        }
      }
    };
  };

  // Helper to mark state as dirty only if debug state has changed
  const markDirty = () => {
    const currentState = getCleanStateForComparison();
    const savedState = ProjectStateService.getCurrentState();
    
    if (!savedState) {
      set({ isDirty: true });
      return;
    }

    // Remove metadata from comparison
    const { metadata, ...cleanSavedState } = savedState;
    
    // Compare specific properties we care about
    const currentBlocks = currentState.decisions.blocks;
    const savedBlocks = cleanSavedState.decisions.blocks;
    
    // Deep compare the relevant properties
    const blocksEqual = currentBlocks.length === savedBlocks.length &&
      currentBlocks.every((block, index) => {
        const savedBlock = savedBlocks[index];
        return (
          block.isRejected === savedBlock.isRejected &&
          block.start === savedBlock.start &&
          block.end === savedBlock.end &&
          block.content === savedBlock.content &&
          block.words.length === savedBlock.words.length &&
          block.words.every((word, i) => 
            word.word === savedBlock.words[i].word &&
            word.start === savedBlock.words[i].start &&
            word.end === savedBlock.words[i].end
          )
        );
      });

    if (!blocksEqual) {
      console.log('State changed because blocks are different:', {
        currentBlocks,
        savedBlocks
      });
      set({ isDirty: true });
    }
  };

  // Add handleSeek to the store's state
  const handleSeekInternal = (time) => {
    const { videoPlayerRef } = get();
    if (videoPlayerRef.current) {
      videoPlayerRef.current.seekTo(time);
      set({ currentTime: time });
    }
  };

  // Add utility function for post-rejection updates
  const handlePostRejectionUpdate = (currentTime, blockIndex) => {
    const { transcriptBlocks } = get();
    
    // Find the block with the closest timestamp to our current time
    const targetBlock = transcriptBlocks.find(block => 
      !block.isRejected && Math.abs(block.start - currentTime) < 0.1
    );

    if (targetBlock) {
      const newIndex = transcriptBlocks.indexOf(targetBlock);
      
      // Update selection and current block
      get().setSelectedBlockIndex(newIndex);
      get().setCurrentBlockIndex(newIndex);
      
      // Scroll to the new position
      queueMicrotask(() => {
        const blockElement = document.querySelector(`[data-block-index="${newIndex}"]`);
        const transcriptContainer = document.querySelector('.transcript-manager');
        
        if (blockElement && transcriptContainer) {
          const containerRect = transcriptContainer.getBoundingClientRect();
          const elementRect = blockElement.getBoundingClientRect();
          
          const relativeTop = elementRect.top - containerRect.top;
          const centerOffset = (containerRect.height - elementRect.height) / 2;
          const targetScroll = transcriptContainer.scrollTop + relativeTop - centerOffset;
          
          transcriptContainer.scrollTo({
            top: targetScroll,
            behavior: 'smooth'
          });
        }
      });
    }
  };

  return {
    transcriptBlocks: [],
    currentBlockIndex: 0,
    selectedBlockIndex: 0,
    selectedWordsInBlock: {},
    isSelecting: false,
    selectionStart: null,
    selectionAnchorIndex: null,
    isShiftSelecting: false,
    isDragging: false,
    setIsDragging: (isDragging) => set({ isDragging }),
    getSelectedWordsInCurrentBlock: () => get().selectedWordsInBlock[get().currentBlockIndex],
    setTranscriptBlocks: updateTranscriptBlocks,
    toggleBlockRejection: (index) => {
      const updated = [...get().transcriptBlocks];
      updated[index].isRejected = !updated[index].isRejected;
      updateTranscriptBlocks(updated);
    },
    groupTranscriptBlocks: () => {
      const { transcriptBlocks } = get();
      const rejectedBlocks = transcriptBlocks.filter(block => block.isRejected);
      const nonRejectedBlocks = transcriptBlocks.filter(block => !block.isRejected);
      const updatedBlocks = [...nonRejectedBlocks, ...rejectedBlocks];
      updateTranscriptBlocks(updatedBlocks);
      return updatedBlocks;
    },
    setCurrentBlockIndex: (index) => set({ currentBlockIndex: index }),
    setSelectedBlockIndex: (index) => set({ selectedBlockIndex: index }),
    setSelectedWordsInBlock: (blockIndex, words) => {
      const currentSelectedWords = get().selectedWordsInBlock[blockIndex];
      
      // If both are null/undefined, no update needed
      if (!currentSelectedWords && !words) return;
      
      // If words is null/undefined, just clear the selection for this block
      if (!words) {
        const newSelectedWordsInBlock = { ...get().selectedWordsInBlock };
        delete newSelectedWordsInBlock[blockIndex];
        set({ selectedWordsInBlock: newSelectedWordsInBlock });
        return;
      }
      
      // If arrays are identical (same reference), no update needed
      if (currentSelectedWords === words) return;
      
      // If arrays have different lengths or different content, update
      if (!currentSelectedWords || 
          words.length !== currentSelectedWords.length || 
          words.some((w, i) => w !== currentSelectedWords[i])) {
        set({ 
          selectedWordsInBlock: { 
            ...get().selectedWordsInBlock, 
            [blockIndex]: [...words] // Create new array to ensure reference changes
          }
        });
      }
    },
    setIsSelecting: (value) => set({ isSelecting: value }),
    setSelectionStart: (start) => set({ selectionStart: start }),
    setSelectionAnchorIndex: (index) => set({ selectionAnchorIndex: index }),
    setIsShiftSelecting: (value) => set({ isShiftSelecting: value }),
    setSelectedWords: (blockIndex, selectedIndices) => {
      // Clear previous selections
      const newSelectedWordsInBlock = {};
      // Set new selection
      newSelectedWordsInBlock[blockIndex] = selectedIndices;
      set({ selectedWordsInBlock: newSelectedWordsInBlock });
    },
    splitBlock: (rejectPart = null, splitAfterCurrent = false) => {
      const { transcriptBlocks } = get();
      const currentBlockIndex = get().currentBlockIndex;
      const currentBlock = transcriptBlocks[currentBlockIndex];
      const selectedWords = get().selectedWordsInBlock[currentBlockIndex];
      const currentTime = get().currentTime;
      const activeWordIndex = get().getCurrentWordIndex(currentTime);

      const createBlock = (words, isRejected = false) => ({
        words,
        start: words[0]?.start || 0,
        end: words[words.length - 1]?.end || 0,
        speaker: currentBlock.speaker,
        content: words.map(word => word.word).join(' '),
        isRejected,
      });

      let newBlocks = [];

      if (selectedWords && selectedWords.length > 0) {
        // Handle case when words are selected
        const firstSelectedWordIndex = selectedWords[0];
        const lastSelectedWordIndex = selectedWords[selectedWords.length - 1];

        if (firstSelectedWordIndex > 0) {
          newBlocks.push(createBlock(currentBlock.words.slice(0, firstSelectedWordIndex), rejectPart === 'left'));
        }

        newBlocks.push(createBlock(currentBlock.words.slice(firstSelectedWordIndex, lastSelectedWordIndex + 1)));

        if (lastSelectedWordIndex < currentBlock.words.length - 1) {
          newBlocks.push(createBlock(currentBlock.words.slice(lastSelectedWordIndex + 1), rejectPart === 'right'));
        }
      } else if (activeWordIndex !== -1) {
        // Handle case when no words are selected, but there's an active word
        const splitIndex = splitAfterCurrent ? activeWordIndex + 1 : activeWordIndex;

        newBlocks.push(createBlock(currentBlock.words.slice(0, splitIndex), rejectPart === 'left'));
        newBlocks.push(createBlock(currentBlock.words.slice(splitIndex), rejectPart === 'right'));
      } else {
        // If no words are selected and no active word, don't split
        return;
      }

      // Remove empty blocks
      newBlocks = newBlocks.filter(block => block.words.length > 0);

      // Only proceed with updates if we actually created multiple blocks (i.e., a real split happened)
      if (newBlocks.length > 1) {
        const isRejected = transcriptBlocks[currentBlockIndex].isRejected;

        newBlocks = newBlocks.map(block => ({
          ...block,
          id: uuidv4(),
          isRejected: block.isRejected || isRejected
        }));

        // Remove the original block and insert the new blocks
        const updatedBlocks = [
          ...transcriptBlocks.slice(0, currentBlockIndex),
          ...newBlocks,
          ...transcriptBlocks.slice(currentBlockIndex + 1)
        ];

        // After creating the blocks, determine which block should become current
        let newCurrentBlockIndex = currentBlockIndex;
        
        if (activeWordIndex !== -1) {
          // If splitting at active word, make the block containing that word current
          newCurrentBlockIndex = currentBlockIndex + (splitAfterCurrent ? 0 : 1);
        }

        // Update blocks and indices only if split was successful
        updateTranscriptBlocks(updatedBlocks);
        get().setTranscriptBlocks(updatedBlocks);
        
        // Update both current and selected block indices
        set({ 
          currentBlockIndex: newCurrentBlockIndex,
          selectedBlockIndex: newCurrentBlockIndex 
        });
        
        // Force refresh of active word index
        get().getCurrentWordIndex(currentTime);
      }
    },
    splitBlockAndRejectSelectedWords: () => {
      const { transcriptBlocks } = get();
      const currentBlockIndex = get().currentBlockIndex;
      const currentBlock = transcriptBlocks[currentBlockIndex];
      const selectedWords = get().selectedWordsInBlock[currentBlockIndex];

      // If no words are selected or ALL words are selected, reject the entire block
      if (!selectedWords || 
          selectedWords.length === 0 || 
          selectedWords.length === currentBlock.words.length) {
        const updatedBlocks = transcriptBlocks.map((block, index) =>
          index === currentBlockIndex ? { ...block, isRejected: !block.isRejected } : block
        );
        get().setTranscriptBlocks(updatedBlocks);
        return;
      }

      const createBlock = (words, isRejected = false) => ({
        words,
        start: words[0]?.start || 0,
        end: words[words.length - 1]?.end || 0,
        speaker: currentBlock.speaker,
        content: words.map(word => word.word).join(' '),
        isRejected,
      });

      const firstSelectedWordIndex = selectedWords[0];
      const lastSelectedWordIndex = selectedWords[selectedWords.length - 1];

      const newBlocks = [...transcriptBlocks];

      const newRejectedBlock = createBlock(currentBlock.words.slice(firstSelectedWordIndex, lastSelectedWordIndex + 1), true);

      // Create the block with words before the selected words
      const updatedCurrentBlock = createBlock(currentBlock.words.slice(0, firstSelectedWordIndex));

      // Create the block with words after the selected words
      const newAfterBlock = createBlock(currentBlock.words.slice(lastSelectedWordIndex + 1));

      // Insert the new rejected block and the new after block after the current block
      newBlocks.splice(currentBlockIndex + 1, 0, {
        ...newRejectedBlock,
        id: uuidv4(),
      }, {
        ...newAfterBlock,
        id: uuidv4(),
      });

      // Update the current block
      newBlocks[currentBlockIndex] = {
        ...updatedCurrentBlock,
        id: currentBlock.id,
      };

      // Remove empty blocks
      const filteredBlocks = newBlocks.filter(block => block.words.length > 0);

      updateTranscriptBlocks(filteredBlocks);
    },
    getCurrentWordIndex: () => {
      const currentTime = get().currentTime;
      const { currentBlockIndex, transcriptBlocks } = get();
      
      // Add comprehensive defensive checks at the start only
      if (currentBlockIndex === null || 
          !transcriptBlocks || 
          !transcriptBlocks.length || 
          currentBlockIndex >= transcriptBlocks.length || 
          !transcriptBlocks[currentBlockIndex] || 
          !transcriptBlocks[currentBlockIndex].words || 
          !transcriptBlocks[currentBlockIndex].words.length) {
        return -1;
      }

      // Keep the existing sophisticated word index calculation
      const currentBlock = transcriptBlocks[currentBlockIndex];
      let lastValidIndex = -1;
      for (let i = 0; i < currentBlock.words.length; i++) {
        if (currentTime >= currentBlock.words[i].start) {
          lastValidIndex = i;
        } else {
          break;
        }
      }

      // Keep the existing logic for handling edge cases
      if (lastValidIndex === currentBlock.words.length - 1) {
        return lastValidIndex;
      }

      if (lastValidIndex >= 0 && lastValidIndex < currentBlock.words.length - 1) {
        const currentWord = currentBlock.words[lastValidIndex];
        const nextWord = currentBlock.words[lastValidIndex + 1];
        if (currentTime - currentWord.end < nextWord.start - currentTime) {
          return lastValidIndex;
        } else {
          return lastValidIndex + 1;
        }
      }

      return lastValidIndex;
    },
    getCurrentBlockIndex: () => get().currentBlockIndex, // doing this to avoid circular dependency
    getSelectedBlockIndex: () => get().selectedBlockIndex, // doing this to avoid circular dependency
    adjustFirstWord: (adjustment, handleSeek, setFlashingButton) => {
      const { transcriptBlocks } = get();

      const selectedBlockIndex = get().selectedBlockIndex;
      if (selectedBlockIndex === null) return;

      const newBlocks = [...transcriptBlocks];
      const selectedBlock = newBlocks[selectedBlockIndex];
      const firstWord = selectedBlock.words[0];
      const newStart = Math.max(0, firstWord.start + adjustment / 1000);

      if (newStart >= firstWord.end) {
        setFlashingButton('firstWordForward');
        setTimeout(() => setFlashingButton(null), 500);
        return;
      }

      if (selectedBlockIndex > 0) {
        const prevBlock = newBlocks[selectedBlockIndex - 1];
        const lastWordOfPrevBlock = prevBlock.words[prevBlock.words.length - 1];
        if (newStart <= lastWordOfPrevBlock.end) {
          setFlashingButton('firstWordBackward');
          setTimeout(() => setFlashingButton(null), 500);
          return;
        }
      }

      firstWord.start = newStart;
      selectedBlock.start = newStart;
      handleSeek(newStart);

      setTranscriptBlocks(newBlocks);
    },
    adjustLastWord: (adjustment, handleSeek, setFlashingButton) => {
      const { transcriptBlocks } = get();
      const selectedBlockIndex = get().selectedBlockIndex;
      if (selectedBlockIndex === null) return;

      const newBlocks = [...transcriptBlocks];
      const selectedBlock = newBlocks[selectedBlockIndex];
      const lastWord = selectedBlock.words[selectedBlock.words.length - 1];
      const newEnd = lastWord.end + adjustment / 1000;

      if (newEnd <= lastWord.start) {
        setFlashingButton('lastWordBackward');
        setTimeout(() => setFlashingButton(null), 500); // switch to using a tailwind class.
        return;
      }

      if (selectedBlockIndex < newBlocks.length - 1) {
        const nextBlock = newBlocks[selectedBlockIndex + 1];
        const firstWordOfNextBlock = nextBlock.words[0];
        if (newEnd >= firstWordOfNextBlock.start) {
          setFlashingButton('lastWordForward');
          setTimeout(() => setFlashingButton(null), 500);
          return;
        }
      }

      lastWord.end = newEnd;
      selectedBlock.end = newEnd;
      handleSeek(newEnd);

      setTranscriptBlocks(newBlocks);
    },
    getActiveWordIndex: () => {
      const currentTime = get().currentTime;
      const { currentBlockIndex, transcriptBlocks } = get();
      
      // Add defensive checks
      if (currentBlockIndex === null || 
          !transcriptBlocks || 
          transcriptBlocks.length === 0) return -1;
      const currentBlock = transcriptBlocks[currentBlockIndex];
      const words = currentBlock.words;

      const blockStart = words[0].start;
      const blockEnd = words[words.length - 1].end;
      if (currentTime < blockStart || currentTime >= blockEnd) {
        return -1;
      }

      return words.findIndex((word, index) => {
        if (index === words.length - 1) {
          return currentTime >= word.start && currentTime < blockEnd;
        }
        return currentTime >= word.start && currentTime < words[index + 1].start;
      });
    },
    clearSelection: () => set({ 
      selectedWordsInBlock: {},
      selectionAnchorIndex: null,
      isShiftSelecting: false
    }),
    isolateSelectedWords: () => {
      const { transcriptBlocks } = get();
      const currentBlockIndex = get().currentBlockIndex;
      const currentBlock = transcriptBlocks[currentBlockIndex];
      const selectedWords = get().selectedWordsInBlock[currentBlockIndex];

      if (!selectedWords || selectedWords.length === 0) {
        console.warn('No words selected for isolation.');
        return;
      }

      const createBlock = (words, isRejected = false) => ({
        words,
        start: words[0]?.start || 0,
        end: words[words.length - 1]?.end || 0,
        speaker: currentBlock.speaker,
        content: words.map(word => word.word).join(' '),
        isRejected,
      });

      const firstSelectedWordIndex = selectedWords[0];
      const lastSelectedWordIndex = selectedWords[selectedWords.length - 1];

      // If all words are selected, just keep the block as is
      if (firstSelectedWordIndex === 0 && lastSelectedWordIndex === currentBlock.words.length - 1) {
        return;
      }

      const newBlocks = [...transcriptBlocks];

      // Create the isolated (non-rejected) block with selected words
      const isolatedBlock = createBlock(currentBlock.words.slice(firstSelectedWordIndex, lastSelectedWordIndex + 1), false);

      // Only create rejected blocks if there are words before/after the selection
      const beforeBlocks = firstSelectedWordIndex > 0 
        ? [createBlock(currentBlock.words.slice(0, firstSelectedWordIndex), true)]
        : [];
        
      const afterBlocks = lastSelectedWordIndex < currentBlock.words.length - 1
        ? [createBlock(currentBlock.words.slice(lastSelectedWordIndex + 1), true)]
        : [];

      // Replace the current block with the new blocks
      newBlocks.splice(
        currentBlockIndex, 
        1,
        ...beforeBlocks,
        { ...isolatedBlock, id: uuidv4() },
        ...afterBlocks
      );

      // Remove any null blocks (in case there were no words before or after the selection)
      const filteredBlocks = newBlocks.filter(block => block !== null && block.words.length > 0);

      // Sort blocks to ensure rejected blocks are at the end
      filteredBlocks.sort((a, b) => a.isRejected - b.isRejected);

      set({ transcriptBlocks: filteredBlocks });
      get().setTranscriptBlocks(filteredBlocks);
    },
    splitBlockAndRejectLeft: (currentWordIndex) => {
      const { transcriptBlocks, currentBlockIndex, currentTime } = get();
      const currentBlock = transcriptBlocks[currentBlockIndex];
      let updatedBlocks;

      console.log('Before split - currentBlockIndex:', currentBlockIndex, 'currentWordIndex:', currentWordIndex);

      if (currentWordIndex <= 0) {
        // If the current word is the first word, reject the entire block
        updatedBlocks = transcriptBlocks.map((block, index) =>
          index === currentBlockIndex ? { ...block, isRejected: true } : block
        );
      } else {
        // Split the block and reject the left part
        const leftWords = currentBlock.words.slice(0, currentWordIndex);
        const rightWords = currentBlock.words.slice(currentWordIndex);

        const newBlocks = [
          { ...currentBlock, words: rightWords, id: uuidv4() }, // Non-rejected block first
          { ...currentBlock, words: leftWords, isRejected: true, id: uuidv4() } // Rejected block second
        ];

        updatedBlocks = [
          ...transcriptBlocks.slice(0, currentBlockIndex),
          ...newBlocks,
          ...transcriptBlocks.slice(currentBlockIndex + 1)
        ];
      }

      // After split, seek to maintain current position
      handleSeekInternal(currentTime);
      
      // Stay on currentBlockIndex since the non-rejected block will remain there after sorting
      const targetIndex = currentBlockIndex;
      console.log('Setting target index:', targetIndex);
      
      set({ 
        currentBlockIndex: targetIndex,
        selectedBlockIndex: targetIndex
      });
      
      get().setTranscriptBlocks(updatedBlocks);
    },

    splitBlockAndRejectRight: (currentWordIndex) => {
      const { transcriptBlocks, currentBlockIndex, currentTime } = get();
      const currentBlock = transcriptBlocks[currentBlockIndex];
      let updatedBlocks;

      if (currentWordIndex >= currentBlock.words.length - 1) {
        // If the current word is the last word, reject the entire block
        updatedBlocks = transcriptBlocks.map((block, index) =>
          index === currentBlockIndex ? { ...block, isRejected: true } : block
        );
      } else {
        // Split the block and reject the right part
        const leftWords = currentBlock.words.slice(0, currentWordIndex + 1);
        const rightWords = currentBlock.words.slice(currentWordIndex + 1);

        const newBlocks = [
          { ...currentBlock, words: leftWords, id: uuidv4() },
          { ...currentBlock, words: rightWords, isRejected: true, id: uuidv4() }
        ];

        updatedBlocks = [
          ...transcriptBlocks.slice(0, currentBlockIndex),
          ...newBlocks,
          ...transcriptBlocks.slice(currentBlockIndex + 1)
        ];
      }

      // After split, seek to maintain current position
      handleSeekInternal(currentTime);
      
      set({ 
        currentBlockIndex: currentBlockIndex,
        selectedBlockIndex: currentBlockIndex
      });
      
      get().setTranscriptBlocks(updatedBlocks);
    },
    includeRejectedBlocksInJSON: false,
    setIncludeRejectedBlocksInJSON: (value) => set({ includeRejectedBlocksInJSON: value }),
    getBlocksForJSON: () => {
      const { transcriptBlocks, includeRejectedBlocksInJSON } = get();
      
      if (includeRejectedBlocksInJSON) {
        return transcriptBlocks;
      }
      
      // Filter out rejected blocks if not including them
      return transcriptBlocks.filter(block => !block.isRejected);
    },
    history: [],
    currentHistoryIndex: -1,
    isDirty: false,

    // Instead of auto-save, implement manual save with clear history points
    saveProject: async () => {
      const currentState = {
        transcriptBlocks: get().transcriptBlocks,
        // ... other state
      };
      
      // Add to history
      set(state => ({
        history: [...state.history.slice(0, state.currentHistoryIndex + 1), currentState],
        currentHistoryIndex: state.currentHistoryIndex + 1,
        isDirty: false
      }));

      // Save to storage
      await ProjectStateService.save(currentState);
    },

    // Future project loading implementation
    /*
      // Future project loading implementation
      loadProject: async (projectData) => {
        set({
          transcriptBlocks: projectData.transcriptBlocks,
          includeRejectedBlocksInJSON: projectData.includeRejectedBlocksInJSON,
          history: [projectData], // Start fresh history with loaded state
          currentHistoryIndex: 0,
          isDirty: false
        });
      },
    */

    undo: () => {
      const { currentHistoryIndex, history } = get();
      console.log('Attempting undo:', { 
        currentHistoryIndex, 
        historyLength: history.length,
        currentRejectedStates: history[currentHistoryIndex]?.transcriptBlocks?.map(b => b.isRejected),
        previousRejectedStates: history[currentHistoryIndex - 1]?.transcriptBlocks?.map(b => b.isRejected)
      });
      
      if (currentHistoryIndex > 0) {
        const previousState = history[currentHistoryIndex - 1];
        
        // Make sure we're creating new objects to avoid reference issues
        const restoredBlocks = previousState.transcriptBlocks.map(block => ({
          ...block,
          words: block.words.map(word => ({ ...word })),
          isRejected: block.isRejected
        }));

        set({
          transcriptBlocks: restoredBlocks,
          includeRejectedBlocksInJSON: previousState.includeRejectedBlocksInJSON,
          currentHistoryIndex: currentHistoryIndex - 1,
          isDirty: true
        });

        console.log('After undo:', {
          newHistoryIndex: currentHistoryIndex - 1,
          restoredRejectedStates: restoredBlocks.map(b => b.isRejected)
        });
      }
    },

    redo: () => {
      const { currentHistoryIndex, history } = get();
      console.log('Attempting redo:', { currentHistoryIndex, historyLength: history.length });
      
      if (currentHistoryIndex < history.length - 1) {
        const nextState = history[currentHistoryIndex + 1];
        set({
          transcriptBlocks: nextState.transcriptBlocks,
          includeRejectedBlocksInJSON: nextState.includeRejectedBlocksInJSON,
          currentHistoryIndex: currentHistoryIndex + 1,
          isDirty: true
        });
      }
    },

    // Block modifications just mark as dirty without auto-saving
    handleRejectBlock: (blockIndex) => {
      const blocks = get().transcriptBlocks.map((block, index) => {
        if (index === blockIndex) {
          return {
            ...block,
            words: block.words.map(word => ({ ...word })),
            isRejected: !block.isRejected
          };
        }
        return block;
      });
      updateTranscriptBlocks(blocks);
    },

    // Add these methods to the store
    canUndo: () => {
      const { currentHistoryIndex } = get();
      return currentHistoryIndex > 0;
    },
    
    canRedo: () => {
      const { currentHistoryIndex, history } = get();
      return currentHistoryIndex < history.length - 1;
    },

    revertToLastSave: () => {
      const savedState = ProjectStateService.getCurrentState();
      if (!savedState) {
        console.warn('No saved state to revert to');
        return;
      }

      // Create new state from saved state
      const revertedBlocks = savedState.decisions.blocks.map(block => ({
        ...block,
        words: block.words.map(word => ({ ...word })),
        isRejected: block.isRejected,
        id: block.id
      }));

      // Add this reversion to history
      updateTranscriptBlocks(revertedBlocks);
      set({
        includeRejectedBlocksInJSON: savedState.decisions.settings?.includeRejectedBlocksInJSON ?? false,
        isDirty: false,
        history: [{ transcriptBlocks: revertedBlocks }],
        currentHistoryIndex: 0
      });

      console.log('Reverted to last saved state');
    },
    addExtractedBlock: (newBlock) => {
      const blocks = [...get().transcriptBlocks];
      blocks.push(newBlock);
      blocks.sort((a, b) => a.start - b.start);
      updateTranscriptBlocks(blocks);
    },

    playWordBoundaries: (start, end) => {
      console.log('playWordBoundaries called with:', { start, end });
      const { videoPlayerRef } = get();

      if (!videoPlayerRef?.current) {
        console.log('No video player ref');
        return;
      }

      // Clear any existing interval first
      if (currentPlaybackInterval) {
        clearInterval(currentPlaybackInterval);
        currentPlaybackInterval = null;
      }

      // Always pause first
      videoPlayerRef.current.pause();
      set({ isPlaying: false });

      // Use setTimeout to ensure pause has taken effect
      setTimeout(() => {
        // Seek to start
        handleSeekInternal(start);

        // Start new interval
        currentPlaybackInterval = setInterval(() => {
          const currentTime = videoPlayerRef.current.getCurrentTime();
          if (currentTime >= end) {
            videoPlayerRef.current.pause();
            set({ isPlaying: false });
            clearInterval(currentPlaybackInterval);
            currentPlaybackInterval = null;
            
            // Seek back to start of word
            handleSeekInternal(start);
          }
        }, 10);

        // Start playback directly
        videoPlayerRef.current.play();
        set({ isPlaying: true });
      }, 50);
    },

    // Helper method specifically for playing current word
    playCurrentWord: () => {
      console.log('playCurrentWord called');
      const { selectedBlockIndex, transcriptBlocks, currentBlockIndex, getCurrentWordIndex } = get();
      
      // Use the same getCurrentWordIndex function that works in the ActionBar
      const currentWordIndex = getCurrentWordIndex();
      
      console.log('Current state:', { 
        selectedBlockIndex, 
        currentBlockIndex,
        currentWordIndex,
        hasTranscriptBlocks: !!transcriptBlocks,
        blocksLength: transcriptBlocks?.length
      });

      if (currentWordIndex === -1 || !transcriptBlocks?.[currentBlockIndex]) {
        console.log('No valid word found at current time');
        return;
      }
      
      const currentWord = transcriptBlocks[currentBlockIndex].words[currentWordIndex];
      console.log('Found word:', currentWord);

      if (!currentWord) {
        console.log('No current word found');
        return;
      }

      console.log('Playing word:', { 
        start: currentWord.start, 
        end: currentWord.end,
        word: currentWord.word 
      });
      get().playWordBoundaries(currentWord.start, currentWord.end);
    },
    lastUpdateTimestamp: Date.now(),

    // Add to store methods
    rejectAllBlocksBefore: (currentIndex) => {
      console.log('Rejecting all blocks before index:', currentIndex);
      
      const { transcriptBlocks } = get();
      const currentTime = transcriptBlocks[currentIndex]?.start || 0;
      
      // Create updated blocks
      const updatedBlocks = transcriptBlocks.map((block, index) => ({
        ...block,
        isRejected: block.isRejected || index < currentIndex
      }));

      // Use a promise to ensure proper sequencing
      return new Promise(resolve => {
        // First update the blocks
        updateTranscriptBlocks(updatedBlocks);

        // Wait for next tick to ensure blocks are updated
        requestAnimationFrame(() => {
          // Find the new index of the block with our target time
          const newBlocks = get().transcriptBlocks;
          const targetBlock = newBlocks.find(block => 
            !block.isRejected && Math.abs(block.start - currentTime) < 0.1
          );

          if (targetBlock) {
            const newIndex = newBlocks.indexOf(targetBlock);
            console.log('Found new block index:', newIndex, 'for time:', currentTime);

            // Update selection and current block
            get().setSelectedBlockIndex(newIndex);
            get().setCurrentBlockIndex(newIndex);

            // Then handle scrolling
            requestAnimationFrame(() => {
              const blockElement = document.querySelector(`[data-block-index="${newIndex}"]`);
              const transcriptContainer = document.querySelector('.transcript-manager');
              
              if (blockElement && transcriptContainer) {
                const containerRect = transcriptContainer.getBoundingClientRect();
                const elementRect = blockElement.getBoundingClientRect();
                
                const relativeTop = elementRect.top - containerRect.top;
                const centerOffset = (containerRect.height - elementRect.height) / 2;
                const targetScroll = transcriptContainer.scrollTop + relativeTop - centerOffset;
                
                transcriptContainer.scrollTo({
                  top: targetScroll,
                  behavior: 'smooth'
                });
              }
              resolve();
            });
          } else {
            resolve();
          }
        });
      });
    },

    // Update the key handler to use async/await
    handleShiftJ: async () => {
      const { currentBlockIndex, rejectAllBlocksBefore } = get();
      await rejectAllBlocksBefore(currentBlockIndex);
    },

    // Add to store methods
    rejectAllBlocksAfter: (currentIndex) => {
      console.log('Rejecting all blocks after index:', currentIndex);
      
      const { transcriptBlocks } = get();
      const currentTime = transcriptBlocks[currentIndex]?.start || 0;
      
      // Do the rejection
      const updatedBlocks = transcriptBlocks.map((block, index) => ({
        ...block,
        isRejected: block.isRejected || (index > currentIndex && !block.isRejected)
      }));

      // Update blocks
      updateTranscriptBlocks(updatedBlocks);
      
      // No need to scroll for Shift+L since we stay at current position
      // but we should still update selection
      get().setSelectedBlockIndex(currentIndex);
      get().setCurrentBlockIndex(currentIndex);
    }
  };
};

export default useTranscriptBlocksStore;