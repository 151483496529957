import React, { useCallback, useEffect, useMemo, memo, useRef } from 'react';
import useStore from '../../store';
import { Button } from '@nextui-org/react';
import { WordSelectionArea } from '..';
import { BiX as XIcon, BiPlus as PlusIcon } from "react-icons/bi";
import { useBlockHandlers, useBlockSplit } from '../../hooks';

export const TranscriptBlock = memo(({
  content,
  metadata,
  words,
  onSeek,
  currentTime,
  dragHandleProps,
  isCurrentBlock,
  onClick,
  isRejected,
  onReject,
  isSelected,
  blockIndex,
  onWordNavigation,
  onBlockSelect,
  onSelectedWordsChange,
  onBlockNavigation,
  searchTerm,
  currentLanguage,
}) => {
  const blockRef = useRef(null);

  const { handleBlockSelection, handleBlockRejection } = useBlockHandlers(onSeek, currentTime);
  const { selectedWordsInBlock, isPlaying } = useStore();
  const { handleSplit, handleSplitAndReject } = useBlockSplit(onSeek);

  const currentWordIndex = words.findIndex(word => 
    currentTime >= word.start && currentTime <= word.end
  );

  const handleClick = useCallback((event) => {
    if (isPlaying) return;
    
    // Check if we clicked directly on a word
    const isWordClick = event.target.closest('.word-box');
    
    // Check if there's an active selection
    const hasSelection = selectedWordsInBlock[blockIndex]?.length > 0;
    
    if (isWordClick || hasSelection) {
      // Let the WordSelectionArea handle it
      return;
    }
    
    // Only handle block selection if we didn't click a word and there's no selection
    handleBlockSelection(blockIndex, words[0].start, true);
    onClick(event);
  }, [handleBlockSelection, blockIndex, words, onClick, isPlaying, selectedWordsInBlock]);

  useEffect(() => {
    const handleGlobalClick = (event) => {
      if (isPlaying) return;
      if (!event.target.closest('.transcript-block')) {
        onSelectedWordsChange([]);
      }
    };

    document.addEventListener('click', handleGlobalClick);
    return () => {
      document.removeEventListener('click', handleGlobalClick);
    };
  }, [onSelectedWordsChange, isPlaying]);

  useEffect(() => {
    if (isCurrentBlock) {
      blockRef.current?.focus({ preventScroll: true });
    }
  }, [isCurrentBlock]);

  useEffect(() => {
    if (currentWordIndex !== -1) {
      const wordElement = document.querySelector(`[data-block-index="${blockIndex}"] .word:nth-child(${currentWordIndex + 1})`);
      if (wordElement) {
        setTimeout(() => {
          wordElement.scrollIntoView({
            behavior: 'smooth',
            block: 'center'
          });
        }, 100);
      }
    }
  }, [currentWordIndex, currentTime, blockIndex]);

  const items = useMemo(() => {
    return words;
  }, [words]);

  const renderWord = (word, index) => {
    if (word.type === 'silence') {
      return (
        <span key={`silence-${index}`} className="text-gray-400">
          {word.renderedContent || '[...]'}
        </span>
      );
    }

    const isMatch = searchTerm && word.word.toLowerCase().includes(searchTerm.toLowerCase());
    
    const displayText = currentLanguage !== 'english' && word.translations?.[currentLanguage]
      ? word.translations[currentLanguage]
      : word.word;
    
    return (
      <span 
        key={`word-${index}`} 
        className={`word ${isMatch ? 'bg-yellow-500/30 rounded px-0.5' : ''}`}
      >
        {displayText}
      </span>
    );
  };

  return (
    <div className="group flex w-full">
      <div
        ref={blockRef}
        tabIndex="0"
        data-block-index={blockIndex}
        className={`transcript-block flex rounded overflow-visible w-full relative
          focus:outline-none
          ${isCurrentBlock || document.activeElement === blockRef.current 
            ? 'current-block ring ring-inset ring-gray-500/10'
            : ''}
          ${isRejected
            ? 'rejected-block bg-red-600/5 text-gray-500'
            : 'bg-transparent'}
          ${isSelected ? 'selected-block' : ''}`}
        onFocus={() => {
          if (!isCurrentBlock) {
            onClick?.();
            handleBlockSelection(blockIndex, null, false);
          }
        }}
        onClick={handleClick}
      >
        <div className="flex-grow">
          <WordSelectionArea
            words={words}
            currentTime={currentTime}
            onSelectedWordsChange={onSelectedWordsChange}
            onSeek={onSeek}
            blockIndex={blockIndex}
            onBlockSelect={onBlockSelect}
            onBlockNavigation={onBlockNavigation}
          />
        </div>
        <button
          className={`reject-button opacity-0 group-hover:opacity-100 transition-none absolute right-3 top-1/2 transform -translate-y-1/2 p-2
            ${isRejected ? 'text-gray-500 group-hover:text-green-600 hover:text-green-700' : 'text-gray-500 hover:text-red-500'}`}
          onClick={(e) => {
            if (isPlaying) return;
            e.stopPropagation();
            handleBlockRejection(blockIndex);
          }}
        >
          {isRejected ? <PlusIcon className='text-2xl' /> : <XIcon className='text-2xl' />}
        </button>
      </div>
    </div>
  );
});