import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import useStore from '../../store';
import { useTranscriptProcessor } from '../../hooks';
import { FileUploadService } from '../../services';
import ProjectStateService from '../../services/ProjectStateService';
import WebhookService from '../../services/WebhookService';
import { calculateFileHash } from '../../utils/hashUtils';

/**
 * Custom hook for handling file uploads.
 * @returns {Object} - An object containing the upload state and handlers.
 */
export function useFileUpload() {
  const navigate = useNavigate();
  const {
    isUploading,
    uploadError,
    setUploadError,
    setIsUploading,
    setTranscriptData,
    videoSHA,
    setVideoSHA,
    setProjectName,
    setProjectFileName,
    transcriptBlocks
  } = useStore();

  const apiUrl = '/api';

  const setVideoFile = useStore(state => state.setVideoFile);
  // const setVideoProcessed = useStore(state => state.setVideoProcessed);
  const setVideoFileName = useStore(state => state.setVideoFileName);
  // const videoFile = useStore(state => state.videoFile);

  const createAndSaveProject = async (hash, fileName) => {
    console.log('createAndSaveProject called with:', { hash, fileName });
    
    // Check if there are any existing projects for this video
    const existingProjects = await ProjectStateService.listProjectsForSHA(hash);
    console.log('Checking for existing projects:', existingProjects);

    // Only auto-save if there are no existing projects
    if (!existingProjects || existingProjects.length === 0) {
      console.log('No existing projects found, auto-saving...');
      
      // Create project name from video filename (without extension)
      const baseName = fileName.replace(/\.[^/.]+$/, '');
      const projectName = `${baseName}`;
      const fullProjectFileName = `${projectName}.json`;
      
      // Prepare project state
      const projectState = {
        videoSHA: hash,
        videoFileName: fileName,
        transcriptBlocks: transcriptBlocks || [],
        projectName,
        projectFileName: fullProjectFileName,
        isInitialSave: true,
        decisions: {
          blocks: transcriptBlocks || [],
          settings: {
            breakAtPunctuation: false,
            maxCharPerSection: 500,
            breakOnSpeakerChange: false,
            rejectLongSilences: false,
            longSilenceThreshold: 1.0,
          }
        },
        metadata: {
          dateCreated: new Date().toISOString(),
          lastModified: new Date().toISOString(),
          version: '1.0.0',
          source: 'upload'
        }
      };

      console.log('Auto-saving project with metadata:', { 
        projectName, 
        dateCreated: projectState.metadata.dateCreated,
        lastModified: projectState.metadata.lastModified
      });
      
      // Save the project
      await ProjectStateService.saveToBackend(hash, projectName, projectState);
      console.log('Auto-save completed successfully');
      
      // Update local state
      setProjectName(projectName);
      setProjectFileName(fullProjectFileName);
      
      // Dispatch event to refresh project list
      window.dispatchEvent(new CustomEvent('projectSaved'));
      
      // Send notification to Zapier if the filename has an ID code
      try {
        console.log('🔔 WEBHOOK: Attempting to send notification for newly saved project:', { 
          hash, 
          fileName 
        });
        await WebhookService.notifyZapier({
          filename: fileName,
          fileHash: hash,
          projectName
        });
      } catch (webhookError) {
        console.error('🔔 WEBHOOK: Failed to send notification:', webhookError);
        // We don't want to fail the project creation if the webhook fails
      }
    } else {
      console.log('Existing projects found, skipping auto-save');
    }
  };

  const handleFileUpload = useCallback(async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    if (setVideoFileName && typeof setVideoFileName === 'function') {
      setVideoFileName(file.name);
    }

    setIsUploading(true);
    setUploadError(null);

    if (setVideoFile && typeof setVideoFile === 'function') {
      setVideoFile(URL.createObjectURL(file));
    }

    try {
      const hash = await calculateFileHash(file);
      setVideoSHA(hash);
      console.log('Calculated file hash:', hash);

      const fileExists = await FileUploadService.checkFileExists(apiUrl, hash);
      if (fileExists) {
        try {
          console.log('🔔 WEBHOOK: File exists on server, fetching transcript:', {
            hash,
            fileName: file.name
          });
          const data = await FileUploadService.fetchTranscript(apiUrl, hash);
          setTranscriptData(data);
          
          // Auto-create project with notification since it's a first load
          const existingProjects = await ProjectStateService.listProjectsForSHA(hash);
          if (!existingProjects || existingProjects.length === 0) {
            console.log('🔔 WEBHOOK: No projects for existing file, will auto-save with proper filename:', file.name);
            // Make sure to store the actual filename for later use in auto-save
            setVideoFileName(file.name);
          }
          
          // Set project name and filename for the route, but don't create a project
          setProjectName('_new');
          setProjectFileName('_new');
          // Navigate to project route with special _new project name
          navigate(`/project/${hash}/_new`);
        } catch (fetchError) {
          console.warn("Failed to fetch existing transcript, uploading file instead:", fetchError);
        }
      } else {
        console.log('File not found on server. Uploading file...');
        const uploadResponse = await FileUploadService.uploadFile(file, apiUrl, hash);
        setTranscriptData(uploadResponse.transcript);
        // Set project name and filename for the route, but don't create a project
        setProjectName('_new');
        setProjectFileName('_new');
        // Navigate to project route with special _new project name
        navigate(`/project/${hash}/_new`);
      }
    } catch (error) {
      setUploadError(error.message);
      console.error("File upload error:", error);
    } finally {
      setIsUploading(false);
    }
  }, [apiUrl, setVideoFile, setVideoFileName, setIsUploading, setUploadError, setTranscriptData, setVideoSHA, setProjectName, setProjectFileName, navigate]);

  const handleTranscript = useTranscriptProcessor();

  const generateTranscript = useCallback(async (handleTranscript, setVideoProcessed) => {
    const { transcriptData } = useStore.getState();
    
    if (!transcriptData) {
      setUploadError('No transcript data available. Please upload a file first.');
      return;
    }

    try {
      // Pass the original transcript data to be reprocessed with current settings
      handleTranscript(transcriptData);
      setVideoProcessed(true);
    } catch (error) {
      setUploadError(error.message);
      console.error("Transcript generation error:", error);
    }
  }, [handleTranscript, setUploadError]);

  return {
    isUploading,
    uploadError,
    handleFileUpload,
    generateTranscript,
    hasTranscriptData: useStore(state => state.hasTranscriptData),
  };
}
