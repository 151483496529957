import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useStore from '../../store';

export const SpookyCutterLogo = () => {
  const [isHovered, setIsHovered] = useState(false);
  const navigate = useNavigate();
  const setVideoFile = useStore(state => state.setVideoFile);

  const handleLogoClick = (e) => {
    e.preventDefault();
    setVideoFile(null); // Clear video file when going home
    navigate('/');
  };

  return (
    <a href="/" onClick={handleLogoClick} className="no-underline">
      <div
        className={`inline-flex w-78 relative ${isHovered ? 'animate-pulse' : ''}`}
        id="spooky-cutter-logo"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <h1 className="font-bold text-2xl text-inherit">
          Spooky Cutter
        </h1>

        <div className={`w-8 h-8 absolute top-0 left-0 transition-all duration-500 ease-in-out ${
          isHovered ? 'opacity-100 translate-x-44' : 'opacity-0 -translate-x-20 delay-[2000ms]'
        }`}>
          <img
            className={`w-8 h-8 ${isHovered ? 'animate-[bounce_1s_ease-in-out_2]' : ''}`}
            src="/8-bit-ghost.png"
            alt="Spooky Cutter Logo"
          />
        </div>
      </div>
    </a>
  );
};

export default SpookyCutterLogo;