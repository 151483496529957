import React, { useState, useRef, useEffect, useImperativeHandle } from 'react';
import ReactPlayer from 'react-player';
import { FaPlay, FaPause, FaVolumeMute, FaVolumeUp, FaExpand, FaCompress, FaExternalLinkAlt } from 'react-icons/fa';
import { formatTime } from '../../utils/timeUtils';
import './VideoPlayer.css';
import { useSequentialPlaybackStore } from '../../store/sequentialPlaybackStore';
import useStore from '../../store';
import VideoLoadingOverlay from '../VideoLoadingOverlay';

const getMediaType = (src) => {
  const url = typeof src === 'string' ? src : src?.url;
  if (!url) return 'unknown';
  
  const extension = url.split('.').pop().toLowerCase();
  if (['mp4'].includes(extension)) return 'video/mp4';
  if (['mov'].includes(extension)) return 'video/quicktime';
  if (['mp3'].includes(extension)) return 'audio/mp3';
  if (['wav'].includes(extension)) return 'audio/wav';
  return 'unknown';
};

export const VideoPlayer = ({
  videoPlayerRef,
  src,
  onSeek,
  onPlayPause,
  onTimeUpdate,
  currentBlock,
  isPlaying,
  isSeeking,
  handleStoppingOnRejectedBlock,
  initialLoadingOperation
}) => {
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [volume, setVolume] = useState(1);
  const [isMuted, setIsMuted] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const containerRef = useRef(null);
  const [isPiP, setIsPiP] = useState(false);
  const playerRef = useRef(null);
  const handleBlockEnd = useSequentialPlaybackStore(state => state.handleBlockEnd);
  const transcriptBlocks = useStore(state => state.transcriptBlocks);
  const currentBlockIndex = useStore(state => state.currentBlockIndex);
  const setCurrentBlockIndex = useStore(state => state.setCurrentBlockIndex);
  const setSelectedBlockIndex = useStore(state => state.setSelectedBlockIndex);
  const [videoBlob, setVideoBlob] = useState(null);
  const [mediaType, setMediaType] = useState(null);
  const [isDragging, setIsDragging] = useState(false);
  const wasPlayingRef = useRef(false);
  const progressBarRef = useRef(null);
  const [dragPosition, setDragPosition] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [loadingProgress, setLoadingProgress] = useState(0);
  const [isCached, setIsCached] = useState(false);
  const [loadingOperation, setLoadingOperation] = useState(initialLoadingOperation || "checking");

  useImperativeHandle(videoPlayerRef, () => ({
    seekTo: (seconds) => {
      if (playerRef.current) {
        playerRef.current.seekTo(seconds ?? 0, 'seconds');
      }
    },
    play: () => {
      if (playerRef.current) {
        playerRef.current.getInternalPlayer().play();
      }
    },
    pause: () => {
      if (playerRef.current) {
        playerRef.current.getInternalPlayer().pause();
      }
    },
    getCurrentTime: () => playerRef.current ? playerRef.current.getCurrentTime() : 0,
  }));

  const handleProgress = (state) => {
    setCurrentTime(state.playedSeconds);
    onTimeUpdate(state.playedSeconds);
    
    // Find the block containing the current time
    const currentTime = state.playedSeconds;
    const blockIndex = transcriptBlocks.findIndex(block => {
      const blockStart = block.words[0].start;
      const blockEnd = block.words[block.words.length - 1].end;
      return currentTime >= blockStart && currentTime < blockEnd;
    });

    // Update block selection if we found a valid block and it's different from current
    if (blockIndex !== -1 && blockIndex !== currentBlockIndex) {
      setCurrentBlockIndex(blockIndex);
      setSelectedBlockIndex(blockIndex);
    }
    
    if (isPlaying && currentBlock && state.playedSeconds >= currentBlock.end) {
      const nextBlock = transcriptBlocks[currentBlockIndex + 1];
      handleBlockEnd(
        currentBlock, 
        nextBlock,
        onSeek,
        () => setCurrentBlockIndex(currentBlockIndex + 1),
        () => setSelectedBlockIndex(currentBlockIndex + 1),
        onPlayPause,
        transcriptBlocks
      );
    }
  };

  const handleDuration = (duration) => {
    console.log('Video duration set:', duration);
    setDuration(duration);
  };

  const handleSeekChange = (e) => {
    const rect = progressBarRef.current.getBoundingClientRect();
    const x = Math.min(Math.max(0, e.clientX - rect.left), rect.width);
    const percentage = x / rect.width;
    const seekTime = percentage * duration;
    
    if (playerRef.current) {
      playerRef.current.seekTo(seekTime, 'seconds');
      setCurrentTime(seekTime);
      onSeek?.(seekTime);
    }
  };

  const handleMouseDown = (e) => {
    // Prevent default drag behavior
    e.preventDefault();
    
    const rect = progressBarRef.current.getBoundingClientRect();
    const clickX = e.clientX - rect.left;
    const currentX = (currentTime / duration) * rect.width;
    
    // Allow dragging if clicking within 12px of playhead
    if (Math.abs(clickX - currentX) <= 12) {
      wasPlayingRef.current = isPlaying;
      if (isPlaying) {
        onPlayPause(false);
      }
      setIsDragging(true);

      const handleMouseMove = (e) => {
        e.preventDefault();
        if (isDragging) {
          handleSeekChange(e);
        }
      };

      const handleMouseUp = (e) => {
        e.preventDefault();
        setIsDragging(false);
        document.removeEventListener('mousemove', handleMouseMove);
        document.removeEventListener('mouseup', handleMouseUp);
      };

      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);
    } else {
      // Direct seek if clicking away from playhead
      handleSeekChange(e);
    }
  };

  const toggleFullscreen = async () => {
    if (!document.fullscreenElement) {
      try {
        await containerRef.current.requestFullscreen();
        setIsFullscreen(true);
      } catch (err) {
        console.error('Error attempting to enable fullscreen:', err);
      }
    } else {
      await document.exitFullscreen();
      setIsFullscreen(false);
    }
  };

  const togglePiP = async () => {
    const videoElement = playerRef.current?.getInternalPlayer();
    if (!videoElement) return;

    try {
      if (document.pictureInPictureElement) {
        await document.exitPictureInPicture();
        setIsPiP(false);
      } else if (document.pictureInPictureEnabled) {
        await videoElement.requestPictureInPicture();
        setIsPiP(true);
      }
    } catch (err) {
      console.error('Error toggling Picture-in-Picture:', err);
    }
  };

  useEffect(() => {
    const handleFullscreenChange = () => {
      setIsFullscreen(!!document.fullscreenElement);
    };

    document.addEventListener('fullscreenchange', handleFullscreenChange);
    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
    };
  }, []);

  useEffect(() => {
    const loadVideo = async () => {
      try {
        setIsLoading(true);
        setLoadingProgress(0);
        setLoadingOperation("checking");

        if (typeof src === 'string' ? src.includes('/api/project/') : src.url?.includes('/api/project/')) {
          const token = localStorage.getItem('authToken');
          const videoUrl = typeof src === 'string' ? src : src.url;
          
          // Create a URL with the auth token
          const urlWithAuth = new URL(videoUrl, window.location.origin);
          urlWithAuth.searchParams.append('token', token);

          // For already cached videos or direct URLs, just set the URL directly
          if (src.isCached || typeof src === 'string') {
            setLoadingOperation("cached");
            setVideoBlob(urlWithAuth.toString());
            setLoadingProgress(100);
            setIsLoading(false);
          } else {
            // Only show progress for new video downloads
            setLoadingOperation("downloading");
            console.log(`VideoPlayer: Starting download with URL: ${urlWithAuth.toString().substring(0, 100)}...`);
            
            const response = await fetch(urlWithAuth.toString());
            
            if (!response.ok) {
              console.error(`VideoPlayer: Download failed with status ${response.status}`);
              throw new Error(`Failed to download video: ${response.statusText}`);
            }
            
            const contentLength = +response.headers.get('Content-Length');
            console.log(`VideoPlayer: Download started, total size: ${contentLength} bytes (${(contentLength / (1024 * 1024)).toFixed(2)} MB)`);
            
            const reader = response.body.getReader();
            let receivedLength = 0;
            let chunks = [];
            let lastProgressUpdate = 0;
            let lastProgressTime = Date.now();
            
            // Update progress at most every 500ms to avoid excessive re-renders
            const throttleInterval = 500;

            while(true) {
              const {done, value} = await reader.read();
              
              if (done) {
                console.log(`VideoPlayer: Download completed, received ${receivedLength} bytes`);
                break;
              }
              
              chunks.push(value);
              receivedLength += value.length;
              
              // Calculate progress as percentage
              const currentProgress = Math.round((receivedLength / contentLength) * 100);
              
              // Only update UI if progress changed significantly or enough time has passed
              const now = Date.now();
              if (
                currentProgress !== lastProgressUpdate && 
                (currentProgress - lastProgressUpdate >= 1 || now - lastProgressTime >= throttleInterval)
              ) {
                console.log(`VideoPlayer: Download progress: ${currentProgress}% (${(receivedLength / (1024 * 1024)).toFixed(2)} MB / ${(contentLength / (1024 * 1024)).toFixed(2)} MB)`);
                setLoadingProgress(currentProgress);
                lastProgressUpdate = currentProgress;
                lastProgressTime = now;
              }
            }

            // Set operation to processing when preparing the blob
            setLoadingOperation("processing");
            
            const blob = new Blob(chunks);
            setVideoBlob(URL.createObjectURL(blob));
            setLoadingProgress(100);
            setIsLoading(false);
          }
        } else {
          // Direct file handling
          setLoadingOperation("processing");
          
          // Handle various src formats
          if (src instanceof Blob || src instanceof File) {
            setVideoBlob(URL.createObjectURL(src));
          } else if (typeof src === 'object' && src.url) {
            if (src.blob) {
              setVideoBlob(URL.createObjectURL(src.blob));
            } else {
              setVideoBlob(src.url);
            }
          } else {
            setVideoBlob(src);
          }
          
          setLoadingProgress(100);
          setIsLoading(false);
        }
      } catch (error) {
        console.error('Error loading video:', error);
        setIsLoading(false);
      }
    };

    if (src) {
      loadVideo();
    }

    return () => {
      if (videoBlob && typeof videoBlob === 'string' && videoBlob.startsWith('blob:')) {
        URL.revokeObjectURL(videoBlob);
      }
    };
  }, [src]);

  useEffect(() => {
    setMediaType(getMediaType(src));
  }, [src]);

  // Update internal loading operation if parent provides it
  useEffect(() => {
    if (initialLoadingOperation) {
      setLoadingOperation(initialLoadingOperation);
    }
  }, [initialLoadingOperation]);

  return (
    <div 
      className="video-player relative" 
      ref={containerRef}
      data-is-fullscreen={isFullscreen}
      data-current-block={currentBlock?.id}
    >
      <div 
        className="relative w-full"
        onClick={() => onPlayPause(!isPlaying)}
        style={{ cursor: 'pointer' }}
      >
        {videoBlob ? (
          <ReactPlayer
            ref={playerRef}
            url={videoBlob}
            playing={isPlaying}
            controls={false}
            volume={volume}
            muted={isMuted}
            width="100%"
            height="auto"
            playsinline
            onPlay={() => onPlayPause(true)}
            onPause={() => onPlayPause(false)}
            onError={(e) => {
              console.error('ReactPlayer error:', e);
              if (e.target) {
                console.log('Video element details:', {
                  error: e.target.error,
                  readyState: e.target.readyState,
                  networkState: e.target.networkState,
                  src: e.target.src,
                  type: mediaType
                });
              }
            }}
            onProgress={handleProgress}
            onDuration={handleDuration}
            progressInterval={10}
          />
        ) : (
          <div className="aspect-video bg-default-100/50" />
        )}
      </div>
      
      <div className="video-controls-wrapper">
        <div className="custom-controls flex items-center gap-2 p-2 bg-gray-800/50">
          {/* Play/Pause - Always visible */}
          <button 
            onClick={() => onPlayPause(!isPlaying)}
            className="control-button"
            title={isPlaying ? "Pause" : "Play"}
          >
            {isPlaying ? <FaPause /> : <FaPlay />}
          </button>

          {/* Progress bar - Always visible */}
          <div 
            ref={progressBarRef}
            className="progress-bar relative"
            onMouseDown={(e) => {
              e.preventDefault();
              e.stopPropagation();
              
              wasPlayingRef.current = isPlaying;
              if (isPlaying) {
                onPlayPause(false);
              }
              
              // Set initial position and dragging state
              const rect = progressBarRef.current.getBoundingClientRect();
              const x = Math.min(Math.max(0, e.clientX - rect.left), rect.width);
              const percentage = x / rect.width;
              setDragPosition(percentage * 100);
              setIsDragging(true);

              const handleMouseMove = (e) => {
                e.preventDefault();
                e.stopPropagation();
                
                requestAnimationFrame(() => {
                  const rect = progressBarRef.current.getBoundingClientRect();
                  const x = Math.min(Math.max(0, e.clientX - rect.left), rect.width);
                  const percentage = x / rect.width;
                  setDragPosition(percentage * 100);
                  
                  // Update video time while dragging
                  const seekTime = percentage * duration;
                  if (playerRef.current) {
                    const videoElement = playerRef.current.getInternalPlayer();
                    if (videoElement) {
                      videoElement.currentTime = seekTime;
                    } else {
                      playerRef.current.seekTo(seekTime, 'seconds');
                    }
                    setCurrentTime(seekTime);
                    onSeek?.(seekTime);
                  }
                });
              };

              const handleMouseUp = (e) => {
                e.preventDefault();
                e.stopPropagation();
                
                // Final seek to ensure accuracy
                const rect = progressBarRef.current.getBoundingClientRect();
                const x = Math.min(Math.max(0, e.clientX - rect.left), rect.width);
                const percentage = x / rect.width;
                const seekTime = percentage * duration;
                
                if (playerRef.current) {
                  const videoElement = playerRef.current.getInternalPlayer();
                  if (videoElement) {
                    videoElement.currentTime = seekTime;
                  } else {
                    playerRef.current.seekTo(seekTime, 'seconds');
                  }
                  setCurrentTime(seekTime);
                  onSeek?.(seekTime);
                }
                
                setIsDragging(false);
                setDragPosition(null);
                
                if (wasPlayingRef.current) {
                  onPlayPause(true);
                }
                
                document.removeEventListener('mousemove', handleMouseMove);
                document.removeEventListener('mouseup', handleMouseUp);
              };

              document.addEventListener('mousemove', handleMouseMove);
              document.addEventListener('mouseup', handleMouseUp);
            }}
            style={{ userSelect: 'none', padding: '4px 0' }}
          >
            <div 
              className="progress-bar-fill"
              style={{ width: `${(currentTime / duration) * 100}%` }}
            />
            <div 
              className={`playhead-indicator ${isDragging ? 'dragging' : ''}`}
              style={{ 
                left: `${dragPosition !== null ? dragPosition : (currentTime / duration) * 100}%`
              }}
            />
          </div>

          {/* Time display - Truncates but doesn't wrap */}
          <div className="time-display">
            {formatTime(currentTime)} / {formatTime(duration)}
          </div>

          {/* Volume control with popup */}
          <div className="volume-control">
            <button 
              onClick={() => {
                if (isMuted) {
                  setIsMuted(false);
                } else if (volume === 0) {
                  setVolume(1);
                  setIsMuted(false);
                } else {
                  setIsMuted(true);
                }
              }}
              className="control-button"
              title={isMuted ? "Unmute" : "Mute"}
            >
              {isMuted || volume === 0 ? <FaVolumeMute /> : <FaVolumeUp />}
            </button>
            <div className="volume-popup">
              <input 
                type="range"
                min={0}
                max={1}
                step={0.1}
                value={isMuted ? 0 : volume}
                onChange={(e) => {
                  const newVolume = parseFloat(e.target.value);
                  setVolume(newVolume);
                  setIsMuted(newVolume === 0);
                }}
                className="volume-slider"
                title="Volume"
              />
            </div>
          </div>

          {/* Secondary controls */}
          <button 
            onClick={togglePiP}
            className="control-button secondary-control"
            title="Picture in Picture"
          >
            <FaExternalLinkAlt />
          </button>

          <button 
            onClick={toggleFullscreen}
            className="control-button secondary-control"
            title={isFullscreen ? "Exit Fullscreen" : "Fullscreen"}
          >
            {isFullscreen ? <FaCompress /> : <FaExpand />}
          </button>
        </div>
      </div>

      {isLoading && (
        <VideoLoadingOverlay 
          progress={loadingProgress} 
          operation={loadingOperation}
        />
      )}
    </div>
  );
};

export default VideoPlayer;
