const useSettingsStore = (set) => ({
  settingsConfirmed: false,
  setSettingsConfirmed: (settingsConfirmed) => set({ settingsConfirmed }),
  breakAtPunctuation: true,
  setBreakAtPunctuation: (breakAtPunctuation) => set({ breakAtPunctuation }),
  useMaxCharacters: false,
  setUseMaxCharacters: (useMaxCharacters) => set({ useMaxCharacters }),
  maxCharPerSection: 100,
  setMaxCharPerSection: (maxCharPerSection) => set({ maxCharPerSection }),
  breakOnLongSilences: true,
  setBreakOnLongSilences: (breakOnLongSilences) => set({ breakOnLongSilences }),
  rejectLongSilences: false,
  setRejectLongSilences: (rejectLongSilences) => set({ rejectLongSilences }),
  longSilenceThreshold: 0.1,
  setLongSilenceThreshold: (longSilenceThreshold) => set({ longSilenceThreshold }),
  breakOnSpeakerChange: false,
  setBreakOnSpeakerChange: (breakOnSpeakerChange) => set({ breakOnSpeakerChange }),
  isContextualToolbarVisible: false,
  setContextualToolbarVisible: (visible) => set({ isContextualToolbarVisible: visible }),
  toggleContextualToolbar: () =>
    set((state) => ({ isContextualToolbarVisible: !state.isContextualToolbarVisible })),
  rejectBlocksContaining: false,
  setRejectBlocksContaining: (rejectBlocksContaining) => set({ rejectBlocksContaining }),
  rejectBlocksCondition: 'contain',
  setRejectBlocksCondition: (rejectBlocksCondition) => set({ rejectBlocksCondition }),
  rejectBlocksWord: '',
  setRejectBlocksWord: (rejectBlocksWord) => set({ rejectBlocksWord }),
});

export default useSettingsStore;
