import { useCallback } from 'react';
import { preventOverlap } from '../../utils/TranscriptUtils';
import useStore from '../../store';

export function useDragAndDrop(transcriptBlocks, setTranscriptBlocks) {
  const setCurrentBlockIndex = useStore(state => state.setCurrentBlockIndex);
  const setSelectedBlockIndex = useStore(state => state.setSelectedBlockIndex);
  
  const handleDragEnd = useCallback((result) => {
    console.log('Drag end event:', {
      source: result.source,
      destination: result.destination,
      draggableId: result.draggableId
    });

    if (!result.destination) {
      console.log('No destination, drag cancelled');
      return;
    }

    const movedBlock = transcriptBlocks[result.source.index];
    console.log('Moving block:', {
      type: movedBlock.type,
      id: movedBlock.id,
      content: movedBlock.content,
      fromIndex: result.source.index,
      toIndex: result.destination.index
    });

    // Create new array and perform the move
    const newBlocks = Array.from(transcriptBlocks);
    const [removed] = newBlocks.splice(result.source.index, 1);
    newBlocks.splice(result.destination.index, 0, removed);
    
    // Only apply overlap prevention to regular blocks
    const finalBlocks = newBlocks.map(block => {
      if (block.type === 'theme-marker') {
        return block;
      }
      return {
        ...block,
        start: block.words[0]?.start || 0,
        end: block.words[block.words.length - 1]?.end || 0
      };
    });
    
    console.log('Updated blocks:', {
      totalBlocks: finalBlocks.length,
      themeMarkers: finalBlocks.filter(b => b.type === 'theme-marker').length,
      regularBlocks: finalBlocks.filter(b => b.type !== 'theme-marker').length
    });

    // Update the blocks array
    setTranscriptBlocks(finalBlocks);
    
    // Update selection to follow the moved block
    const newIndex = result.destination.index;
    setCurrentBlockIndex(newIndex);
    setSelectedBlockIndex(newIndex);
  }, [transcriptBlocks, setTranscriptBlocks, setCurrentBlockIndex, setSelectedBlockIndex]);

  return { handleDragEnd };
}

export default useDragAndDrop;