import { useEffect, useState } from 'react';
import axios from '../../utils/axios';
import ThumbnailCacheService from '../../services/ThumbnailCacheService';

const AuthImage = ({ fileHash, alt, className, onError }) => {
  const [imageSrc, setImageSrc] = useState(null);

  useEffect(() => {
    const fetchImage = async () => {
      try {
        // First check if we have it in the cache
        const cachedImage = await ThumbnailCacheService.getCachedThumbnail(fileHash);
        
        if (cachedImage) {
          // Use cached image
          console.log('AuthImage :: Using cached thumbnail for hash:', fileHash);
          const objectUrl = URL.createObjectURL(cachedImage);
          setImageSrc(objectUrl);
          return;
        }
        
        // If not in cache, fetch from API
        console.log('AuthImage :: Fetching thumbnail from API for hash:', fileHash);
        const response = await axios.get(`/project/${fileHash}/thumb`, {
          responseType: 'blob'
        });
        
        // Cache the image for future use
        await ThumbnailCacheService.cacheThumbnail(fileHash, response.data);
        
        const objectUrl = URL.createObjectURL(response.data);
        setImageSrc(objectUrl);
      } catch (error) {
        console.error('Error loading thumbnail:', {
          fileHash,
          error: error.message,
          status: error.response?.status,
          responseData: error.response?.data
        });
        if (onError) onError(error);
      }
    };

    if (fileHash) {
      fetchImage();
    }

    return () => {
      if (imageSrc) {
        URL.revokeObjectURL(imageSrc);
      }
    };
  }, [fileHash, onError]);

  if (!imageSrc) {
    return <div className={`${className} bg-default-200`} />;
  }

  return (
    <img 
      src={imageSrc} 
      alt={alt || `Thumbnail for ${fileHash}`} 
      className={`${className} object-cover`}
      onError={(e) => {
        console.error('Error displaying thumbnail:', e);
        if (onError) onError(e);
      }}
    />
  );
};

export default AuthImage;