import React, { useState } from 'react';
import { Button, Modal, ModalContent, ModalHeader, ModalBody, ModalFooter } from "@nextui-org/react";
import { LoadingSpinner } from '../LoadingSpinner/index.jsx';
import useStore from '../../store';

export const AIOrganizer = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [response, setResponse] = useState(null);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const { transcriptBlocks, setTranscriptBlocks, token } = useStore();

  const reorderBlocksByThemes = (themes, rejectedBlockIds = []) => {
    // Create a map of block IDs to their current blocks to preserve all properties
    const blockMap = transcriptBlocks.reduce((acc, block) => {
      acc[block.id] = {
        ...block,
        // Mark blocks as rejected if they're in the rejected array
        isRejected: rejectedBlockIds.includes(block.id) || block.isRejected
      };
      return acc;
    }, {});

    // Create a new array of blocks in the order specified by themes
    const orderedBlocks = [];
    
    // First add all blocks that are part of themes in the order they appear
    themes.forEach(theme => {
      theme.blockIds.forEach(blockId => {
        if (blockMap[blockId]) {
          orderedBlocks.push(blockMap[blockId]);
          delete blockMap[blockId]; // Remove from map to track unused blocks
        }
      });
    });

    // Add any remaining blocks that weren't part of themes at the end
    Object.values(blockMap).forEach(block => {
      orderedBlocks.push(block);
    });

    // Update the transcript blocks with the new order
    setTranscriptBlocks(orderedBlocks);
  };

  const handleOrganize = async () => {
    setShowConfirmModal(false);
    setIsLoading(true);
    try {
      const response = await fetch('/api/baml/analyze-themes', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          projectData: {
            decisions: {
              blocks: transcriptBlocks
            }
          }
        })
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to analyze themes');
      }

      const result = await response.json();
      setResponse(result.data);

      // Reorder blocks based on themes and handle rejected blocks
      if (result.data?.themes) {
        reorderBlocksByThemes(result.data.themes, result.data.rejected || []);
      }
    } catch (error) {
      console.error('Error organizing with AI:', error);
      setResponse({ error: error.message });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="mt-4">
      <div className="relative">
        <Button
          className="w-full bg-gradient-to-tr from-blue-500/40 to-blue-600/40 text-white/60 shadow-lg cursor-not-allowed"
          isDisabled
        >
          Organize with AI
        </Button>
        <div className="text-center text-sm text-default-500 mt-1">Coming Soon!</div>
      </div>

      {/* Confirmation Modal */}
      <Modal 
        isOpen={showConfirmModal} 
        onClose={() => setShowConfirmModal(false)}
      >
        <ModalContent>
          <ModalHeader>Confirm AI Organization</ModalHeader>
          <ModalBody>
            This will re-organize your editor. Please save your current work first if you want to keep it.
          </ModalBody>
          <ModalFooter>
            <Button color="danger" variant="light" onPress={() => setShowConfirmModal(false)}>
              Cancel
            </Button>
            <Button color="primary" onPress={handleOrganize}>
              Proceed
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Loading State */}
      {isLoading && (
        <div className="mt-4">
          <LoadingSpinner />
        </div>
      )}

      {/* Response Display */}
      {response && !isLoading && (
        <div className="mt-4 p-4 bg-content2 rounded-lg">
          <h3 className="text-lg font-semibold mb-2">AI Analysis Results</h3>
          <pre className="whitespace-pre-wrap text-sm">
            {JSON.stringify(response, null, 2)}
          </pre>
        </div>
      )}
    </div>
  );
}; 